/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player';
import { GetAdvertisements, GetTheData } from '../Application/Api';
import awards1 from '../Assets/award.png'
import Star from "../Assets/Star.png";
import Footer from './Footer';
import Header from './Header';
import { FaArrowLeftLong } from "react-icons/fa6";
import usernotThere from '../Assets/usernotThere.jpg'
import BannerComponent from './BannerComponent';
import axios from 'axios';
import { FaAngleDown } from 'react-icons/fa';
import MovieNews from './MovieNews';
function Actorinfo() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [BottomBanner, setBottomBanner] = useState('')
    const [LeftBanner, setLeftBanner] = useState('')
    const [RightBanner, setrightBanner] = useState('')
    async function FetchingAdvertisements() {
        try {
            const data = await GetAdvertisements()
            if (data.length > 0) {
                const bottombann = data.filter((e) => e.destination == 4 && e.placement == 3)
                setBottomBanner(bottombann)
                const leftbann = data.filter((e) => e.destination == 4 && e.placement == 4)
                setLeftBanner(leftbann)
                const rightbann = data.filter((e) => e.destination == 4 && e.placement == 2)
                setrightBanner(rightbann)
            }
        } catch (error) {
            console.log(error.message);
        }

    }
    const location = useLocation()
    const datas = location.state.data;
    const name11 = location.state.name1
    const desc1 = location.state.desc
    const award = JSON.parse(datas.awardsIds)
    const [CelebritiesNews, setCelebritiesNews] = useState([])
    useEffect(() => {
        FetchingCategoryData()
        FetchingMovieDataByName()
        FetchingGenreData()
        FetchingAwards()
        try {
            axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/topicNews/celebrities/${datas.id}`)
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data);
                        setCelebritiesNews(response.data)
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
        } catch (error) {

        }
    }, [])

    const [awardsData, setAwardsData] = useState([])
    async function FetchingAwards() {
        try {
            const response = await GetTheData('awardTitles')
            if (award != undefined)
                FetchingTheAwardsBasedOnId(response.data)
        } catch (error) {
            console.log('awards error');
        }
    }
    function FetchingTheAwardsBasedOnId(params) {
        let temp = []
        for (let i = 0; i < award.length; i++) {
            const result = params.find(e => e.id == award[i])
            if (result != undefined) {
                temp.push(result)
            }
        }
        setAwardsData(temp)
    }
    const [moviesData, setMoviesData] = useState([])
    async function FetchingMovieDataByName() {
        try {
            const response = await GetTheData('movies')
            setMoviesData(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await GetTheData('movieGenres')
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingGenreDataByID(params) {
        const value = JSON.parse(params)
        console.log(value);
        try {
            if (value.length > 1) {
                let temp = ''
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    temp += data1.name.trim()
                    if (i != value.length - 1) {
                        temp += ', '
                    }
                }
                return temp;
            } else {
                const data = GenreCollection.find(e => e.id === value[0])
                return data.name;
            }
        } catch (error) {
            console.log('error genre');
        }

    }
    const [CelebrityCollection, setCelebrityCollection] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('celebrityCategories')
            setCelebrityCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingCategoryDataByID(params) {
        const value = JSON.parse(params)
        try {
            if (value.length > 1) {
                let temp = ''
                for (let i = 0; i < value.length; i++) {
                    const data1 = CelebrityCollection.find(e => e.id === Number(value[i]))
                    temp += data1.name.trim()
                    if (i != value.length - 1) {
                        temp += ', '
                    }
                }
                return temp;
            } else {
                const data = CelebrityCollection.find(e => e.id === Number(value[0]))
                return data.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }
    const navigate = useNavigate();

    const [selected, setSelected] = useState("movies");
    const OnOption = (_name, _route) => {
        setSelected(_name)
        navigate("/" + _route);
    }

    const returnTheReleaseDate = (_date) => {
        let year = new Date(_date).getUTCFullYear();
        let month =
            (Number(new Date(_date).getUTCMonth()) + 1).toString().length < 2
                ? (Number(new Date(_date).getUTCMonth()) + 1).toString().padStart(2, 0)
                : (Number(new Date(_date).getUTCMonth()) + 1).toString();
        let date =
            new Date(_date).getUTCDate().toString().length < 2
                ? new Date(_date).getUTCDate().toString().padStart(2, 0)
                : new Date(_date).getUTCDate().toString();
        let final = date + "-" + month + "-" + year;
        console.log(final);
        return final;
    };
    const [News, setNews] = useState(false)
    const OnClickNews = () => {
        setSelected('News')
        setNews(true)
        setAwards(false)
        setMovies(false)
    }
    const [awards, setAwards] = useState(false);
    const OnClickAwards = () => {
        setSelected('awards')
        setNews(false)
        setAwards(true)
        setMovies(false)
    }
    const [movies, setMovies] = useState(true);
    const OnClickMovies = () => {
        setSelected('movies')
        setNews(false)
        setAwards(false)
        setMovies(true)
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    function ReturnRating(params) {
        if (params != null) {
            let tempValue = 0
            JSON.parse(params)?.forEach(e => tempValue += Number(e.r));
            return (tempValue / JSON.parse(params).length) == 0.0 ? 0 : (tempValue / JSON.parse(params).length).toFixed(1)
        }
        return 0
    }
    return (
        <div className='w-full flex flex-col overflow-auto h-[100vh] relative  bg-[#131722] '>
            <Header props={{ selected: "Celebrity" }} />
            <div onClick={() => navigate('/Celebrity')} className='absolute lg:mt-[20vh] hover:-translate-x-3  hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] lg:mx-10 lg:my-20 my-14 mx-5 z-[99999]'>
                <FaArrowLeftLong />
                <h5 className='font-normal text-[14px]'>Back</h5>
            </div>
            <div className='w-full bg-[#131722]'>
                <div style={{ backgroundColor: '#131722' }} className='flex flex-row mx-auto max-w-[2000px] px-2 justify-center items-start'>
                    <div className='w-[170px] hidden lg:flex relative flex-col'>
                        {LeftBanner.length > 0 && <BannerComponent sectionId={LeftBanner[0].sectionId} internal={LeftBanner[0].internal} redirectUrl={LeftBanner[0].redirectUrl} id={LeftBanner[0].id} ImageUrl={LeftBanner[0].bannerUrl} />}
                    </div>
                    <div className='container flex flex-col mx-auto px-3 py-3 lg:gap-3'>
                        <div className='lg:w-[100%] px-2 w-full flex flex-col flex-wrap '>
                            <div className='flex lg:flex-row flex-col w-full gap-5'>
                                <div style={{ top: '550px' }} className='flex flex-col justify-end'>
                                    {name11 === undefined && (JSON.parse(datas.profilePictureUrls)) != null ? <img className='w-[290px] h-[400px] shadow-md rounded-md  object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + datas.id + '/' + (JSON.parse(datas.profilePictureUrls)[0])} alt='' /> :
                                        <img className='md:w-[290px] w-full h-[400px] shadow-md rounded-md  object-cover object-top' src={usernotThere} alt='' />}
                                </div>
                                <div className='lg:w-[38%] w-full flex flex-col gap-3'>
                                    <h1 className='text-white hover:cursor-default lg:text-4xl text-xl font-bold tracking-wider '>{name11 !== undefined ? name11 : datas.name}</h1>
                                    <p className={`md:text-base text-sm hover:cursor-default text-gray-300 ${datas.description.length < 550 ? 'h-auto' : 'h-[220px]'}  custom-scrollbar overflow-y-auto`}>{desc1 != undefined ? desc1 : datas.description}</p>
                                    <div className='flex flex-row gap-2 '>
                                        <div className=' border-l-4  border-l-[#24baef]'></div>
                                        <h5 className='text-gray-300 hover:cursor-default font-normal text-base'>{JSON.parse(datas.categoryId).length > 0 && FetchingCategoryDataByID(datas.categoryId)}</h5>
                                    </div>
                                </div>
                                <div className='md:w-[100%] lg:w-[40%] lg:h-auto h-[300px] mb-2 lg:mb-0 w-full flex flex-col '>
                                    {
                                        name11 !== undefined ? null :
                                            <ReactPlayer
                                                width="100%"
                                                height='100%'
                                                url={`https://www.youtube.com/watch?v=${JSON.parse(datas.personalInformation).you}`}
                                                loop={true}
                                                muted={false}
                                                // light={true}
                                                controls={true}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            datas.galleryPictureUrls !== "" ?
                                <div className='flex lg:flex-row flex-col '>
                                    <div className='lg:w-[50%] xl:w-[35%] w-full '>
                                        <div className='bg-[#1c212e] space-y-6 p-5 rounded-md '>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Name: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{datas.name}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Birth Of Date: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{returnTheReleaseDate(JSON.parse(datas.personalInformation).born)}</h5> </p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>AlmaMater: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).alm}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] break-all justify-between gap-2'>Occupation: <h5 className='text-white  text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).occ}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Active Year: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).yea}</h5></p>
                                            {JSON.parse(datas.movieIds).length !== 0 ? <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Movie Count: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.movieIds).length}</h5></p> : null}
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Works: <h5 className='text-white text-left w-[50%] break-all text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).wor}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Spouse: <h5 className='text-white text-left w-[50%] break-all text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).spo}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Childran: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).chi}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2'>Family: <h5 className='text-white text-left w-[50%] text-sm lg:text-base font-normal '>{JSON.parse(datas.personalInformation).fam}</h5></p>
                                            <p className='flex flex-row hover:cursor-default w-[100%] justify-between gap-2 '>facebook:  <a className='w-[50%] ' href={JSON.parse(datas.personalInformation).fac}><label className=' text-[#24baef]  font-normal text-sm lg:text-base'>See {datas.name} facebook page</label></a></p>
                                            {JSON.parse(datas.personalInformation).ins != '-' ? <p className='flex flex-row hover:cursor-default justify-between w-[100%] gap-2'>Instagram:  <a className='w-[50%] ' href={JSON.parse(datas.personalInformation).ins}><label className=' text-[#24baef] w-[50%] font-normal text-sm lg:text-base'>See {datas.name} Instagram page</label></a></p> : null}
                                        </div>
                                    </div>
                                    <div className='w-full lg:flex flex-col mt-2 lg:mt-0'>
                                        <div className='lg:pl-4 lg:ml-3 mb-2 lg:mb-0 rounded-md lg:h-[60px] h-[80px] flex flex-row items-center bg-[#1c212e] justify-between text-white'>
                                            <div className='flex lg:flex-row gap-10 pl-2' >
                                                <label style={{ color: selected === "movies" ? "#24baef" : "" }} onClick={() => OnClickMovies()} className='cursor-pointer text-sm lg:text-base hover:text-[#24baef]'>Movies</label>
                                                {/* <label style={{ color: selected === "photos" ? "#24baef" : "" }} onClick={() => OnClickPhotos()} className='cursor-pointer hover:text-[#24baef]'>PHOTOS</label> */}
                                                <label style={{ color: selected === "awards" ? "#24baef" : "" }} onClick={() => OnClickAwards()} className='cursor-pointer text-sm lg:text-base hover:text-[#24baef]'>Awards</label>
                                                <label style={{ color: selected === "News" ? "#24baef" : "" }} onClick={() => OnClickNews()} className='cursor-pointer text-sm lg:text-base hover:text-[#24baef]'>News</label>
                                                {/* <label style={{ color: selected === "FILMOGRAPHY" ? "#24baef" : "" }} className='cursor-pointer hover:text-[#24baef]'>FILMOGRAPHY</label> */}
                                            </div>
                                        </div>
                                        <div className=' '>
                                            {
                                                movies === true ?
                                                    <div className='flex flex-col mx-3 rounded-md lg:h-[550px] custom-scrollbar overflow-y-auto'>
                                                        {
                                                            JSON.parse(datas.movieIds).length > 0 ? moviesData.map((e, index) =>
                                                                JSON.parse(datas.movieIds).find(e1 => e1 == e.id) ?
                                                                    <div key={index} className='w-full py-2 rounded-sm border-b-[1px] border-b-gray-600'>
                                                                        <div className=' flex flex-row '>
                                                                            <div className='lg:w-[10%] flex flex-col justify-center items-center'>
                                                                                <img onClick={() => navigate("/AboutMovie", { state: { data: e, test: FetchingGenreDataByID(e.genreIds) } })} className='w-[81px] hover:cursor-pointer h-[100px] object-fill  rounded-md' src={`https://storage.googleapis.com/cinimini-console/movies/${e.id}/${JSON.parse(e.thumbnailPictureUrl)[0]}`} />
                                                                                <h5 className='text-gray-300 hover:cursor-default text-center font-normal tracking-wide mt-1'>{new Date(e.releaseDate).getUTCFullYear()}</h5>
                                                                            </div>
                                                                            <div className='lg:w-[90%] gap-y-2 flex flex-col  pl-2'>
                                                                                <label onClick={() => navigate("/AboutMovie", { state: { data: e, test: FetchingGenreDataByID(e.genreIds) } })} className='text-white text-base tracking-wide'>{e.name}</label>
                                                                                <div className='flex flex-row gap-2 items-center'>
                                                                                    <img className='w-[20px] object-contain' src={Star} alt='' />
                                                                                    <h6 className='text-gray-400 hover:cursor-default font-normal text-sm '>{ReturnRating(e.rating)}/10</h6>
                                                                                </div>
                                                                                <h4 className='text-[#949cb0] hover:cursor-default font-normal text-sm'>{FetchingGenreDataByID(e.genreIds)}</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div> : null
                                                            ) : <h5 className='text-gray-300 text-center font-normal pl-3 py-2'>No Movies yet</h5>
                                                        }


                                                    </div> : null
                                            }
                                            {
                                                awards === true ?
                                                    <div className='border-gray-300 rounded-lg h-[550px] overflow-auto custom-scrollbar'>
                                                        {
                                                            awardsData.length > 0 ? awardsData.map((e, index) =>
                                                                <div key={index} className='w-full py-2 rounded-sm justify-center flex flex-col border-b-[1px] border-b-gray-600'>
                                                                    <div className=' flex flex-row '>
                                                                        <div className='lg:w-[10%] flex flex-col justify-center items-center'>
                                                                            <img className='w-[81px] hover:cursor-pointer h-[81px] rounded-md' src={awards1} />
                                                                            <h5 className='text-gray-300 hover:cursor-default text-center font-normal tracking-wide mt-1'>2012</h5>
                                                                        </div>
                                                                        <div className='lg:w-[90%] gap-y-2 flex flex-col mt-5 '>
                                                                            <label className='text-white text-base tracking-wide'>{e.awardsName}</label>
                                                                            <h4 className='text-white hover:cursor-default font-normal text-sm'>{e.name}</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : <h5 className='text-gray-300 text-center font-normal pl-3 py-2'>No Awards yet</h5>
                                                        }
                                                    </div> : null
                                            }
                                            {
                                                News === true ?
                                                    <MovieNews props={{ movieNews: CelebritiesNews }} /> : null
                                            }
                                        </div>
                                    </div>
                                </div> : null}
                    </div>
                    <div className='w-[170px] hidden lg:flex relative flex-col'>
                        {RightBanner.length > 0 && <BannerComponent sectionId={RightBanner[0].sectionId} internal={RightBanner[0].internal} redirectUrl={RightBanner[0].redirectUrl} id={RightBanner[0].id} ImageUrl={RightBanner[0].bannerUrl} />}
                    </div>
                </div>
            </div>
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0 flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>

    )
}
export default Actorinfo;
