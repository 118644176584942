import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Searchbar from "../Image/Seacrh-White.png";
import { GetAdvertisements, GetTheData } from '../Application/Api';
import { FaAngleDown, FaSearch, FaTimes } from 'react-icons/fa';
import BannerComponent from './BannerComponent';
import { motion } from 'framer-motion'
import { PiFadersBold } from 'react-icons/pi';
import axios from 'axios';
function CelebrityDropdownpage() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [HeaderBanner, setHeaderBanner] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const Header = data.filter((e) => e.destination == 3 && e.placement == 1)
            setHeaderBanner(Header)
            const bottombann = data.filter((e) => e.destination == 3 && e.placement == 3)
            setBottomBanner(bottombann)
        }
    }
    const location = useLocation()
    const navigate = useNavigate();
    const datas = location.state.data
    useEffect(() => {
        FetchingCelebrities()
        FetchingCategoryData()
    }, [datas])
    const [Celebrities, setCelebrities] = useState([])
    async function FetchingCelebrities() {
        try {
            const response = await GetTheData('celebrities')
            setCelebrities(response.data)
            CelebrityFilterFunctionById(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const [offsetValue, setOffsetValue] = useState(0)
    async function AgainFetchingCelebrities(offset) {
        let temp = [...Celebrities]
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrities?limit=10&offset=${offset}`)
            if (response.status == 200) {

                for (let i = 0; i < response.data.length; i++) {
                    temp.push(response.data[i])
                }
                setCelebrities(temp)
                CelebrityFilterFunctionById(temp);
                setOffsetValue(offset)
            }

        } catch (error) {
            console.log(error.message);
        }
    }
    const [FilterArrayDiv, setFilterArrayDiv] = useState([])
    function ReArrangeTheArray(params) {
        let temp = []
        for (let i = 0; i < params.length; i += 10) {
            let temp1 = [...temp]
            let data = params.slice(i, i + 10)
            temp1 = data
            temp.push(temp1)
        }
        setFilterArrayDiv(temp)
    }
    const [filteredArray, setFilteredArray] = useState([])
    function CelebrityFilterFunctionById(params) {
        let temp = [...params]
        let temp1 = []
        for (let i = 0; i < temp.length; i++) {
            if (JSON.parse(temp[i].categoryId) !== null) {
                for (let j = 0; j < JSON.parse(temp[i].categoryId).length; j++) {
                    if (JSON.parse(temp[i].categoryId)[j] == datas) {
                        temp1.push(temp[i])
                    }
                }
            }
        }
        setPriviousCollection(temp1)
        ReArrangeTheArray(temp1)
    }
    function CelebrityFilterFunction(params) {
        let temp = [...Celebrities]
        if (params != 0) {
            let temp1 = []
            for (let i = 0; i < temp.length; i++) {
                if (JSON.parse(temp[i].categoryId) !== null) {
                    for (let j = 0; j < JSON.parse(temp[i].categoryId).length; j++) {
                        if (Number(JSON.parse(temp[i].categoryId)[j]) == params) {
                            temp1.push(temp[i])
                        }
                    }
                }
            }
            setShowMobileViewFilter(false)
            ReArrangeTheArray(temp1)
            setFilterFocus(false)
            setAlphabit([])
        } else {
            setShowMobileViewFilter(false)
            setFilterFocus(false)
            ReArrangeTheArray(Celebrities)
            setAlphabit([])
            navigate('/Celebrity')
        }
    }

    const [CelebrityCollection, setCelebrityCollection] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('celebrityCategories')
            setCelebrityCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingCategoryDataByID(params) {
        const value = JSON.parse(params)
        console.log(value);
        try {
            const data = CelebrityCollection.find(e => e.id === Number(value[0]))
            console.log(data.name);
            return data.name;

        } catch (error) {
            console.log('error genre');
        }
    }
    const [onFocused, setOnFocused] = useState(false)
    const [count, setCount] = useState(0)
    const [PriviousCollection, setPriviousCollection] = useState([])
    function CelebrityFilterFunctionByName(params) {
        let temp = [...PriviousCollection]
        if (params.length > count) {
            const data = params.trim().toLowerCase()
            const response = temp.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            // setAlphabit([])
            setCount(params.length)
        }
        else if (params.length < count) {
            const data = params.trim().toLowerCase()
            const response = temp.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            // setAlphabit([])
            setCount(params.length)
        } else if (params.length == 0) {
            ReArrangeTheArray(PriviousCollection)
        }
    }
    function FilterByAlphabitical(params) {
        let temp = [...PriviousCollection.length > 0 ? PriviousCollection : Celebrities]
        let tem1 = []
        for (let i = 0; i < params.length; i++) {
            const res = alpha.find(e => e.id == params[i]).name
            const response = temp.filter(e => e.name.trim().toLowerCase().startsWith(res.trim().toLowerCase()))
            for (let j = 0; j < response.length; j++) {
                tem1.push(response[j]);
            }
        }
        setShowMobileViewFilter(false)
        ReArrangeTheArray(tem1);
    }
    const [alphabit, setAlphabit] = useState([])
    function AlphabiticalFilterFunction(params) {
        let temp = [...alphabit]
        const id = temp.findIndex(e => e === params)
        if (id === -1) {
            temp.push(params)
        } else {
            temp.splice(id, 1);
        }
        if (temp.length > 0) {
            FilterByAlphabitical(temp)
        }
        else {
            ReArrangeTheArray(PriviousCollection)
        }
        setAlphabit(temp)
    }
    const alpha = [
        {
            id: 1,
            name: "A"
        },
        {
            id: 2,
            name: "B"
        },
        {
            id: 3,
            name: "C"
        },
        {
            id: 4,
            name: "D"
        },
        {
            id: 5,
            name: "E"
        },
        {
            id: 6,
            name: "F"
        },
        {
            id: 7,
            name: "G"
        },
        {
            id: 8,
            name: "H"
        },
        {
            id: 9,
            name: "I"
        },
        {
            id: 10,
            name: "J"
        },
        {
            id: 11,
            name: "K"
        },
        {
            id: 12,
            name: "L"
        },
        {
            id: 13,
            name: "M"
        },
        {
            id: 14,
            name: "N"
        },
        {
            id: 15,
            name: "O"
        },
        {
            id: 16,
            name: "P"
        },
        {
            id: 17,
            name: "Q"
        },
        {
            id: 18,
            name: "R"
        },
        {
            id: 19,
            name: "S"
        },
        {
            id: 20,
            name: "T"
        },
        {
            id: 21,
            name: "U"
        },
        {
            id: 22,
            name: "V"
        },
        {
            id: 23,
            name: "W"
        },
        {
            id: 24,
            name: "X"
        },
        {
            id: 25,
            name: "Y"
        },
        {
            id: 26,
            name: "Z"
        }
    ]
    const [filterFocus, setFilterFocus] = useState(false)
    const [Selectedcate, setSelectedcate] = useState('Select')
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const [ShowMobileViewFilter, setShowMobileViewFilter] = useState(false)
    const [SearchContentMobile, setSearchContentMobile] = useState('')
    return (
        <div className='bg-[#131722] w-full h-[100vh] overflow-y-auto'>
            {
                ShowMobileViewFilter ?
                    <motion.div inherit={{ x: -50 }} animate={{ x: 0 }} className='w-[280px] lg:hidden block h-[100vh] z-[99999] pl-5 py-5 overflow-y-auto absolute bg-[#1c212e]'>
                        <FaTimes onClick={() => setShowMobileViewFilter(false)} className='absolute text-white right-0 w-5 m-2 top-1 h-5' />
                        <h3 className='mt-5 text-white font-normal'>Popular Categories </h3>
                        <div className='border-b-[1px] w-[200px] mt-5 border-b-gray-500'></div>
                        <div className='flex flex-col  gap-y-2 z-[40]  my-5 rounded-md hover:bg-transparent hover:cursor-pointer'>
                            <label className='text-[14px] text-[#949cb0]' onClick={() => CelebrityFilterFunction(0) & setSelectedcate('All')}>All</label>
                            {
                                CelebrityCollection.map((e, index) =>
                                    <label className='text-[14px] text-[#949cb0]' key={index} onClick={() => CelebrityFilterFunction(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                )
                            }
                        </div>
                        <div style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                            className='flex bg-[#373c49] px-4 rounded-md flex-row outline-none items-center hover:border-white justify-end w-[245px] gap-5 border-[1px]'>
                            <input type='text' onChange={(e) => setSearchContentMobile(e.target.value)}
                                className="w-full text-white rounded-md outline-none  h-[45px] bg-[#373c49] " placeholder='Search' />
                            <FaSearch onClick={() => CelebrityFilterFunctionByName(SearchContentMobile)} className='text-[#949cb0]' />
                        </div>
                        <h3 className='mt-5 text-white font-normal'> Filter by Alphabitical </h3>
                        <div className='border-b-[1px] w-[200px] mt-5 border-b-gray-400'></div>
                        <div className='grid grid-cols-4 w-[200px] gap-x-2 gap-y-2  mt-1 rounded-md '>
                            {
                                alpha.map((e, index) =>
                                    <div style={{ width: '100%', cursor: "pointer", backgroundColor: alphabit.findIndex(e1 => e1 == e.id) != -1 ? "#24baef" : 'transparent' }} key={index}
                                        onClick={() => AlphabiticalFilterFunction(e.id)} className='text-[14px] text-[#949cb0] rounded-md   text-center'>{e.name}</div>
                                )
                            }
                        </div>
                    </motion.div> : null
            }
            <Header props={{ selected: "Celebrity" }} />
            <div className='flex flex-col m-auto bg-[#131722]'>
                <div className='w-full bg-[#1c212e]'>
                    <div className='lg:flex container hidden mt-2 m-auto px-3 flex-col relative'>
                        <div className='w-full h-[50px] flex flex-row gap-1'>
                            <div style={{ borderColor: filterFocus ? "#24baef" : 'gray', }} onClick={() => setFilterFocus(!filterFocus)} onBlur={() => setFilterFocus(false)} onFocus={() => setFilterFocus(true)}
                                className=' flex flex-row px-4 justify-between  border-[1px] hover:cursor-pointer border-gray-500  text-[#949cb0] rounded-md items-center bg-[#1c212e] w-[20%]'>
                                <h5 className='font-normal text-[16px] pl-1'>{Selectedcate}</h5>
                                <FaAngleDown className='' />
                            </div>
                            <div style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                                className='flex bg-[#1c212e] px-4 rounded-md flex-row outline-none items-center hover:border-white justify-end w-[80%] gap-5 border-[1px]'>
                                <input type='text' onChange={(e) => CelebrityFilterFunctionByName(e.target.value)}
                                    className="w-full  text-white rounded-md outline-none  h-full bg-[#1c212e]" placeholder='Search for a movie, TV Show or celebrity that you are looking for' />
                                {/* <img className='w-[20px]  hover:cursor-pointer h-[20px]' src={Searchbar} alt='' /> */}
                                <FaSearch className='text-[#949cb0]' />
                            </div>
                            {
                                filterFocus &&
                                <div onMouseLeave={() => setFilterFocus(false)} onFocus={() => setFilterFocus(true)} className='lg:w-[200px] xl:w-[250px]  2xl:w-[300px] flex flex-col px-5 gap-y-2 py-3 z-[40] absolute top-[55px] rounded-md bg-[#2f3942] hover:cursor-pointer'>
                                    <label onClick={() => CelebrityFilterFunction(0) & setSelectedcate('All')}>All</label>
                                    {
                                        CelebrityCollection.map((e, index) =>
                                            <label key={index} onClick={() => CelebrityFilterFunction(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                        )
                                    }
                                </div>
                            }
                        </div>
                        <div className='lg:flex lg:flex-row grid items-center justify-center grid-cols-12  gap-3 gap-y-2 py-2 mt-1 rounded-md bg-[#1c212e] '>
                            {
                                alpha.map((e, index) =>
                                    <div style={{ width: '3.8%', cursor: "pointer", backgroundColor: alphabit.findIndex(e1 => e1 == e.id) != -1 ? "#24baef" : 'transparent' }} key={index}
                                        onClick={() => AlphabiticalFilterFunction(e.id)} className='text-[16px] rounded-md text-white  hover:text-[#24baef] text-center'>{e.name}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div onClick={() => setShowMobileViewFilter(true)} className='px-3 lg:hidden w-max z-10 gap-x-1 my-5 flex flex-row items-center '>
                    <PiFadersBold className='rotate-90 w-5 h-5 text-gray-300' />
                    <h5 className='text-white text-base font-normal'>FILTER</h5>
                </div>
                <div className='container flex flex-col px-3 m-auto bg-[#131722]'>
                    {
                        FilterArrayDiv.map((e, index1) =>
                            <div>
                                <div key={index1} className='grid lg:grid-cols-4 2xl:grid-cols-5 md:grid-cols-3 grid-cols-2 py-2 gap-x-5 gap-y-3' >
                                    {
                                        e.map((item, index) =>
                                            <div className='flex flex-col justify-center items-center'>
                                                <div key={index} className='flex flex-col gap-1 '>
                                                    <div onClick={() => navigate("/Actorinfo", { state: { data: item } })} className='rounded-md w-full hover:bg-[#24baef] duration-300'>
                                                    {(JSON.parse(item.profilePictureUrls))!=null?<img className='md:w-[290px] w-[200px] min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] xl:h-[400px] hover:cursor-pointer hover:opacity-60 object-top rounded-md object-cover' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + item.id + '/' + (JSON.parse(item.profilePictureUrls)[0])} alt='' />:null}
                                                    </div>
                                                    <div className='flex w-full lg:flex-row flex-col justify-between my-1'>
                                                        {
                                                            CelebrityCollection.map((e, index1) => e.id === Number(JSON.parse(item.categoryId)[0]) ?
                                                                <h5 onClick={() => CelebrityFilterFunctionById(e.id)} key={index1} className='text-[#949cb0] block lg:hidden px-2 hover:cursor-pointer hover:text-[#24baef] font-normal tracking-widest'>{e.name}</h5> : null
                                                            )
                                                        }
                                                        <label onClick={() => navigate("/Actorinfo", { state: { data: item } })} className='text-white text-[12px] lg:text-base block md:hidden hover:cursor-pointer px-2 tracking-widest'>{item.name.length > 14 ? item.name.substr(0, 12) + '...' : item.name}</label>
                                                        <label onClick={() => navigate("/Actorinfo", { state: { data: item } })} className='text-white text-[12px] lg:text-base hidden md:block hover:cursor-pointer px-2 tracking-widest'>{item.name}</label>
                                                        {
                                                            CelebrityCollection.map((e, index1) => e.id === Number(JSON.parse(item.categoryId)[0]) ?
                                                                <h5 onClick={() => CelebrityFilterFunctionById(e.id)} key={index1} className='text-[#949cb0] hidden lg:block px-2 hover:cursor-pointer hover:text-[#24baef] font-normal tracking-widest'>{e.name}</h5> : null
                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>)
                                    }
                                </div>
                                <div className=' container h-[200px] my-5'>
                                    {HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} height={200} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />}
                                </div>
                            </div>
                        )
                    }
                    {
                        FilterArrayDiv.length === 0 && <h5 className='text-white mb-32'>Our Peoples Working On</h5>
                    }
                </div>
            </div>
            {/* <div className='flex flex-row items-center my-8 justify-center w-full '>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
                <div onClick={() =>AgainFetchingCelebrities(offsetValue+10)} className='w-max whitespace-nowrap hover:bg-primary_color hover:border-primary_color duration-300 cursor-pointer md:px-12 px-5 rounded-md md:py-3 py-2 text-[12px] text-sm text-white border-[2px] border-gray-700'>+ View More</div>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
            </div> */}
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}
export default CelebrityDropdownpage;