import React, { useState } from 'react';
import ReactPlayer from 'react-player';
function Trailer({ data }) {
    return (
        <div className='h-[500px] overflow-y-auto custom-scrollbar'>
            <div className='grid flex-row lg:grid-cols-2 lg:pl-3 gap-5'>
                <div className='w-full xl:h-[400px] h-[350px]'>
                    <ReactPlayer
                        width="100%"
                        height={'100%'}
                        url={`https://www.youtube.com/watch?v=${data}`}
                        loop={true}
                        muted={false}
                        // light={true}
                        controls={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Trailer
