import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import ReactPlayer from 'react-player'
import starnotfill from "../Assets/starnotfill.png";
import Star from "../Assets/Star.png";
import { useLocation } from 'react-router-dom';
import Close from '../Assets/Close.png'
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import awards from '../Assets/award.png'
import { FaArrowLeftLong } from 'react-icons/fa6';
import { GetAdvertisements, GetTheData } from '../Application/Api';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import BannerComponent from './BannerComponent';
import { FaAngleDown } from 'react-icons/fa';
function AboutWebseries() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [BottomBanner, setBottomBanner] = useState('')
    const [LeftBanner, setLeftBanner] = useState('')
    const [RightBanner, setrightBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const bottombann = data.filter((e) => e.destination == 4 && e.placement == 3)
            setBottomBanner(bottombann)
            const leftbann = data.filter((e) => e.destination == 4 && e.placement == 4)
            setLeftBanner(leftbann)
            const rightbann = data.filter((e) => e.destination == 4 && e.placement == 2)
            setrightBanner(rightbann)
        }
    }
    const location = useLocation()
    const datas = location.state.data;

    let total = 0;
    for (let i = 0; i < JSON.parse(datas?.seasonDetails).length; i++) {
        total += Number(JSON.parse(datas?.seasonDetails)[i].e)
    }
    const genre = location.state.test;
    const Language = location.state.Languages;
    console.log(Language);
    const webseries = location.state.webseriesRating;
    const [webseriesRating, setwebseriesRating] = useState(webseries)

    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]
    const [Selected, setSelected] = useState('Actors')
    const [Seasons, setSeasons] = useState(false);
    const OnClickSeasons = () => {
        setSelected('Seasons')
        setTrailer(false);
        setActors(false)
        setSeasons(true);

    }
    const [trailer, setTrailer] = useState(false);
    const OnClickTrailer = () => {
        setSelected('Trailer')
        setTrailer(true);
        setActors(false)
        setSeasons(false);


    }
    const [actors, setActors] = useState(true);
    const OnClickActors = () => {
        setSelected('Actors')
        setActors(true);
        setTrailer(false);
        setSeasons(false);

    }
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    const [celebrityWebseries, setcelebrityWebseries] = useState([])
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
            } else {
                setUser(data)
            }
        });
        FetchingOttPlatforms()
        window.addEventListener("click", (e) => {
            console.log(e);
            if (e.target.id != "Rating") {
                setShowStarRating(false);
            }
        })

        axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrityWebseries/${datas.id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    setcelebrityWebseries(response.data)
                }
            }).catch((error) => {
                console.log(error.message);
            })
    }, [])
    const [userInfo, setUserInfo] = useState('')
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            // console.log(JSON.parse(response.data[0].webseriesRating))
            setwebseriesRating(JSON.parse(response.data[0].webseriesRating));
            setUserInfo(response.data[0])
        } catch (error) {
            console.log(error.message);
        }
    }
    const navigate = useNavigate()
    const [showStarRating, setShowStarRating] = useState(false)
    const [starCountSelected, setStarCountSelected] = useState(-1);
    const [starCount, setStarCount] = useState(-1)
    const [ottplatform, setOttplatform] = useState([])
    async function FetchingOttPlatforms() {
        try {
            const response = await GetTheData('ottPlatforms')
            FetchingOttplatformById(response.data)
        } catch (error) {
            console.log('ott error');
        }
    }
    function FetchingOttplatformById(params) {
        let temp = []
        for (let i = 0; i < JSON.parse(datas.ottIds).length; i++) {
            const data = params.find(e => e.id === Number(JSON.parse(datas.ottIds)[i]))
            if (data != undefined) {
                temp.push(data)
            }

        }
        setOttplatform(temp)
    }
    const [selectedMovie, setSelectedMovie] = useState(0)
    function webseriesRatingFunction(e) {
        if (user === null) {
            alert('Login Do First')
        }
        else {
            setSelectedMovie(e.id)
            setStarCountSelected(webseriesRating!=null?webseriesRating.findIndex(e1 => e1.i == e.id) != -1 ? webseriesRating.find(e1 => e1.i == e.id).r - 1 : -1:-1)
            setShowStarRating(true)
        }
    }
    async function ChangeTheRatingFunction(rating) {
        try {
            let temp = []
            if (datas.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (datas.rating !== null) {
                temp = [...JSON.parse(datas.rating)]
                const result = temp.findIndex(e => e.i === userInfo.id)
                if (result === -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/Webseries/${selectedMovie}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdateMovieLocally(selectedMovie, JSON.stringify(temp))

            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    function UpdateMovieLocally(_id, _obj) {
        let temp = datas
        temp.rating = _obj
        datas = temp
    }
    const notify = async (e) => {
        setShowStarRating(false)
        ChangeTheRatingFunction(e)
        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        try {
            let temp = []
            if (userInfo.webseriesRating === null) {
                temp.push({
                    i: selectedMovie,
                    r: e
                })
            }
            else if (userInfo.webseriesRating !== null) {
                temp = [...JSON.parse(userInfo.webseriesRating)]
                const result = temp.findIndex(e => e.i === selectedMovie)
                if (result === -1) {
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber}`, {
                webseriesRating: JSON.stringify(temp)
            }).then((res) => {
                console.log(res.data);
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }; useEffect(() => {
        // FetchingCelebrities()
        FetchingCategoryData()
    }, [])



    const [CelebrityCollection, setCelebrityCollection] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('celebrityCategories')
            setCelebrityCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingCategoryDataByID(params) {
        const value = JSON.parse(params)
        try {
            const data = CelebrityCollection.find(e => e.id === Number(value[0]))
            return data.name;

        } catch (error) {
            console.log('error genre');
        }
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const [ExpantBanner, setExpantBanner] = useState(false)
    function ReturnRating(params) {
        if (params != null) {
            let tempValue = 0
            JSON.parse(params)?.forEach(e => tempValue += e.r);
            return (tempValue / JSON.parse(params).length) == 0.0 ? 0 : (tempValue / JSON.parse(params).length).toFixed(1)
        }
        else
        return 0

    }
    return (
        <div className='w-full bg-[#131722] h-[100vh] flex flex-col overflow-y-auto'>
            <Header props={{ selected: "Webseries" }} />
            <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'black' }} />
            {
                showStarRating &&
                <div className='w-full md:w-1/2 lg:w-1/3 absolute top-[30vh] self-center z-[9999] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                    <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                    <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                    <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                    <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
                    <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                        {
                            stars.map((e, index) =>
                                <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                    className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                            )
                        }
                    </div>
                    <div onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                        className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                        <h4 className=' tracking-wide text-base'>Rate</h4>
                    </div>
                </div>
            }
            <div className='relative self-start'>
                <div onClick={() => ExpantBanner ? setExpantBanner(false) : navigate('/Webseries')} className='absolute  hover:-translate-x-3 hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] mx-10 my-5 z-[99999]'>
                    <FaArrowLeftLong />
                    <h5 className='font-normal text-[14px]'>Back</h5>
                </div>
            </div>
            <div className='w-full bg-[#131722]'>
                {/* <div className='relative w-full z-10 h-[60vh] sm:block hidden'>
                    <img onClick={()=>setExpantBanner(!ExpantBanner)} className={`w-full ${ExpantBanner?'lg:z-[999]':'h-[60vh]'}  absolute object-cover object-top`} src={'https://storage.googleapis.com/cinimini-console/webseries/' + datas.id + '/' + datas.bannerUrl} />
                    <div onClick={()=>setExpantBanner(!ExpantBanner)} className='w-full bg-gradient-to-t  from-[#131722] h-[50%] to-transparent bottom-0 absolute'></div>
                </div> */}
                <div style={{ backgroundColor: '#131722' }} className={` flex ${ExpantBanner ? 'hidden' : ''} flex-row mx-auto  max-w-[2000px] px-2  items-start`}>
                    <div className='w-[170px] hidden lg:flex relative flex-col mt-2'>
                        {LeftBanner.length > 0 && <BannerComponent sectionId={LeftBanner[0].sectionId} internal={LeftBanner[0].internal} redirectUrl={LeftBanner[0].redirectUrl} id={LeftBanner[0].id} ImageUrl={LeftBanner[0].bannerUrl} />}
                    </div>

                    <div className={`container mx-auto sm:px-3  `} >
                        <div className='flex py-2 flex-col lg:flex-row w-full gap-x-5 justify-between'>
                            <div className='w-full lg:w-[750px] relative rounded-md flex flex-col justify-end'>
                                <img className='w-[750px] hidden lg:flex flex-col h-[400px] rounded-md object-cover object-top ' src={'https://storage.googleapis.com/cinimini-console/webseries/' + datas.id + '/' + datas.thumbnailUrl} alt='' />
                                <div className='lg:hidden block relative w-full h-[400px]'>
                                    <img className='w-full h-[400px] object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/webseries/' + datas.id + '/' + datas.bannerUrl} alt='' />
                                    <div className='absolute w-full h-[50%] bottom-0 bg-gradient-to-t from-[#131721] to-transparent'></div>
                                </div>
                                {
                                    new Date() < new Date(datas.releaseDate) ? null :
                                        <div className='flex flex-row absolute w-full h-14 pl-2  gap-x-2 items-center'>
                                            <img className='w-[18px] object-contain' src={Star} alt='' />
                                            <div className='flex flex-row items-center justify-center gap-x-4'>
                                                <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(datas.rating)}/10</h6>
                                                <div className='flex flex-row items-center justify-center gap-x-1'>
                                                    <img id='Rating' onClick={() => webseriesRatingFunction(datas)} className='w-[20px] cursor-pointer object-contain' src={webseriesRating != null? webseriesRating.findIndex(e1 => e1.i == datas.id) != -1 ? Star : starnotfill:starnotfill} alt='' />
                                                    <h6 className='text-gray-200 font-normal cursor-default text-sm'>{webseriesRating != null ? webseriesRating.findIndex(e1 => e1.i == datas.id) != -1 ? webseriesRating.find(e1 => e1.i == datas.id).r + '/10' : null : null}</h6>
                                                </div>
                                            </div>
                                        </div>}
                            </div>
                            <div className='w-full flex gap-y-3 flex-col '>
                                <h1 className={`text-white lg:text-4xl  text-xl hover:cursor-default font-bold tracking-wider `}>{datas.name}</h1>
                                <div className='grid grid-cols-1 xl:flex lg:flex-row gap-x-3'>
                                    <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>Seasons : {JSON.parse(datas.seasonDetails).length}</p>
                                    <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                    <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{(JSON.parse(datas.seasonDetails))[0].y} {JSON.parse(datas.seasonDetails).length > 1 ? Number((JSON.parse(datas.seasonDetails))[JSON.parse(datas.seasonDetails).length - 1].y) == Number((JSON.parse(datas.seasonDetails))[0].y) ? '' : ' - ' + (JSON.parse(datas.seasonDetails))[JSON.parse(datas.seasonDetails).length - 1].y : ''} </p>
                                    <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                    <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{datas.certification}</p>
                                    <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                    <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{genre}</p>
                                </div>
                                {Language != '' ? <div className='flex flex-row items-start '>
                                    <h4 className='text-gray-200 text-[13px] xl:text-[15px] font-normal'>Languages: </h4>
                                    <h5 className='text-[#24baef] text-[12px] xl:text-[14px] pl-2 font-normal'>{Language}</h5>
                                </div> : null}
                                <p className={`text-base mb-5 hover:cursor-move text-gray-300 ${datas.description.length < 400 ? 'h-auto' : 'xl:h-[190px] h-[140px]'}  custom-scrollbar overflow-y-auto`}>{datas.description}</p>
                                <div className='mb-2 flex flex-col lg:flex-row justify-start gap-x-10'>
                                    {JSON.parse(datas.castAndCrew).dir !== '-' ?
                                        <div className='space-y-1'>
                                            <label className='text-white  hover:text-white tracking-wide xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).dir.length > 18 ? JSON.parse(datas.castAndCrew).dir.substr(0, 18) + '...' : JSON.parse(datas.castAndCrew).dir}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default tracking-wider'>Director</div>
                                        </div> : null}
                                    {JSON.parse(datas.castAndCrew).scr !== '-' ?
                                        <div className='space-y-1'>
                                            <label className='text-white hover:text-white  tracking-wide  xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).scr.length > 18 ? JSON.parse(datas.castAndCrew).scr.substr(0, 18) + '...' : JSON.parse(datas.castAndCrew).scr}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Screenplay</div>
                                        </div> : null}
                                    {JSON.parse(datas.castAndCrew).pro !== '-' ?
                                        <div className='space-y-1'>
                                            <label className='text-white hover:text-white  tracking-wide  xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).pro.length > 18 ? JSON.parse(datas.castAndCrew).pro.substr(0, 18) + '...' : JSON.parse(datas.castAndCrew).pro}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Producer</div>
                                        </div> : null}
                                </div>
                            </div>
                            <div className='w-full flex flex-col '>
                                <ReactPlayer
                                    width="100%"
                                    height={'100%'}
                                    url={`https://www.youtube.com/watch?v=${datas.youtubeLink}`}
                                    loop={true}
                                    muted={false}
                                    // light={true}
                                    controls={true}
                                />
                            </div>
                        </div>
                        <div className='lg:flex lg:flex-row '>
                            <div className=' xl:w-[35%] '>
                                {
                                    ottplatform.length > 0 && ottplatform.map((e, index) =>
                                        <div key={index} className='flex flex-row items-center mb-2  bg-[#1c212e] pl-5 rounded-lg gap-4 '>
                                            <a className=' overflow-hidden rounded-md' href={e.redirectUrl}>
                                                <img className='w-[80px] h-[60px] my-2 cursor-pointer hover:scale-105 duration-300 rounded-md' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + e.iconUrl} />
                                            </a>
                                            <div className='space-y-1'>
                                                <a href={e.redirectUrl}>
                                                    <label className='text-white text-sm lg:text-base lg:font-semibold tracking-widest'>{e.name}</label>
                                                </a>
                                                <h6 className='text-gray-300 tracking-widest hover:cursor-default'>streaming on {e.name}</h6>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='bg-[#1c212e] space-y-6 p-5 mb-2 rounded-md'>
                                    {JSON.parse(datas.castAndCrew).wri !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Written by: <label className='text-white text-left w-[50%] text-sm lg:text-base'>{JSON.parse(datas.castAndCrew).wri} </label> </p> : null}
                                    {JSON.parse(datas.castAndCrew).cin !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Cinematography: <label className='text-white text-left w-[50%] text-sm lg:text-base'>{JSON.parse(datas.castAndCrew).cin} </label></p> : null}
                                    {JSON.parse(datas.castAndCrew).edi !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Edited by: <label className='text-white text-sm text-left w-[50%] lg:text-base'> {JSON.parse(datas.castAndCrew).edi}</label></p> : null}
                                    {JSON.parse(datas.castAndCrew).mus !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Music by: <label className='text-white text-sm text-left w-[50%] lg:text-base'> {JSON.parse(datas.castAndCrew).mus}</label></p> : null}
                                    {JSON.parse(datas.castAndCrew).sto !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Story by: <label className='text-white text-sm text-left w-[50%] lg:text-base'> {JSON.parse(datas.castAndCrew).sto}</label></p> : null}
                                    {JSON.parse(datas.castAndCrew).dia !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm  hover:cursor-default lg:text-base'>Dialogues by: <p className='text-white text-sm text-left w-[50%] lg:text-base'>{JSON.parse(datas.castAndCrew).dia}</p></p> : null}
                                    {JSON.parse(datas.castAndCrew).proc !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Production company:<p className={`text-white text-left w-[50%] text-sm ${JSON.parse(datas.castAndCrew).proc.length > 20 ? 'lg:text-sm' : 'lg:text-base'}`}>{JSON.parse(datas.castAndCrew).proc}</p></p> : null}
                                    <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Release Year: <p className='text-white text-sm text-left w-[50%] lg:text-base'>{new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                    <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Total Episodes: <p className='text-white text-sm text-left w-[50%] lg:text-base'>{total}</p></p>
                                    <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Original Language:  <p className='text-white text-sm text-left w-[50%] lg:text-base'>{JSON.parse(datas.castAndCrew).lang}</p></p>
                                    {/* <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Budget: <p className='text-white text-sm text-left w-[50%] lg:text-base'>{JSON.parse(datas.castAndCrew).bud}{JSON.parse(datas.castAndCrew).bud == 'NA' ? '' : 'cr'} </p></p> */}
                                    <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Release date: <p className='text-white text-left w-[50%] text-sm lg:text-base'>{new Date(datas.releaseDate).getUTCDate() + "-" + (Number(new Date(datas.releaseDate).getUTCMonth()) + 1) + "-" + new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                </div>

                            </div>
                            <div className='w-full  lg:flex  flex-col mt-2 lg:mt-0'>
                                <div className='lg:pl-4 px-3 mb-2 w-[99%] lg:ml-3 rounded-lg lg:h-[60px] h-[80px]  flex flex-row items-center bg-[#1c212e] justify-between text-white'>
                                    <div className='flex flex-row md:gap-10 md:w-auto w-full justify-between' >
                                        <label onClick={() => OnClickActors()} className={`${Selected === 'Actors' ? 'text-[#24baef]' : 'text-white'}`}>Cast</label>
                                        <label onClick={() => OnClickTrailer()} className={`${Selected === 'Trailer' ? 'text-[#24baef]' : 'text-white'}`}>Trailer</label>
                                        <label onClick={() => OnClickSeasons()} className={`${Selected === 'Seasons' ? 'text-[#24baef]' : 'text-white'}`}>Seasons</label>
                                    </div>
                                </div>
                                {
                                    actors &&
                                    <div className='w-full lg:pl-3 h-[500px] overflow-y-auto custom-scrollbar'>
                                        <div className='grid lg:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-5  grid-cols-1'>
                                            {celebrityWebseries.map((e, index) =>
                                                <div key={index} className='flex  flex-row gap-3  hover:shadow-sm rounded-md p-2 duration-300 items-center'>
                                                    {e.profilePictureUrls != null ? <img onClick={() => navigate("/Actorinfo", { state: { data: e } })} className='w-[60px] h-[60px] object-top hover:cursor-pointer rounded-full object-cover gap-6 ' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + e.id + '/' + JSON.parse(e.profilePictureUrls)[0]} alt='' /> : null}
                                                    <div className='space-y-1'>
                                                        <label onClick={() => navigate("/Actorinfo", { state: { data: e } })} className='text-white hover:text-white font-medium tracking-wide '>{e.name}</label>
                                                        <div style={{ fontSize: 13 }} className='text-gray-400 hover:cursor-default tracking-wider'>{FetchingCategoryDataByID(e.categoryId)}</div>
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                }
                                {
                                    trailer &&
                                    <div className='h-[500px] overflow-y-auto custom-scrollbar'>
                                        <div className='grid flex-row lg:grid-cols-2 lg:pl-3 gap-5'>
                                            <div className='w-full xl:h-[400px] rounded-md h-[350px]'>
                                                <ReactPlayer
                                                    width="100%"
                                                    url={`https://www.youtube.com/watch?v=${datas.youtubeLink}`}
                                                    loop={true}
                                                    muted={false}
                                                    // light={true}
                                                    controls={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    Seasons &&
                                    <div className='h-[500px] overflow-y-auto custom-scrollbar lg:mx-3'>
                                        {
                                            JSON.parse(datas.seasonDetails).map((item, index) =>
                                                <div key={index} className='w-full py-2 rounded-sm justify-center flex flex-col border-b-[1px] border-b-gray-600'>
                                                    <div className=' flex flex-row '>
                                                        <div className='lg:w-[10%] flex flex-col justify-center items-center'>
                                                            <img className='w-[81px]  h-[81px] object-cover object-top rounded-md' src={'https://storage.googleapis.com/cinimini-console/webseries/' + datas.id + '/' + datas.thumbnailUrl} />
                                                            <h5 className='text-gray-300 hover:cursor-default text-center font-normal tracking-wide mt-1'>{item.y}</h5>
                                                        </div>
                                                        <div className='lg:w-[90%] gap-y-1 flex flex-col mt-1 '>
                                                            <label className='text-white text-base hover:text-white cursor-default tracking-wide w-max'>Season : {item.s}</label>
                                                            <label className='text-white hover:text-white cursor-default text-base tracking-wide w-max'>Episodes : {item.e}</label>
                                                            <h4 className='text-white hover:cursor-default w-max font-normal text-sm'>{genre}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-[170px] hidden lg:flex relative flex-col mt-2'>
                        {RightBanner.length > 0 && <BannerComponent sectionId={RightBanner[0].sectionId} internal={RightBanner[0].internal} redirectUrl={RightBanner[0].redirectUrl} id={RightBanner[0].id} ImageUrl={RightBanner[0].bannerUrl} />}
                    </div>
                </div>
            </div>
            {ExpantBanner ? null : <Footer />}
            {BottomBannerState && ExpantBanner === false ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default AboutWebseries