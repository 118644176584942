import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { FaAngleDown, FaAngleRight, FaSearch, FaTimes } from 'react-icons/fa'
import Searchbar from "../Image/Seacrh-White.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAdvertisements, GetTheData } from '../Application/Api';
import playcircle from "../Assets/playcircle.png";
import starnotfill from "../Assets/starnotfill.png";
import netflix1 from "../Assets/netflix1.png";
import Star from "../Assets/Star.png";
import Close from '../Assets/Close.png'
import ReactPlayer from 'react-player';
import n1 from '../Assets/n1.jpg'
import netflix from '../Assets/netflix1.png'
import n2 from '../Assets/n2.jpg'
import n3 from '../Assets/n3.jpg'
import n4 from '../Assets/n4.jpg'
import n5 from '../Assets/n5.jpg'
import n6 from '../Assets/n6.jpg'
import { motion } from 'framer-motion'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import BannerSliderComponent from './BannerSliderComponent';
import OpenYoutubeBox from './OpenYoutubeBox';
import BannerComponent from './BannerComponent';
import { PiFadersBold } from 'react-icons/pi';
function Webseries() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [HeaderBanner, setHeaderBanner] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    const [Square, setSquare] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const Header = data.filter((e) => e.destination == 5 && e.placement == 1)
            setHeaderBanner(Header)
            const bottombann = data.filter((e) => e.destination == 4 && e.placement == 3)
            setBottomBanner(bottombann)
            const sqaurebann = data.filter((e) => e.destination == 5 && e.placement == 6)
            setSquare(sqaurebann)
        }
    }
    const location = useLocation()
    const datas = location.state?.data

    const [FlipingPage, setFlipingPage] = useState(true)
    useEffect(() => {
        FetchingData()
    }, [])
    const [Contents, setContents] = useState([])
    async function FetchingData() {
        let temp1 = []
        let temp2 = []
        try {
            const response1 = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/ottPlatforms?limit=100&offset=0`)
            temp1 = [...response1.data]
        } catch (error) {
            console.log(error.message);
        }
        try {
            const response2 = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/webSeries?limit=100&offset=0`)
            temp2 = [...response2.data]
        } catch (error) {
            console.log(error.message);
        }
        FilteringPlatforms(temp1, temp2)
    }

    function FilteringPlatforms(param1, param2) {
        let temp2 = []
        for (let i = 0; i < param1.length; i++) {
            let temp = []
            for (let j = 0; j < param2.length; j++) {
                if (param1[i].id === JSON.parse(param2[j].ottIds)[0]) {
                    temp.push(param2[j])
                }
            }
            temp2.push(temp)
        }
        setContents(temp2)
    }


    useEffect(() => {
        if (datas !== undefined)
            FetchingSeriesGenre(datas)
    }, [datas])

    function FetchingSeriesGenre(params) {
        setFlipingPage(false)
        let temp = [...Webseries]
        let temp1 = []
        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < JSON.parse(temp[i].genreIds).length; j++) {
                if (JSON.parse(temp[i].genreIds)[j] === params) {
                    temp1.push(temp[i])
                }
            }
        }
        console.log(temp1);
        ReArrangeTheArray(temp1)
    }
    const [filterFocus, setFilterFocus] = useState(false)
    const [Selectedcate, setSelectedcate] = useState('Select')
    const [onFocused, setOnFocused] = useState(false)
    const [alphabit, setAlphabit] = useState([])
    const navigate = useNavigate()
    const alpha = [
        {
            id: 1,
            name: "A"
        },
        {
            id: 2,
            name: "B"
        },
        {
            id: 3,
            name: "C"
        },
        {
            id: 4,
            name: "D"
        },
        {
            id: 5,
            name: "E"
        },
        {
            id: 6,
            name: "F"
        },
        {
            id: 7,
            name: "G"
        },
        {
            id: 8,
            name: "H"
        },
        {
            id: 9,
            name: "I"
        },
        {
            id: 10,
            name: "J"
        },
        {
            id: 11,
            name: "K"
        },
        {
            id: 12,
            name: "L"
        },
        {
            id: 13,
            name: "M"
        },
        {
            id: 14,
            name: "N"
        },
        {
            id: 15,
            name: "O"
        },
        {
            id: 16,
            name: "P"
        },
        {
            id: 17,
            name: "Q"
        },
        {
            id: 18,
            name: "R"
        },
        {
            id: 19,
            name: "S"
        },
        {
            id: 20,
            name: "T"
        },
        {
            id: 21,
            name: "U"
        },
        {
            id: 22,
            name: "V"
        },
        {
            id: 23,
            name: "W"
        },
        {
            id: 24,
            name: "X"
        },
        {
            id: 25,
            name: "Y"
        },
        {
            id: 26,
            name: "Z"
        }
    ]
    const [FilterArray, setFilterArray] = useState([])
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data)
            } else {
                setUser(data)
            }
        });
        FetchingMovieData()
        FetchingCategoryData()
        FetchingGenreData()
        FetchingOttPlatforms()
        window.addEventListener("click", (e) => {
            console.log(e);
            if (e.target.id != "Rating") {
                setShowStarRating(false);
            }
            if (e.target.id != "Video") {
                setShowMovieTrailer(false)
            }
        })
    }, [])

    const [OttPlatform, setOttPlatform] = useState([])
    async function FetchingOttPlatforms() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/ottPlatforms?limit=100&offset=0`)
            setOttPlatform(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    const [selectedMovie, setSelectedMovie] = useState(0)
    const [userInfo, setUserInfo] = useState('')
    const [webseriesRating, setwebseriesRating] = useState([]);
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            setwebseriesRating(JSON.parse(response.data[0].webseriesRating));
            setUserInfo(response.data[0])
        } catch (error) {
            console.log(error.message);
        }
    }
    const [Webseries, setWebseries] = useState([])
    async function FetchingMovieData() {
        const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/webSeries?limit=100&offset=0`)
        setWebseries(response.data)
        setFilterArray(response.data)
        ReArrangeTheArray(response.data)
    }
    const [FilterArrayDiv, setFilterArrayDiv] = useState([])
    function ReArrangeTheArray(params) {
        let temp = []
        for (let i = 0; i < params.length; i += 12) {
            let temp1 = [...temp]
            let data = params.slice(i, i + 12)
            temp1 = data
            temp.push(temp1)
        }
        setFilterArrayDiv(temp)
    }
    const [movieCategories, setMovieCategories] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieCategories?limit=100&offset=0`)
            setMovieCategories(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    const [showStarRating, setShowStarRating] = useState(false)
    const [starCountSelected, setStarCountSelected] = useState(-1);
    const [starCount, setStarCount] = useState(-1)
    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]

    const [showMovieTrailer, setShowMovieTrailer] = useState(false)
    const [movieTrailerLink, setMovieTrailerLink] = useState('')
    function MovieTrailerFunction(params) {
        setShowStarRating(false)
        setMovieTrailerLink(params)
        setShowMovieTrailer(true)
    }
    const [FilteredId, setFilteredId] = useState(0)
    const [selectedMovieForAplha, setSelectedMovieForAplha] = useState([])
    function MovieFilterFunctionById(params) {
        setFlipingPage(false)
        let temp = [...Webseries]
        if (params != 0) {
            let temp1 = []
            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < JSON.parse(temp[i].categoryIds).length; j++) {
                    if (JSON.parse(temp[i].categoryIds)[j] == params) {
                        temp1.push(temp[i])
                    }
                }
            }
            setShowMobileViewFilter(false)
            ReArrangeTheArray(temp1)
            setFilteredId(params)
            setAlphabit([])
            setFilterFocus(false)
            setSelectedMovieForAplha(temp1)

        }
        else {
            setShowMobileViewFilter(false)
            ReArrangeTheArray(temp)
            setFilteredId(0)
            setAlphabit([])
            setFilterFocus(false)
            setSelectedMovieForAplha(temp)
            setFlipingPage(true)
        }
    }
    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieGenres?limit=100&offset=0`)
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingGenreDataByID(params) {
        const value = JSON.parse(params)
        try {
            if (value.length > 1) {
                let temp = []
                let count = 0
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    count++;
                    temp += data1.name.trim()
                    if (count > 3) {
                        temp += '...'
                        break;
                    }
                    if (i != value.length - 1) {
                        temp += ', '
                    }

                }
                return temp;
            } else {
                const data = GenreCollection.find(e => e.id === value[0])
                return data.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }

    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    function SeriesRatingFunction(e) {
        if (user === null) {
            alert('Login Do First')
        }
        else {
            setSelectedMovie(e.id)
            setStarCountSelected(webseriesRating !== null ? webseriesRating.findIndex(e1 => e1.i == e.id) != -1 ? webseriesRating.find(e1 => e1.i == e.id).r - 1 : -1 : -1)
            setShowStarRating(true)
        }
    }
    async function ChangeTheRatingFunction(rating) {
        let ParticularMovie = Webseries.find((e => e.id == selectedMovie))
        try {
            let temp = []
            if (ParticularMovie.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (ParticularMovie.rating !== null) {
                temp = [...JSON.parse(ParticularMovie.rating)]
                const result = temp.findIndex(e => e.i === userInfo.id)
                if (result === -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/Webseries/${selectedMovie}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdateMovieLocally(selectedMovie, JSON.stringify(temp))

            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    function UpdateMovieLocally(_id, _obj) {
        let tempMovies = [...Webseries]
        tempMovies.find(e => e.id == _id).rating = _obj
        setWebseries(tempMovies)
        ReArrangeTheArray(tempMovies)
        FetchingData()
    }
    const notify = async (e) => {
        setShowStarRating(false)
        ChangeTheRatingFunction(e)
        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        try {
            let temp = []
            if (userInfo.webseriesRating === null) {
                temp.push({
                    i: selectedMovie,
                    r: e
                })
            }
            else if (userInfo.webseriesRating !== null) {
                temp = [...JSON.parse(userInfo.webseriesRating)]
                const result = temp.findIndex(e => e.i === selectedMovie)
                if (result === -1) {
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber}`, {
                webseriesRating: JSON.stringify(temp)
            }).then((res) => {
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    };
    const [count, setCount] = useState(0)
    function MovieFilterFunctionByName(params) {
        if (params.length > count) {
            const data = params.trim().toLowerCase()
            const response = FilterArray.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            setAlphabit([])
            setCount(params.length)
            setFlipingPage(false)
            setShowMobileViewFilter(false)
        }
        else if (params.length <= count) {
            const data = params.trim().toLowerCase()
            const response = Webseries.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            setShowMobileViewFilter(false)
            setAlphabit([])
            setCount(params.length)
            if (params.length == 0)
                setFlipingPage(true)
        }
        else {
            MovieFilterFunctionById(FilteredId)
            setShowMobileViewFilter(false)
            setFlipingPage(true)
        }
    }
   
    function FilterByAlphabitical(params) {
        let temp = [...selectedMovieForAplha.length > 0 ? selectedMovieForAplha : Webseries]
        let tem1 = []
        for (let i = 0; i < params.length; i++) {
            const res = alpha.find(e => e.id == params[i]).name
            const response = temp.filter(e => e.name.trim().toLowerCase().startsWith(res.trim().toLowerCase()))
            for (let j = 0; j < response.length; j++) {
                tem1.push(response[j]);
            }
        }
        setFilterArray(tem1);
    }

    const paragraphRef = useRef(null);
    function FilteringBasedOnOtt(params) {
        console.log(params);
        paragraphRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        })
        setFlipingPage(false)
        let temp = [...Webseries]
        let Newtemp = []
        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < JSON.parse(temp[i].ottIds).length; j++) {
                if (JSON.parse(temp[i].ottIds)[j] === Number(params)) {
                    Newtemp.push(temp[i])
                }
            }
        }
        ReArrangeTheArray(Newtemp)
    }
    function ClearAllFunction() {
        ReArrangeTheArray(Webseries)
        setFlipingPage(true)
    }

    function FetchSeriesName(params) {
        if (params != undefined)
            return OttPlatform.find(e => e.id === Number(params))?.iconUrl
    }
    const [ShowMoreValue, setShowMoreValue] = useState([])
    function MoreFunction(params) {
        let temp = [...ShowMoreValue]
        const value = temp.findIndex(e => e === params)
        if (value === -1) {
            temp.push(params)
        }
        else {
            temp.splice(value, 1)
        }
        setShowMoreValue(temp)
    }
    const array1 = [
        {
            id: 1,
            name: 'The Limit',
            img: n1,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix',
        },
        {
            id: 2,
            name: 'Winnie',
            img: n2,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 3,
            name: 'The Growth',
            img: n3,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 4,
            name: 'Swank',
            img: n4,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 5,
            name: 'Breaking Bat',
            img: n5,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        }, {
            id: 6,
            name: 'MindSet',
            img: n6,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        }
    ]
    function FilterLanguages(params) {
        console.log(params);
        let temp = ''
        for (let i = 0; i < JSON.parse(params.categoryIds).length; i++) {
            let value = movieCategories.find(e => e.id === JSON.parse(params.categoryIds)[i]).name
            if (i < JSON.parse(params.categoryIds).length - 1) {
                value = value + ', '
            }
            temp += value;
        }
        return temp;
    }
    const [showWebseriesBox, setShowWebseriesBox] = useState(false)
    const [YtLink, setYtLink] = useState('')
    function WebseriesTurnOn(params) {
        setYtLink(params)
        setShowWebseriesBox(true)
    }
    function WebseriesTurnOff() {
        setShowWebseriesBox(false)
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const [ShowMobileViewFilter, setShowMobileViewFilter] = useState(false)
    const [SearchContentMobile, setSearchContentMobile] = useState('')
    function ReturnRating(params) {
        if (params != null) {
        let tempValue = 0
        JSON.parse(params)?.forEach(e => tempValue += Number(e.r));
        console.log(tempValue);
        return (tempValue / JSON.parse(params).length) == 0.0 ? 0 : (tempValue / JSON.parse(params).length).toFixed(1)
        }
        return 0
    }
    return (
        <div className='w-full h-[100vh] overflow-y-auto flex flex-col bg-[#131722] items-center '>
            <Header props={{ selected: "Webseries" }} />
            {
                showStarRating &&
                <div className='w-full md:w-1/2 lg:w-1/3 absolute z-50 top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                    <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                    <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                    <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                    <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
                    <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                        {
                            stars.map((e, index) =>
                                <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                    className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                            )
                        }
                    </div>
                    <div onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                        className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                        <h4 className=' tracking-wide text-base'>Rate</h4>
                    </div>
                </div>
            }
            {
                showMovieTrailer ?
                    <div id='Video' className='py-5  md:h-[50vh] md:w-[50%] lg:w-[35%] top-[25vh] w-full rounded-md bg-[#1c212e] absolute z-10  flex flex-col items-center '>
                        <div id='Video' className=' flex flex-row justify-between w-full items-center px-5 pb-3'>
                            <h4 className='text-white font-normal'>Trailer</h4>
                            <img onClick={() => setShowMovieTrailer(false)} className='w-[20px]  hover:scale-125 hover:cursor-pointer duration-300 object-contain' src={Close} />
                        </div>
                        <div id='Video' className='md:w-[100%] h-full w-full flex flex-col gap-3 px-5'>
                            <ReactPlayer
                                width="100%"
                                height='100%'
                                url={`https://www.youtube.com/watch?v=${movieTrailerLink}`}
                                playing={true}
                                controls={true}
                            />
                        </div>
                    </div> : null
            }
            {
                showWebseriesBox &&
                <div className='flex flex-col self-center'>
                    <OpenYoutubeBox YtLink={YtLink} handleYtFalseFunction={WebseriesTurnOff} />
                </div>

            }
            {
                ShowMobileViewFilter ?
                    <motion.div inherit={{ x: -50 }} animate={{ x: 0 }} className='w-[280px] self-start lg:hidden block h-[100vh] z-[99999] pl-5 py-5 overflow-y-auto absolute bg-[#1c212e]'>
                        <FaTimes onClick={() => setShowMobileViewFilter(false)} className='absolute text-white right-0 w-5 m-2 top-1 h-5' />
                        <h3 className='mt-5 text-white font-normal'>Popular Categories </h3>
                        <div className='border-b-[1px] w-[200px] mt-5 border-b-gray-500'></div>
                        <div className='flex flex-col  gap-y-2 z-[40]  my-5 rounded-md hover:bg-transparent hover:cursor-pointer'>
                            <label className='text-[14px] text-[#949cb0]' onClick={() => MovieFilterFunctionById(0) & setSelectedcate('All')}>All</label>
                            {
                                movieCategories.map((e, index) =>
                                    <label className='text-[14px] text-[#949cb0]' key={index} onClick={() => MovieFilterFunctionById(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                )
                            }
                        </div>
                        <div style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                            className='flex bg-[#373c49] px-4 rounded-md flex-row outline-none items-center hover:border-white justify-end w-[245px] gap-5 border-[1px]'>
                            <input type='text' onChange={(e) => setSearchContentMobile(e.target.value)}
                                className="w-full text-white rounded-md outline-none  h-[45px] bg-[#373c49]" placeholder='Search' />
                            <FaSearch onClick={() => MovieFilterFunctionByName(SearchContentMobile)} className='text-[#949cb0]' />
                        </div>
                    </motion.div> : null
            }
            <div onMouseLeave={() => setFilterFocus(false)} className={`w-full relative bg-[#131722] flex flex-col mt-2 mx-auto ${FilterArray.length === 0 ? 'mb-72' : 'mb-0'}`}>
                <div className='w-full bg-[#1c212e]'>
                    {/* Mobile */}
                    <div onClick={() => setShowMobileViewFilter(true)} className='px-3 lg:hidden w-max z-10 gap-x-1 my-5 flex flex-row items-center '>
                        <PiFadersBold className='rotate-90 w-5 h-5 text-gray-300' />
                        <h5 className='text-white text-base font-normal'>FILTER</h5>
                    </div>
                    <div ref={paragraphRef} className='flex flex-col bg-[#1c212e] container m-auto px-3 mt-2 relative'>
                        <div className='w-full h-[50px] hidden lg:flex flex-row gap-1'>
                            <div style={{ borderColor: filterFocus ? "#24baef" : 'gray', }} onClick={() => setFilterFocus(!filterFocus)}
                                className=' flex flex-row justify-between  px-4 border-[1px] hover:cursor-pointer border-gray-500  text-[#949cb0] rounded-md items-center bg-[#1c212e] w-[20%]'>
                                <h5 className='font-normal text-left text-sm lg:text-[16px] lg:pl-1'>{Selectedcate}</h5>
                                <FaAngleDown className='lg:block hidden' />
                            </div>
                            <div onMouseEnter={() => setFilterFocus(false)} style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                                className='flex px-4 bg-[#1c212e] rounded-md flex-row outline-none items-center  justify-end w-[80%] gap-5 border-[1px]'>
                                <input type='text' onChange={(e) => MovieFilterFunctionByName(e.target.value)}
                                    className="w-full text-white text-sm lg:text-[16px] rounded-md outline-none px-1  h-full bg-[#1c212e]" placeholder='Search for a movie, TV Show or celebrity that you are looking for' />
                                {/* <img className='w-[20px]  hover:cursor-pointer h-[20px]' src={Searchbar} alt='' /> */}
                                <FaSearch className='text-[#949cb0] cursor-pointer' />
                            </div>
                        </div>
                        {
                            filterFocus &&
                            <div onMouseLeave={() => setFilterFocus(false)} className='w-[300px] flex flex-col px-5 gap-y-2 py-3 z-[99999] absolute top-[53px] rounded-md bg-[#2f3942] hover:cursor-pointer'>
                                <label onClick={() => MovieFilterFunctionById(0) & setSelectedcate('All')}>All</label>
                                {
                                    movieCategories.map((e, index) =>
                                        <label key={index} onClick={() => MovieFilterFunctionById(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                    )
                                }
                            </div>
                        }
                        <div onMouseEnter={() => setFilterFocus(false)} className='flex flex-row w-full items-center overflow-x-auto  gap-4 gap-y-2 py-2 mt-1 rounded-md bg-[#1c212e] '>
                            <div onClick={() => ClearAllFunction()} className='border-[1px] w-[80px] h-[60px] hover:bg-[#24baef] duration-200 cursor-pointer rounded-md px-3 py-1 flex flex-col justify-center items-center'>
                                <h5 className='text-[#949cb0] lg:text-sm text-xs font-normal tracking-wider'>All</h5>
                            </div>
                            {
                                OttPlatform.map((e, index) => 
                                    <img onClick={() => FilteringBasedOnOtt(e.id)} key={index} className='w-[80px] h-[60px] hover:scale-110 cursor-pointer duration-200 rounded-md' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + e.iconUrl} /> 
                                )
                            }

                        </div>
                    </div>
                </div>
                <div className='w-full m-auto '>
                    {
                        FlipingPage ? (
                            Contents.length > 0 ? (
                                <motion.div initial={{ opacity: .5 }} animate={{ opacity: 10 }} className='w-full  flex flex-col '>
                                    <div className='mb-5 mx-auto container px-4 flex flex-row gap-x-2 '>
                                        <div className='w-full'>
                                            <BannerSliderComponent WebseriesTurnOn={WebseriesTurnOn} array={Webseries.slice(0, 5)} />
                                        </div>
                                        <div className='pt-6 lg:block hidden w-full'>
                                            {Square.length > 0 && <BannerComponent sectionId={Square[0].sectionId} internal={Square[0].internal} redirectUrl={Square[0].redirectUrl} id={Square[0].id} ImageUrl={Square[0].bannerUrl} />}
                                        </div>
                                    </div>
                                    {
                                        Contents.map((e, index1) => e.length > 0 ?
                                            <div key={index1} className='w-full flex flex-col'>
                                                <div className='container px-3 m-auto grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-x-5 gap-y-3'>
                                                    <div sty className='w-full flex lg:col-auto col-span-2 flex-col justify-center'>
                                                        <div className='border-b-[1px] border-b-gray-600 bg-red-500 w-[30%] '></div>
                                                        <h5 className=' lg:text-sm font-normal  text-white py-3 cursor-default tracking-wider '>{OttPlatform[index1]?.name}</h5>
                                                        <img className='w-[200px] h-[100px] lg:block hidden rounded-md object-contain ' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + OttPlatform[index1]?.iconUrl} />
                                                        <h5 className=' lg:text-sm font-normal lg:mt-4  text-gray-400 cursor-default tracking-wider '>Most Watched Webseries by days</h5>
                                                        <div className='border-b-[1px] border-b-gray-600 bg-red-500 w-[50%] mt-5 mb-5'></div>
                                                    </div>
                                                    {e.map((item, index) => index < (ShowMoreValue.findIndex(e2 => e2 === index1) !== -1 ? e.length : 7) ?
                                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 10 }} key={index} className='flex flex-col hover:scale-105 duration-300'>
                                                            <div className='flex flex-col  justify-end relative rounded-md hover:bg-[#24baef] duration-300'>
                                                                <img onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })}
                                                                    className=' hover:opacity-60 hover:cursor-pointer h-[100px] md:h-[200px] w-full  rounded-md object-top object-cover ' src={`https://storage.googleapis.com/cinimini-console/webseries/${item.id}/${item.bannerUrl}`} alt='' />
                                                                <img id="Video" onClick={() => MovieTrailerFunction(item.youtubeLink)} className='sm:w-[35px] sm:h-[35px] w-[25px] h-[25px] hover:cursor-pointer absolute opacity-50 hover:opacity-100 sm:bottom-[40%] bottom-[40%] left-[40%] sm:left-[44%]' src={playcircle} />
                                                                {JSON.parse(item.ottIds).length > 0 ? <img className='w-[40px] h-[30px] sm:w-[60px] sm:h-[40px]  m-1 absolute top-0 object-fill hover:scale-110 cursor-pointer duration-200 rounded-md' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + FetchSeriesName(JSON.parse(item.ottIds)[0])} /> : null}
                                                                {
                                                                    new Date() < new Date(item.releaseDate) ? null :
                                                                        <div className='flex flex-row absolute my-2 mx-2 gap-x-2 items-center'>
                                                                            <img className='md:w-[18px] w-[15px] object-contain' src={Star} alt='' />
                                                                            <div className='flex flex-row items-center justify-center gap-x-4'>
                                                                                <h6 className='text-gray-200 text-[12px] md:text-sm font-normal cursor-default '>{ReturnRating(item.rating)}/10</h6>
                                                                                <div className='flex flex-row items-center justify-center gap-x-1'>
                                                                                    <img id='Rating' onClick={() => SeriesRatingFunction(item)} className='md:w-[20px] w-[15px] cursor-pointer object-contain' src={webseriesRating != null && webseriesRating.findIndex(e1 => e1.i == item.id) != -1 ? Star : starnotfill} alt='' />
                                                                                    <h6 className='text-gray-200 font-normal cursor-default text-[12px] md:text-sm'>{webseriesRating != null ? webseriesRating.findIndex(e1 => e1.i == item.id) != -1 ? webseriesRating.find(e1 => e1.i == item.id).r + '/10' : null : null}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                            </div>
                                                            <div className='flex flex-col pt-1'>
                                                                <div className=' flex flex-col md:flex-row md:items-center justify-between rounded-md'>
                                                                    <div className='md:block hidden text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                                        <div className=' flex flex-row'>
                                                                            {
                                                                                JSON.parse(item.genreIds) != null && JSON.parse(item.genreIds).map((e, index) => GenreCollection.findIndex(e1 => e1.id == e) !== -1 ?
                                                                                    <div key={index} className='flex flex-row items-center'>
                                                                                        <h6 onClick={() => FetchingSeriesGenre(e)} className='md:text-[14px] text-[12px] hover:cursor-pointer hover:text-[#24baef] text-[#949cb0] font-normal'>{GenreCollection.find(e2 => e2.id == e).name}</h6>
                                                                                        <h6 className='self-end pr-1 text-sm'>{index < JSON.parse(item.genreIds).length - 1 ? ',' : ''}</h6>
                                                                                    </div> : null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='md:hidden block text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                                        <div className=' flex flex-row'>
                                                                            {
                                                                                JSON.parse(item.genreIds) != null && JSON.parse(item.genreIds).map((e, index) => GenreCollection.findIndex(e1 => e1.id == e) !== -1 ? index < 2 ?
                                                                                    <div key={index} className='flex flex-row items-center'>
                                                                                        <h6 onClick={() => FetchingSeriesGenre(e)} className='md:text-[14px] text-[12px] hover:cursor-pointer hover:text-[#24baef] text-[#949cb0] font-normal'>{GenreCollection.find(e2 => e2.id == e).name}</h6>
                                                                                        <h6 className={`self-end pr-1 text-sm ${index < 1 ? 'block' : 'hidden'}`}>{index < JSON.parse(item.genreIds).length - 1 ? ',' : ''}</h6>
                                                                                    </div> : null : null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <h5 className='text-[#949cb0] hover:cursor-default md:block hidden text-[12px] font-normal tracking-widest'>{(JSON.parse(item.seasonDetails))[0].y} {JSON.parse(item.seasonDetails).length > 1 ? Number((JSON.parse(item.seasonDetails))[JSON.parse(item.seasonDetails).length - 1].y) == Number((JSON.parse(item.seasonDetails))[0].y) ? '' : ' - ' + (JSON.parse(item.seasonDetails))[JSON.parse(item.seasonDetails).length - 1].y : ''} </h5>
                                                                </div>
                                                                <label onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })} className='text-white md:text-base md:block hidden text-[16px] w-max hover:cursor-pointer'>{item.name.length > 25 ? item.name.substr(0, 20) + '...' : item.name}</label>
                                                                <label onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })} className='text-white md:text-base md:hidden block text-[14px] w-max hover:cursor-pointer'>{item.name.length > 14 ? item.name.substr(0, 13) + '...' + '...' : item.name}</label>
                                                            </div>
                                                        </motion.div> : null
                                                    )}
                                                </div>
                                                <div className='flex flex-row items-center my-8 justify-center w-full '>
                                                    <div className='w-full border-b-[1px] border-b-gray-700'></div>
                                                    <div onClick={() => FilteringBasedOnOtt(JSON.parse(e[0].ottIds)[0])} className='w-max whitespace-nowrap hover:bg-primary_color hover:border-primary_color duration-300 cursor-pointer md:px-12 px-5 rounded-md md:py-3 py-2 text-[12px] text-sm text-white border-[2px] border-gray-700'>+ View More</div>
                                                    <div className='w-full border-b-[1px] border-b-gray-700'></div>
                                                </div>
                                                <div className='container mx-auto mb-5 px-3'>
                                                    {HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />}
                                                </div>
                                            </div> : null
                                        )
                                    }
                                </motion.div>) : null) :
                            FilterArrayDiv.length > 0 ?
                                <div>
                                    {
                                        FilterArrayDiv.map((e, index1) =>
                                            <div>
                                                <div key={index1} className='container m-auto px-3 grid lg:grid-cols-4 grid-cols-2 gap-x-5 mt-2'>
                                                    {e.map((item, index) =>
                                                        <div key={index} className='flex flex-col mb-5 hover:scale-105 duration-300'>
                                                            <div className='flex flex-col  justify-end relative rounded-md hover:bg-[#24baef] duration-300'>
                                                                <img onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })}
                                                                    className='md:h-[200px] h-[100px] hover:opacity-60 hover:cursor-pointer w-full  rounded-md object-top object-cover ' src={`https://storage.googleapis.com/cinimini-console/webseries/${item.id}/${item.bannerUrl}`} alt='' />
                                                                <img id="Video" onClick={() => MovieTrailerFunction(item.youtubeLink)} className=' sm:w-[35px] sm:h-[35px] w-[25px] h-[25px] hover:cursor-pointer absolute opacity-50 hover:opacity-100 sm:bottom-[40%] bottom-[40%] left-[40%] sm:left-[44%]' src={playcircle} />
                                                                {JSON.parse(item.ottIds).length > 0 ? <img className='w-[40px] h-[30px] sm:w-[60px] sm:h-[40px] m-1 absolute top-0 hover:scale-110 cursor-pointer duration-200 rounded-md' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + FetchSeriesName(JSON.parse(item.ottIds)[0])} /> : null}
                                                                <div className='flex flex-row absolute my-2 mx-2 gap-x-2 items-center'>
                                                                    <img className='md:w-[18px] w-[15px] object-contain' src={Star} alt='' />
                                                                    <div className='flex flex-row items-center justify-center gap-x-4'>
                                                                        <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(item.rating)}/10</h6>
                                                                        <div className='flex flex-row items-center justify-center gap-x-1'>
                                                                            <img id='Rating' onClick={() => SeriesRatingFunction(item)} className='md:w-[20px] w-[15px] cursor-pointer object-contain' src={webseriesRating != null && webseriesRating.findIndex(e1 => e1.i == item.id) != -1 ? Star : starnotfill} alt='' />
                                                                            <h6 className='text-gray-200 font-normal cursor-default text-sm'>{webseriesRating != null ? webseriesRating.findIndex(e1 => e1.i == item.id) != -1 ? webseriesRating.find(e1 => e1.i == item.id).r + '/10' : null : null}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-col pt-1'>
                                                                <div className=' flex flex-row items-center justify-between rounded-md'>
                                                                    <div className='md:block hidden text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                                        <div className=' flex flex-row'>
                                                                            {
                                                                                JSON.parse(item.genreIds) != null && JSON.parse(item.genreIds).map((e, index) => GenreCollection.findIndex(e1 => e1.id == e) !== -1 ?
                                                                                    <div key={index} className='flex flex-row items-center'>
                                                                                        <h6 onClick={() => FetchingSeriesGenre(e)} className='md:text-[14px] text-[12px] hover:cursor-pointer hover:text-[#24baef] text-[#949cb0] font-normal'>{GenreCollection.find(e2 => e2.id == e).name}</h6>
                                                                                        <h6 className='self-end pr-1 text-sm'>{index < JSON.parse(item.genreIds).length - 1 ? ',' : ''}</h6>
                                                                                    </div> : null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='md:hidden block text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                                        <div className=' flex flex-row'>
                                                                            {
                                                                                JSON.parse(item.genreIds) != null && JSON.parse(item.genreIds).map((e, index) => GenreCollection.findIndex(e1 => e1.id == e) !== -1 ? index < 2 ?
                                                                                    <div key={index} className='flex flex-row items-center'>
                                                                                        <h6 onClick={() => FetchingSeriesGenre(e)} className='md:text-[14px] text-[12px] hover:cursor-pointer hover:text-[#24baef] text-[#949cb0] font-normal'>{GenreCollection.find(e2 => e2.id == e).name}</h6>
                                                                                        <h6 className={`self-end pr-1 text-sm ${index < 1 ? 'block' : 'hidden'}`}>{index < JSON.parse(item.genreIds).length - 1 ? ',' : ''}</h6>
                                                                                    </div> : null : null
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <h5 className='text-[#949cb0] hover:cursor-default md:block hidden text-[12px] font-normal tracking-widest'>{(JSON.parse(item.seasonDetails))[0].y} {JSON.parse(item.seasonDetails).length > 1 ? Number((JSON.parse(item.seasonDetails))[JSON.parse(item.seasonDetails).length - 1].y) == Number((JSON.parse(item.seasonDetails))[0].y) ? '' : ' - ' + (JSON.parse(item.seasonDetails))[JSON.parse(item.seasonDetails).length - 1].y : ''} </h5>
                                                                </div>
                                                                <label onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })} className='text-white md:text-base md:block hidden text-[16px] w-max hover:cursor-pointer'>{item.name.length > 25 ? item.name.substr(0, 20) + '...' : item.name}</label>
                                                                <label onClick={() => navigate("/AboutWebseries", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), webseriesRating: webseriesRating, Languages: FilterLanguages(item) } })} className='text-white md:text-base md:hidden block text-[14px] w-max hover:cursor-pointer'>{item.name.length > 14 ? item.name.substr(0, 13) + '...' + '...' : item.name}</label>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='container mx-auto mb-5 px-3'>
                                                    {/* {BottomBanner.length > 0 && <BannerComponent sectionId={Square[0].sectionId} internal={Square[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />} */}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                : <div className='container m-auto px-3'> <h4 className='text-white self-center my-5 font-normal'>Webseries Not found</h4></div>
                    }
                </div>
            </div>
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default Webseries

