import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from './Header';
import Footer from './Footer';
import { FaArrowLeftLong } from 'react-icons/fa6';

export default function AboutOttPlatforms() {
    const location = useLocation()
    const navigate=useNavigate()
    const datas = location.state.data;
    return (
        <div className='w-full lg:h-[80vh] h-[100vh] bg-[#131722] lg:px-0 px-2'>
            <Header props={{ selected: 'Ott' }} />
            <div className='relative self-start'>
                <div onClick={() => navigate(-1)} className='absolute  hover:-translate-x-3 hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] mx-10 my-5 z-[99999]'>
                    <FaArrowLeftLong />
                    <h5 className='font-normal text-[14px]'>Back</h5>
                </div>
            </div>
            <div className='container m-auto bg-[#131722] py-5 flex lg:flex-row flex-col lg:gap-x-8'>
                <img className='w-[350px] h-[250px] rounded-md self-center lg:self-start' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + datas.iconUrl} />
                <div className='gap-y-2 mt-2'>
                    <h1 className='text-white font-bold tracking-wider'>{datas.name}</h1>
                    <h5 className='text-gray-300 font-normal mt-3'>{datas.description}</h5>
                </div>
            </div>
            <Footer />
        </div>
    )
}
