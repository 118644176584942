import React, { useEffect, useState } from 'react';
import Vijay from "../Assets/Vijay.webp";
import Aijth from "../Assets/Ajith_kumar.jpg";
import Rajini from "../Assets/RajniKanth.jpg";
import Kamal from "../Assets/Kamal.jpg";
import Surya from "../Assets/Surya.jpg";
import sethu from "../Assets/VijaySethupathi.jpg";
import Karthi from "../Assets/Karthi.jpg";
import Siva from "../Assets/Sivakarthi.jpg";
import { GetTheData } from '../Application/Api';
import { useNavigate } from 'react-router-dom';
function Actors({ props }) {
    useEffect(() => {
        // FetchingCelebrities()
        FetchingCategoryData()
    }, [])

    const [CelebrityCollection, setCelebrityCollection] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('celebrityCategories')
            setCelebrityCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingCategoryDataByID(params) {
        const value = JSON.parse(params)
        try {
            const data = CelebrityCollection.find(e => e.id === Number(value[0]))
            return data.name;

        } catch (error) {
            console.log('error genre');
        }
    }
    const navigate = useNavigate();
    return (
        <div className='w-full lg:pl-3 pl-0'>
            <div className='grid lg:grid-cols-3 2xl:grid-cols-4 md:grid-cols-2 gap-5  grid-cols-1'>
                {props.movieCelebrities.map((item, index) => 
                    (<div key={index} className='flex  flex-row gap-3  hover:shadow-sm rounded-md p-2 duration-300 items-center'>
                        {(JSON.parse(item.profilePictureUrls))!=null?<img onClick={() => navigate("/Actorinfo", { state: { data: item } })} className='w-[60px] h-[60px] object-top hover:cursor-pointer rounded-full object-cover gap-6 ' src={'https://storage.googleapis.com/cinimini-console/celebrities/' + item.id + '/' + (JSON.parse(item.profilePictureUrls)[0])} alt='' />:null}
                        <div className='space-y-1'>
                            <label onClick={() => navigate("/Actorinfo", { state: { data: item } })} className='text-white hover:text-white font-medium tracking-wide '>{item.name}</label>
                            <div style={{ fontSize: 13 }} className='text-gray-400 hover:cursor-default tracking-wider'>{FetchingCategoryDataByID(item.categoryId)}</div>
                        </div>
                    </div>) 
                )}
            </div>
        </div>
    )
}

export default Actors
