import React from 'react'
import { FiPhoneCall } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { TbClock24 } from "react-icons/tb";
import Header from './Header';
import Footer from './Footer';
import { FaCommentAlt } from 'react-icons/fa';
import mail from '../Assets/mail.png'
function ContactUs() {
    return (
        <div className='h-[100vh] overflow-y-auto bg-[#131722] w-full flex flex-col items-center'>
            <Header props={{ selected: "ContactUs" }} />
            <div className='flex flex-col items-center'>
                <h1 className='mt-20 text-white tracking-wider text-4xl font-bold font-serif'>SHALL WE GET STARTED?</h1>
                <div className='border-b-[1px] border-b-gray-400 w-[60%] mt-5'></div>
                <p className='text-sm  mt-5'>Just drop a note to us and our best brains will get back to you with a customized proposal at the earliest.</p>
                <div className='flex flex-row gap-x-10 items-center justify-between mt-10'>
                    <div className='flex flex-col gap-y-5 items-center justify-start'>
                        <div>
                            <h5 className='text-gray-300 font-normal tracking-wide'>Please enter your name.</h5>
                            <input placeholder='YOUR NAME *' className='w-[550px] h-[62px] rounded-md px-2 outline-none hover:border-2 border-[#24baef]' />
                        </div>
                        <div>
                            <h5 className='text-gray-300 font-normal tracking-wide'>Your e-mail id required! Enter a valid e-mail</h5>
                            <input placeholder='YOUR EMAIL *' className='w-[550px] h-[62px] rounded-md px-2 hover:border-2 border-[#24baef] outline-none' />
                        </div>
                        <div>
                            <h5 className='text-gray-300 font-normal tracking-wide'>Please enter your contact phone number</h5>
                            <input placeholder='YOUR PHONE *' className='w-[550px] h-[62px] rounded-md px-2 hover:border-2 border-[#24baef] outline-none' />
                        </div>
                    </div>
                    <textarea placeholder='YOUR MESSAGE *' className='w-[550px] rounded-md hover:border-2 border-[#24baef] outline-none p-3 h-[236px]' />
                </div>
                <div className='w-[230px] h-[60px] hover:cursor-pointer bg-[#24baef] flex flex-col justify-center items-center rounded-md mt-10'>
                    <h5 className='uppercase text-[16px]'>Send Message</h5>
                </div>
                <div className='border-b-[1px] border-b-gray-400 w-full mt-5'></div>
                <h1 className='mt-3 text-white tracking-wider text-4xl font-bold font-serif'>OR WRITE TO US DIRECTLY</h1>
                <div className='flex flex-row items-center mt-5 justify-evenly w-full mb-10'>
                    <div className='flex flex-col items-center'>
                        <img className='w-[40px] h-[40px] ' src={mail}/>
                        <h5 className='text-white font-normal tracking-wider'>ads@cinimini.com</h5>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img className='w-[40px] h-[40px]]' src={mail}/>
                        <h5 className='text-white font-normal tracking-wider'>editor@cinimini.com</h5>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img className='w-[40px] h-[40px]' src={mail}/>
                        <h5 className='text-white font-normal tracking-wider'>webmaster@cinimini.com</h5>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs;

{/* <div className=' w-[40%] p-5 flex flex-col justify-around'>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex flex-row items-center gap-x-3'>
                        <FiPhoneCall className='w-[18px] h-[18px] text-black' />
                        <label className='font-serif font-semibold uppercase text-black'>Call Us</label>
                    </div>
                    <p className='text-gray-500 text-[12px]'>1 (234) 564-897 , 1 (234) 987-653</p>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex flex-row items-center gap-x-3'>
                        <IoLocationOutline className='w-[18px] h-[18px] text-black' />
                        <label className='font-serif font-semibold uppercase text-black'>Location</label>
                    </div>
                    <p className='text-gray-500 text-[12px]'>121 Rock Street , 21 Avenue , New York</p>
                </div>
                <div className='flex flex-col gap-y-3'>
                    <div className='flex flex-row items-center gap-x-3'>
                        <TbClock24 className='w-[18px] h-[18px] text-black' />
                        <label className='font-serif font-semibold uppercase text-black'>Business Hours</label>
                    </div>
                    <p className='text-gray-500 text-[12px]'>Mon - Sat ....... 10 am - 7 pm , Sun ......... Closed</p>
                </div>
            </div>
            <div className='bg-[#0e0d12] w-[55%] p-5 h-[100%] rounded-md'>
                <h2 className='uppercase font-semibold tracking-wider text-white font-serif'>Contact US</h2>
                <h4 className='text-white mt-3 text-[14px] font-normal'>Name</h4>
                <input className='w-full bg-[#0e0d12] text-[13px] border-b-2 border-b-white text-white p-1 outline-none mt-2 rounded-sm' placeholder='Enter The Name' />
                <h4 className='text-white mt-2 text-[14px] font-normal'>Email</h4>
                <input className='w-full bg-[#0e0d12] text-[13px] border-b-2 border-b-white text-white p-1 outline-none mt-2 rounded-sm' placeholder='Enter The Email' />
                <div className='flex flex-row items-center justify-center'>
                    <input className='text-white' type='file' />
                    <div className='bg-white hover:text-black w-max px-8 py-2 rounded-md mt-5 hover:cursor-pointer flex flex-col justify-between items-center'>
                        <h5 className='uppercase font-serif  '>Submit</h5>
                    </div>
                </div>
            </div> */}