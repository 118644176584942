import React, { useEffect, useState } from 'react';
import Cinimini from "../Assets/Ciniminilogo.png";
import { useNavigate } from 'react-router-dom'
import { SlSocialYoutube } from "react-icons/sl";

import { FaFacebook, FaGooglePlusG, FaInstagram, FaYoutube } from 'react-icons/fa';
import { LiaFacebookF } from "react-icons/lia";

import axios from 'axios';
function Footer() {
    // const [selected, setSelected] = useState("Home");
    const navigate = useNavigate();

    // const OnOption = (_name, _route) => {
    //     setSelected(_name)
    //     navigate("/" + _route);
    // }

    useEffect(() => {
        FetchingCelCategoryData()
        FetchingGenreData()
    }, [])
    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieGenres?limit=20&offset=0`)
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    const [CelebrityCollection, setCelebrityCollection] = useState([])
    async function FetchingCelCategoryData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrityCategories?limit=20&offset=0`)
            setCelebrityCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FunctionCalling(e) {
        navigate("/CelebrityDropdownpage", { state: { data: e.id } })
    }
    function FunctionCallingForMovieGenre(e) {
        navigate("/MovieDropDownpage", { state: { data: e.id } })
    }

    function FromHome() {
        navigate('/')
        window.location.reload()
    }
    
    return (
        <div className='flex flex-col w-full bg-[#0a0d14] border-t-[1px]  border-t-gray-700'>
            <div className='container hidden xl:block m-auto px-3'>
                <div className='w-full flex flex-col border-b-[1px] border-b-gray-500 md:flex-row md:justify-between items-center gap-y-5 py-5 justify-center'>
                    <div onClick={() => FromHome()} className=' hover:cursor-pointer'>
                        <img className='w-[60px] object-contain h-[60px]' src={Cinimini} />
                    </div>
                    <div className='md:flex flex-row grid grid-cols-2 gap-y-5 lg:gap-y-0 gap-x-5 lg:gap-x-8 items-center overflow-x-auto justify-center'>
                        <a target='_blank' href='https://www.facebook.com/cinimini.review.14' ><div  className='flex flex-row hover:cursor-pointer justify-center md:justify-end gap-x-2 items-center '>
                            <LiaFacebookF className='text-white' />
                            <h5 className='text-gray-500 text-[14px] hover:text-[#ffffff]  hover:cursor-pointer font-normal'>Facebook</h5>
                        </div></a>
                        <a target='_blank' href='https://www.youtube.com/channel/UCRnhf3cHymPJPr2IPBZvCzQ'><div  className='flex flex-r ow hover:cursor-pointer gap-x-2 justify-center md:justify-end items-center'>
                            <SlSocialYoutube className='text-white' />
                            <h5 className='text-gray-500 text-[14px] hover:text-[#ffffff]  hover:cursor-pointer font-normal'>YouTube</h5>
                        </div></a>
                        <a target='_blank' href='https://www.instagram.com/cinimini_review/'><div className='flex flex-row hover:cursor-pointer gap-x-2 items-center  justify-center md:justify-end'>
                            <FaInstagram className='text-white' />
                            <h5 className='text-gray-500 text-[14px]  hover:text-[#ffffff]   font-normal'>Instagram</h5>
                        </div></a>
                        {/* <div onClick={() => navigate("/")} className='flex flex-row hover:cursor-pointer gap-x-2 justify-center md:justify-end items-center'>
                            <FaXTwitter className='text-white' />
                            <h5 className='text-gray-500 text-[14px] hover:text-[#ffffff]  hover:cursor-pointer font-normal'>Twitter</h5>
                        </div>
                        <div onClick={() => navigate("/")} className='flex flex-row hover:cursor-pointer gap-x-2 items-center  justify-center md:justify-end'>
                            <FaGooglePlusG className='text-[20px] text-white' />
                            <h5 className=' text-[14px] text-gray-500 hover:text-[#ffffff] font-normal'>Google +</h5>
                        </div> */}
                    </div>
                </div>
                <div className='py-5 lg:gap-x-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4'>
                    <div className=' '>
                        <h4 className='text-white text-[16px] font-normal hover:cursor-default tracking-wide'>Movie Categories</h4>
                        <div className='grid grid-cols-2 my-5 gap-y-3'>
                            {
                                GenreCollection.map((e, index) =>
                                    <label onClick={() => FunctionCallingForMovieGenre(e)} key={index} className='text-[14px] hover:text-white text-gray-500 w-max'>{e.name}</label>
                                )
                            }
                        </div>
                    </div>
                    <div className=' '>
                        <h4 className='text-white font-normal text-[16px] hover:cursor-default tracking-wide'>Celebrity Categories</h4>
                        <div className='grid grid-cols-2 my-5 gap-y-3'>
                            {
                                CelebrityCollection.map((e, index) =>
                                    <label onClick={() => FunctionCalling(e)} key={index} className='text-[14px] hover:text-white text-gray-500 w-max'>{e.name}</label>
                                )
                            }
                        </div>
                    </div>
                    <div className=' '>
                        <h4 className='text-white text-[16px] font-normal hover:cursor-default tracking-wide'>Webseries Categories</h4>
                        <div className='grid grid-cols-2 my-5 gap-y-3'>
                            {
                                GenreCollection.map((e, index) =>
                                    <label onClick={() => navigate("/Webseries", { state: { data: e.id } })} key={index} className='text-[14px] hover:text-white text-gray-500 w-max'>{e.name}</label>
                                )
                            }
                        </div>
                    </div>
                    <div className=' border-l-[1px] border-l-gray-600 pl-5 '>
                        <h4 className='text-white text-[16px] font-normal hover:cursor-default tracking-wide'>Support</h4>
                        <div className='flex flex-col my-5 gap-y-3'>
                            <label className='text-[14px] hover:text-white text-gray-500 w-max'>My Account</label>
                            <label className='text-[14px] hover:text-white text-gray-500 w-max'>FAQ</label>
                            <label className='text-[14px] hover:text-white text-gray-500 w-max'>Help Center</label>
                            <label onClick={() => navigate('/ContactUsPage')} className='text-[14px] hover:text-white text-gray-500 w-max'>Contact</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#06090f] w-full justify-center flex flex-col'>
                <div className='container flex flex-row m-auto gap-x-4 xl:justify-between justify-center lg:px-3'>
                    <p className='lg:text-[13px] text-[12px] py-5'>Copyright © 2024, Ciniminireview. All Rights Reserved</p>
                    <div className=' xl:flex hidden flex-row items-center gap-x-5'>
                        <p className='lg:text-[13px] text-[12px] hover:cursor-pointer hover:text-[#ffffff] '> Ad Choice</p>
                        <p onClick={() => navigate('/PrivacyPolicyPage', { state: { data: 'Terms of Use' } })} className='lg:text-[13px] text-[12px] hover:cursor-pointer hover:text-[#ffffff] '>Terms of Use</p>
                        <p onClick={() => navigate('/PrivacyPolicyPage', { state: { data: 'User Agreement' } })} className='lg:text-[13px] text-[12px] hover:cursor-pointer hover:text-[#ffffff] '>User Agreement</p>
                        <p onClick={()=>navigate('/Careerpage')} className='lg:text-[13px] text-[12px] hover:cursor-pointer hover:text-[#ffffff] '>Careers</p>
                        <p onClick={() => navigate('/PrivacyPolicyPage', { state: { data: 'PrivacyPolicy' } })} className='lg:text-[13px] text-[12px] hover:cursor-pointer hover:text-[#ffffff] '>Privacy Policy</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer