import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GetTheData } from '../Application/Api';
import axios from 'axios';

function BannerComponent({ internal, sectionId, ImageUrl, height, id, redirectUrl }) {
    useEffect(() => {
        if(internal!==0){
            let path=sectionId==4?"webseries":sectionId==3?"movies":sectionId==2?"celebrities":sectionId==1?"news":null
            FetchingWebseries(path)
        }
    }, [])
   
   const [data, setData] = useState([])
   async function FetchingWebseries(path) {
        const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specific/${path}/${redirectUrl}`)        
        if(response.status==200){
            setData(response.data[0])
            console.log(response.data);
        }
    }   

    const navigate = useNavigate()
    return (
        <div className='relative overflow-y-hidden'>
            {internal === 0 ? <a href={redirectUrl}>
                <h5 className='text-primary_color m-2 absolute font-normal right-0'>Ad</h5>
                <img className={`h-[${height}px] w-full object-fill `} src={`https://storage.googleapis.com/cinimini-console/advertisements/${id}/${ImageUrl}`} />
            </a> :data&&
                <div onClick={() => navigate(`${sectionId==4?"/AboutWebseries":sectionId==3?"/AboutMovie":sectionId==2?"/Celebrity":sectionId==1?"/News":null}`, { state: { data:data }})}>
                    <h5 className='text-primary_color m-2 absolute font-normal right-0'>Ad</h5>
                    <img className={`h-[${height}px] w-full object-fill `} src={`https://storage.googleapis.com/cinimini-console/advertisements/${id}/${ImageUrl}`} />
                </div>
            }
        </div>
    )
}

export default BannerComponent