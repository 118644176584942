import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import Header from "./Component/Header";
import News from "./Component/News";
import Movie from "./Component/Movie";
import AboutMovie from "./Component/AboutMovie";
import Celebrities from "./Component/Celebrities";
import Actorinfo from "./Component/Actorinfo";
import KnowMore from "./Component/KnowMore";
import Footer from "./Component/Footer";
import Videos from "./Component/Videos";
import Event from "./Component/Event";
import Contact from "./Component/Contact";
import AboutOttPlatforms from "./Component/AboutOttPlatforms";
import Login from "./Component/Login";
import Profilepage from "./Component/Profilepage";
import CelebrityDropdownpage from "./Component/CelebrityDropdownpage";
import MovieDropDownpage from "./Component/MovieDropDownpage";
import PrivacyPolicyPage from "./Component/PrivacyPolicyPage";
import Careerpage from "./Component/Careerpage";
import Webseries from "./Component/Webseries";
import AboutWebseries from "./Component/AboutWebseries";
import ContactUs from "./Component/ContactUs";
import { useEffect, useState } from "react";
import { GetAdvertisements } from "./Application/Api";
import ContactUsPage from "./Component/ContactUsPage";

function App() {


  return (
    <BrowserRouter>
      {/* <Header props={{selected:"e"}}/> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/News' element={<News />} />
        <Route path='/Movies' element={<Movie />} />
        <Route path='/Celebrity' element={<Celebrities />} />
        <Route path='/AboutMovie' element={<AboutMovie />} />
        <Route path='/Actorinfo' element={<Actorinfo />} />
        <Route path='/KnowMore' element={<KnowMore />} />
        <Route path='/Video' element={<Videos />} />
        <Route path='/Events' element={<Event />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/AboutOttPlatforms' element={<AboutOttPlatforms />} />
        <Route path='/Profilepage' element={<Profilepage />} />
        <Route path='/CelebrityDropdownpage' element={<CelebrityDropdownpage />} />
        <Route path='/MovieDropDownpage' element={<MovieDropDownpage />} />
        <Route path='/PrivacyPolicyPage' element={<PrivacyPolicyPage />} />
        <Route path='/Careerpage' element={<Careerpage />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Webseries" element={<Webseries />} />
        <Route path="/AboutWebseries" element={<AboutWebseries />} />
        <Route path="/ContactUsPage" element={<ContactUsPage />} />
      </Routes>
      {/* <Footer/> */}
    </BrowserRouter>
  );
}

export default App;
