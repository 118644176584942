import React, { useEffect, useState } from 'react'

import Cinimini from "../Assets/Ciniminilogo.png";

import { FaAngleDown, FaAngleUp, FaBars, FaInstagram, FaTimes, FaTruck, FaUserCircle, FaYoutube } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'

import usericon from '../Assets/usericon.png'
import { GetAdvertisements, GetTheData } from '../Application/Api'
import axios from 'axios'
import { easeInOut, easeOut, motion as m } from 'framer-motion'
import { ToastContainer, toast } from 'react-toastify'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'

import { auth } from './FirebaseInit'
import { HiOutlineMail } from "react-icons/hi";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { RiFacebookCircleLine, RiContactsLine } from "react-icons/ri";
import { LuContact2 } from "react-icons/lu";

import { motion } from 'framer-motion'

import BannerComponent from './BannerComponent'
import { LiaFacebookF } from 'react-icons/lia'
import { SlSocialYoutube } from 'react-icons/sl'
function Header({ props }) {

     useEffect(() => {
          FetchingAdvertisements()
     }, [])
     const [HeaderBanner, setHeaderBanner] = useState('')
     async function FetchingAdvertisements() {
          const data = await GetAdvertisements()
          if (data.length > 0) {
               const Header = data.filter((e) => e.destination == 7 && e.placement == 1)
               setHeaderBanner(Header)
          }
     }

     const [selected, setSelected] = useState("Home");
     const navigate = useNavigate();

     const OnOption = (_name, _route) => {
          setSelected(_name)
          navigate("/" + _route);
     }
     const [pass, set_pass] = useState("")
     const onclickpass = () => {
          set_pass(!pass)
     }
     const [active, setActive] = useState(false);
     const OnClickMenu = () => {
          setActive(true)
     }

     useEffect(() => {
          window.addEventListener('wheel', (e) => {
               setShowLoginPopUp(false);
               setShowRegisterPage(false)
               setPhoneNumber('')

          })
          window.addEventListener('click', (e) => {
               if (e.target.id != 'login') {
                    setShowLoginPages(false)
                    setShowRegisterPage(false)
                    setPhoneNumber('')
                    setUserData(null)
               }
          })
     }, [])

     const [CurrentNumber, setCurrentNumber] = useState('')
     useEffect(() => {
          const auth = getAuth();
          onAuthStateChanged(auth, (data) => {
               console.log(data);
               if (data) {
                    setUser(data)
                    setCurrentNumber(data.phoneNumber.substring(3, data.phoneNumber.length));
                    FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber.length))
               } else {
                    setUser(data)
               }
          });
     }, [])

     const OnSignOutFunction = async () => {
          const auth = getAuth();
          await signOut(auth)
               .then(() => {
                    toast("Logout Successfully Done", { autoClose: true, pauseOnHover: false, containerId: 'id1' })
                    setUserName('')
                    OnOption("Home", "")
                    setUserData(null)
                    setUser(null)
                    window.location.reload()
               }).catch((error) => {
                    console.log(error);
               });
     }
     const [userInfo, setUserInfo] = useState('')
     const [UserName, setUserName] = useState('')
     const [ProfileImage, setProfileImage] = useState('')
     async function FetchingUserDetailsFromAPi(Number) {
          try {
               const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${Number}`)
               if (response.data.length > 0) {
                    setUserInfo(response.data[0])
                    setUserName(response.data[0].userName)
                    setProfileImage(response.data[0].profilePictureUrl)
                    console.log(response.data[0].profilePictureUrl);
               }
               else {
                    setShowRegisterPage(true)
               }
          } catch (error) {
               console.log(error.message);
          }
     }
     useEffect(() => {
          FetchingCategoryData()
     }, [])




     const [CelebrityCollection, setCelebrityCollection] = useState([])
     async function FetchingCategoryData() {
          try {
               const response = await GetTheData('celebrityCategories')
               setCelebrityCollection(response.data)
          } catch (error) {
               console.log(error.message);
          }
     }
     const [CelebrityDropDown, setCelebrityDropDown] = useState(false)

     function FunctionCalling(e) {
          setCelebrityDropDown(false)
          navigate("/CelebrityDropdownpage", { state: { data: e.id } })
     }
     const [ShowLoginPopUp, setShowLoginPopUp] = useState(false)
     const [showLoginPages, setShowLoginPages] = useState(false)
     const [ShowRegisterPage, setShowRegisterPage] = useState(false)
     useEffect(() => {
          window.addEventListener('wheel', () => {
               setCelebrityDropDown(false)
               setShowLoginPages(false)
               setShowRegisterPage(false)
          })
     }, [])
     const [phoneNumber, setPhoneNumber] = useState("");
     const [user, setUser] = useState(null)
     const [UserData, setUserData] = useState(null)
     async function PhoneNumberAuthentication() {
          if (phoneNumber.length === 10) {
               try {
                    const recapcha = new RecaptchaVerifier(auth, 'recaptcha-container', {})
                    console.log(recapcha);
                    const num = "+91 " + phoneNumber
                    console.log(num);
                    await signInWithPhoneNumber(auth, num, recapcha)
                         .then((response) => {
                              console.log(response);
                              setUserData(response)
                         }).catch((error) => {
                              console.log(error);
                         })

               } catch (error) {
                    console.log(error);
               }
          }
          else {
               toast('Invalid Mobile Number', { containerId: 'id2' })
          }

     }
     const [otp, setotp] = useState("")
     async function VerifyOTP() {
          try {
               await UserData.confirm(otp)
                    .then((res) => {
                         console.log(res);
                         setShowLoginPages(false)
                         toast("Login Successfully Done", { autoClose: true, pauseOnHover: false, containerId: 'id1' })
                         FetchingUserDetailsFromAPi(phoneNumber)
                         setUser(UserData)
                    }).catch((error) => {
                         console.log(error);
                         setUser(null)
                         toast(error.message, { containerId: 'id2' })
                    })
          } catch (error) {
               console.log(error.messgae);
               toast(error.message, { containerId: 'id2' })
          }

     }
     const [Counter, setCounter] = useState(59)
     useEffect(() => {
          const timer = Counter > 0 && setInterval(() => setCounter(Counter - 1), 1000);
          return () => clearInterval(timer)
     }, [Counter])

     const [registerName, setRegisterName] = useState("")
     const [registerEmail, setRegisterEmail] = useState("")
     const [registerNumber, setRegisterNumber] = useState("")
     const [registerAddress, setRegisterAddress] = useState("")
     const [registerDOB, setregisterDOB] = useState('')
     async function PostingTheNewUser() {
          if (user !== null) {
               try {
                    const res =
                    {
                         name: registerName,
                         profilePictureUrl: "",
                         mobileNumber: phoneNumber,
                         deviceRegistrationId: "",
                         email: '',
                         pointsEarned: 0,
                         address: '',
                         dateOfCreation: returnTheReleaseDate(new Date()),
                         active: 1,
                         dateOfBirth: null,
                         movieRating: null,
                         newsFeedLikes: null,
                         userName: registerName
                    }
                    const response = await axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/users`, res)
                    if (response.status == 200) {
                         console.log(response);
                         FetchingUserDetailsFromAPi(phoneNumber)
                         setShowRegisterPage(false)
                         toast("Register Successfully Done", { autoClose: true, pauseOnHover: false, containerId: 'id1' })
                    }
               } catch (error) {
                    console.log(error);
               }
          }
          else {
               toast('Login Do First', { autoClose: true, pauseOnHover: false, containerId: 'id2' })
               setShowRegisterPage(false)
               setShowLoginPages(true)
          }

     }
     const returnTheReleaseDate = (_date) => {
          let year = new Date(_date).getUTCFullYear();
          let month = (Number(new Date(_date).getUTCMonth()) + 1).toString().length < 2 ? (Number(new Date(_date).getUTCMonth()) + 1).toString().padStart(2, 0) : (Number(new Date(_date).getUTCMonth()) + 1).toString();
          let date = new Date(_date).getUTCDate().toString().length < 2 ? new Date(_date).getUTCDate().toString().padStart(2, 0) : new Date(_date).getUTCDate().toString();
          let final = year + "-" + month + "-" + date
          return final;
     }

     function OpenLoginPageInMobile() {
          setShowLoginPages(true)
          setActive(false)
     }
     return (
          <div onMouseLeave={() => setShowLoginPopUp(false)} className='w-full relative flex flex-col z-50 '>
               {(showLoginPages || ShowRegisterPage) && <div className='w-full h-[100vh] overflow-auto opacity-60 bg-transparent/90 backdrop-blur-lg absolute'></div>}
               <ToastContainer containerId='id1' position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'white' }} />
               <ToastContainer containerId='id2' position='top-center' toastStyle={{ backgroundColor: 'red', color: 'white' }} />
               {
                    showLoginPages === true ?
                         <motion.div id='login' initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='self-center transition-all duration-500 flex flex-col absolute z-[9999] top-[20vh] rounded-lg bg-white gap-y-2  '>
                              <div id='login' className='container w-full md:w-[800px] md:h-[435px] flex flex-col z-[99] gap-y-5 justify-center items-center'>
                                   <div id='login' className='md:w-[373px] px-10 py-5 lg:py-0 lg:px-0 w-full  h-[343px] '>
                                        <h2 id='login' className={`text-[25px] text-black mb-[28px] font-normal tracking-wider`}>Login</h2>
                                        {
                                             UserData === null ?
                                                  (<div id='login' className="flex flex-col">
                                                       <label id='login' htmlFor="new" className=" text-[15px] font-medium hover:text-black cursor-default tracking-wider text-black  ">
                                                            Mobile Number*
                                                       </label>
                                                       <div id='login' className="mb-[26px] mt-3">
                                                            <div id='login' className="border-b-2 border-b-gray-200 flex flex-row items-center sm:max-w-md">
                                                                 <h4 id='login' className='text-gray-500 text-[15px] font-normal' >+91</h4>
                                                                 <input
                                                                      type="number"
                                                                      name="new"
                                                                      id="login"
                                                                      style={{ appearance: 'none' }}
                                                                      onChange={(e) => setPhoneNumber(e.target.value)} onPointerEnter={() => setSelected("Phone")} onPointerLeave={() => setSelected(null)}
                                                                      className=" tracking-wide pl-2 bg-transparent w-full py-1.5 rounded-md text-gray-500 outline-none  sm:text-[15px] "
                                                                 />
                                                            </div>
                                                       </div>
                                                       <div className='overflow-x-hidden' id='login'>
                                                            <div className='w-[100px] md:w-full' id='recaptcha-container' style={{ marginBottom: '26px' }}></div>
                                                       </div>
                                                       <button
                                                            onClick={() => PhoneNumberAuthentication()}
                                                            id='login'
                                                            type="submit"
                                                            disabled={phoneNumber.length === 10 ? false : true}
                                                            className={`rounded-md tracking-widest text-white px-4 py-4 mb-[26px] text-sm font-semibold  w-[120px] shadow-sm ${phoneNumber.length === 10 ? 'bg-[#24baef] hover:bg-[#0693e3]' : 'bg-gray-200'}   duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}>
                                                            Send OTP
                                                       </button>
                                                       {/* <div id='login' className='flex flex-row self-center items-center '>
                                                            <h5 className='text-gray-400 font-normal' id='login'>Not registered yet ? </h5>
                                                            <label id='login' onClick={() => setShowLoginPages(false) & setShowRegisterPage(true)} className='text-[#24baef] font-normal pl-2'> Register</label>
                                                       </div> */}
                                                  </div>) :
                                                  <div id='login' className='flex flex-col gap-y-5 '>
                                                       <div id='login' className='flex flex-col gap-2'>
                                                            <p>Otp*</p>
                                                            <p className='text-[13px] flex flex-row '>A One-Time Password has been send to +91 {phoneNumber}</p>
                                                            <input id="login" type='number' onChange={(text) => setotp(text.target.value)} className='py-3 border-b-2 border-b-gray-200 outline-none'
                                                                 onPointerEnter={() => setSelected("Password")} onPointerLeave={() => setSelected(null)} placeholder='Enter the OTP' />
                                                       </div>
                                                       <button
                                                            onClick={() => VerifyOTP()}
                                                            id='login'
                                                            type="submit"
                                                            className={`rounded-md tracking-wider text-white px-3 py-4 text-sm font-semibold  w-[200px] ${otp.length === 6 ? 'bg-[#24baef] hover:bg-[#0693e3]' : 'bg-gray-200'}  duration-300 `}>
                                                            Enter OTP
                                                       </button>
                                                       {/* <div className='flex flex-row items-center gap-x-2'>
                                                            <h5 id='login' onClick={() => PhoneNumberAuthentication() & setCounter(59)} className='text-gray-500 border-b-2 w-max cursor-pointer'>Resend OTP</h5>
                                                            <span className=''>00 : {Counter.toString().padStart(2, 0)}</span>
                                                       </div> */}
                                                  </div>
                                        }
                                   </div>
                              </div>
                         </motion.div> : null
               }
               {
                    ShowRegisterPage &&
                    <motion.div id='login' initial={{ opacity: 0 }} animate={{ opacity: 1, transition: easeOut }} className='lg:w-[800px] h-[435px] self-center flex flex-col transition-all duration-300 absolute z-[9999] top-[20vh]  rounded-lg bg-white gap-y-2 '>
                         <div id='login' className='lg:w-[800px] h-[435px]  flex flex-col justify-center items-center gap-y-3'>
                              {/* <div onClick={() => setShowRegisterPage(false)} className='w-[25px] hover:cursor-pointer h-[25px] self-end rounded-full bg-white mx-2 items-center justify-center flex flex-col'>
                                   <FaTimes className='' />
                              </div> */}
                              <div id='login' className='md:w-[363px] px-10 lg:px-0 flex flex-col justify-center'>
                                   <h2 id='login' className={`text-[25px] text-black mb-[28px] font-normal tracking-wider`}>Register</h2>
                                   <div id='login' className='flex flex-col  mb-10'>
                                        <label id='login' htmlFor="new" className=" text-[15px] font-medium hover:text-black cursor-default tracking-wider text-black  ">
                                             UserName*
                                        </label>
                                        <div id='login' className="">
                                             <div id='login' className=" border-b-2  border-b-gray-200  outline-none sm:max-w-md">
                                                  <input
                                                       id='login'
                                                       type="text"
                                                       name="new"
                                                       onChange={(e) => setRegisterName(e.target.value)} onPointerEnter={() => setSelected("RegisterName")} onPointerLeave={() => setSelected(null)}
                                                       className="block  py-1.5 pl-1 w-full  text-gray-400 outline-none placeholder:text-gray-400  sm:text-[15px] "
                                                  />
                                             </div>
                                        </div>
                                   </div>
                                   <div id='login' className='flex flex-col gap-2 mb-5'>
                                        <label id='login' htmlFor="new" className=" text-[15px] font-medium hover:text-black cursor-default tracking-wider text-black  ">
                                             PhoneNumber*
                                        </label>
                                        <div id='login' className="">
                                             <div id='login' className="outline-none sm:max-w-md">
                                                  <input
                                                       id='login'
                                                       type="number"
                                                       name="new"
                                                       maxLength={10}
                                                       disabled
                                                       placeholder={phoneNumber}
                                                       className="block border-b-2 border-b-gray-200 bg-transparent py-1.5 pl-1 w-full text-gray-400 outline-none placeholder:text-gray-400  sm:text-[15px] "
                                                  />
                                             </div>
                                        </div>
                                   </div>
                                   <button
                                        onClick={() => PostingTheNewUser()}
                                        type="submit"
                                        id='login'
                                        className={`rounded-md  ${registerName.length > 0 ? 'bg-[#24baef] hover:bg-[#0693e3]' : 'bg-gray-200'}  mb-5 tracking-widest text-white px-3 py-4 text-sm font-semibold mt-5 w-[120px] shadow-sm  duration-300 `}>
                                        Register
                                   </button>
                                   <div id='login' className='flex flex-row self-center items-center'>
                                        <h5 className='text-gray-400 font-normal' >Already an User ? </h5>
                                        <label id="login" onClick={() => {
                                             setShowRegisterPage(false)
                                             setShowLoginPages(true)
                                        }} className='text-[#24baef] font-normal pl-2'>Login</label>
                                   </div>
                              </div>
                         </div>
                    </motion.div>
               }
               <div onMouseLeave={() => setShowLoginPopUp(false)} className='w-full bg-black lg:flex hidden flex-col items-center'>
                    <div className='container m-auto py-2 lg:px-3 flex flex-row items-center justify-between text-white '>
                         <div className='flex flex-row items-center justify-center gap-x-8'>
                              <label className='hover:text-white'>Share</label>
                              <a target='_blank' href='https://www.facebook.com/cinimini.review.14' ><LiaFacebookF className='w-[18px] cursor-pointer h-[20px]' /></a>
                              <a target='_blank'  href='https://www.youtube.com/channel/UCRnhf3cHymPJPr2IPBZvCzQ'><SlSocialYoutube   className='w-[18px] cursor-pointer h-[18px]' /></a>
                             <a  target='_blank' href='https://www.instagram.com/cinimini_review/'><FaInstagram className='w-[18px] text-white cursor-pointer h-[18px]' /></a> 
                         </div>
                         <div onClick={()=>navigate('/ContactUsPage')} className='flex flex-row cursor-pointer items-center justify-center gap-x-3 '>
                              {/* <label className='hover:text-white text-[15px]'>Email Us</label>
                              <HiOutlineMail className='w-[20px] text-white cursor-pointer h-[20px]' /> */}
                              <label  className='hover:text-white text-[15px] '>Contact Us</label>
                              <RiContactsLine className='w-[18px] text-white cursor-pointer h-[18px]' />
                         </div>
                         {/* <div className='flex flex-row lg:gap-4 lg:visible items-center justify-end'>
                              {userInfo.profilePictureUrl !== null ? <img onClick={() => navigate("/Profilepage", { state: { userInfo: userInfo } })}
                                   className='w-[40px] h-[40px] hover:cursor-pointer rounded-full bg-[#808080] ' src={`https://storage.googleapis.com/cinimini-console/users/${userInfo.profilePictureUrl}`} /> : null}
                              <div className='flex flex-col gap-1'>
                                   {userInfo.name !== null && <label onClick={() => navigate("/Profilepage", { state: { userInfo: userInfo } })}>{userInfo.name}</label>}
                                   <h5 id='User' onClick={() => setUser(!user)} className='text-[#FFA533] cursor-pointer'>Admin ▼</h5>
                              </div>
                              {user === true ?
                                   <div className="flex flex-col absolute py-2 px-5 border-[#233A50] rounded gap-2 bg-[#233A50] mt-[145px] border-[1px] z-20 ">
                                        <label className='text-left hover:text-[#FFA533] w-full'>User</label>
                                        <label className=' hover:text-[#FFA533]' onClick={() => navigate("/")}>Logout</label>
                                   </div>
                                   : null}
                         </div> */}
                    </div>
               </div>
               <div className='w-full bg-white lg:flex hidden flex-col items-center '>
                    <div className='container px-3 m-auto lg:h-[60px] h-0 flex flex-row items-center justify-center gap-4'>
                         <img onClick={() => navigate('/')} className='w-[20%] h-full object-contain hover:cursor-pointer' src={Cinimini} alt='' />
                         <div className='w-[80%] h-[60px] flex flex-row overflow-x-hidden justify-center'>
                              {
                                   HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />
                              }

                              {/* <BannerComponent height={'60'} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} /> */}
                         </div>
                    </div>
               </div>
               <div className='w-full bg-[#0c0e17] lg:flex hidden flex-col items-center'>
                    <div className='container px-3 lg:h-[60px] h-0 m-auto flex flex-row items-center justify-between text-white'>
                         <div onMouseEnter={() => setShowLoginPopUp(false)} className='flex flex-row  lg:w-[80%] lg:gap-x-8 items-center lg:justify-start justify-center' >
                              <label style={{ color: props.selected === "Home" ? "#24baef" : "", transition: 'ease-in-out' }} onClick={() => OnOption("Home", "", null)} className='cursor-pointer hover:text-[#24baef]'>Home</label>
                              <label style={{ color: props.selected === "News" ? "#24baef" : "", transition: 'ease-in-out' }} onClick={() => OnOption("News", "News", null)} className='cursor-pointer  hover:text-[#24baef]'>News</label>
                              <div onMouseLeave={() => setCelebrityDropDown(false)} onMouseEnter={() => setCelebrityDropDown(true)}
                                   className=' flex flex-row hover:text-[#24baef] hover:cursor-pointer duration-300 h-[60px] items-center justify-start '>
                                   <label style={{ color: CelebrityDropDown || props.selected === "Celebrity" ? "#24baef" : "" }} onClick={() => OnOption("Celebrity", "Celebrity", null)}
                                        className='hover:cursor-pointer '>Celebrity</label>
                                   {CelebrityDropDown ? <FaAngleDown className='  hover:duration-300 hover:cursor-pointer ' /> : <FaAngleUp className=' hover:duration-300 hover:cursor-pointer ' />}
                                   {
                                        CelebrityDropDown &&
                                        <div className='z-50 w-[200px] block absolute mt-[291px] bg-[#2f3942] rounded-lg py-3 px-5 '>
                                             <div onMouseLeave={() => setCelebrityDropDown(false)} className='flex flex-col gap-y-3'>
                                                  {
                                                       CelebrityCollection.map((e, index) => index < 6 ?
                                                            <label className='z-50' onClick={() => FunctionCalling(e)} key={index}>{e.name}</label> : null
                                                       )
                                                  }
                                             </div>
                                        </div>
                                   }
                              </div>
                              <label style={{ color: props.selected === "Movies" ? "#24baef" : "" }} onClick={() => OnOption("Movies", "Movies", null)} className='cursor-pointer hover:text-[#24baef]'>Movies</label>
                              <label style={{ color: props.selected === "Webseries" ? "#24baef" : "" }} onClick={() => OnOption("Webseries", "Webseries", null)} className='cursor-pointer hover:text-[#24baef]'>WebSeries</label>
                              {/* <label style={{ color: props.selected === "Events" ? "#24baef" : "" }} onClick={() => OnOption("Events", "Events", null)} className='cursor-pointer   hover:text-[#24baef]'>Events</label>
                              <label style={{ color: props.selected === "ContactUs" ? "#24baef" : "" }} onClick={() => OnOption("ContactUs", "ContactUs", null)} className='cursor-pointer hover:text-[#24baef]'>Contact</label> */}
                         </div>
                         <div className='flex flex-row items-center gap-x-3'>
                              <h4 className='text-white cursor-default font-normal text-[15px]'>{UserName}</h4>
                              {user === null ? <img src={usericon} onMouseEnter={() => setShowLoginPopUp(true)} className='w-[50px] rounded-full h-[50px] object-cover object-top self-end hover:cursor-pointer' /> : ProfileImage !== '' ?
                                   <img onMouseEnter={() => setShowLoginPopUp(true)} className='w-[50px] rounded-full h-[50px] object-cover object-top self-end hover:cursor-pointer ' src={"https://storage.googleapis.com/cinimini-console/users/" + ProfileImage} /> : <img src={usericon} onMouseEnter={() => setShowLoginPopUp(true)} className='w-[50px] rounded-full h-[50px] object-cover object-top self-end hover:cursor-pointer' />}
                         </div>
                    </div>
               </div>
               <div className='w-full lg:hidden visible bg-white py-2 px-3'>
                    <div className='container m-auto flex flex-row items-center justify-between'>
                         <img onClick={() => navigate('/')} className='w-[60px] h-[60px]' src={Cinimini} alt='' />
                         <div className='flex flex-row gap-x-5  items-center'>
                              {user === null ? <label id='login' onClick={() => OpenLoginPageInMobile()} className='cursor-pointer text-black'>Login</label> : <label onClick={() => navigate('/Profilepage', { state: { userInfo: userInfo } })} id='login' className='cursor-pointer text-black'>Profile</label>}
                              <div onClick={() => setActive(!active)} >
                                   {active == true ? <FaTimes style={{ color: 'black', width: '17px', height: '17px' }} /> : <FaBars style={{ color: 'black', width: '17px', height: '17px' }} />}
                              </div>
                         </div>
                    </div>
               </div>
               {/* {mobile} */}
               {active === true ?
                    <div className='w-full lg:invisible visible flex flex-col items-center justify-center z-[9999] px-5 bg-red-800'>
                         <div className='container w-[100%] m-auto rounded lg:invisible visible bg-black  flex flex-col absolute px-5 top-20 gap-5 py-5'>
                              <label style={{ color: props.selected === "Home" ? "#24baef" : "" }} onClick={() => OnOption("Home", "", null)} className='cursor-pointer hover:text-[#24baef]'>Home</label>
                              <label style={{ color: props.selected === "News" ? "#24baef" : "" }} onClick={() => OnOption("News", "News", null)} className='cursor-pointer hover:text-[#24baef]'>News</label>
                              <label style={{ color: props.selected === "Celebrity" ? "#24baef" : "" }} onClick={() => OnOption("Celebrity", "Celebrity", null)} className='cursor-pointer hover:text-[#24baef]'>Celebrity</label>
                              <label style={{ color: props.selected === "Movies" ? "#24baef" : "" }} onClick={() => OnOption("Movies", "Movies", null)} className='cursor-pointer hover:text-[#24baef]'>Movies</label>
                              <label style={{ color: props.selected === "Webseries" ? "#24baef" : "" }} onClick={() => OnOption("Webseries", "Webseries", null)} className='cursor-pointer hover:text-[#24baef]'>WebSeries</label>
                              {user === null ? null : <label onClick={() => OnSignOutFunction()} id='login' className='cursor-pointer '>Logout</label>}
                              {/* <label style={{ color: selected === "Video" ? "#24baef" : "" }} onClick={() => OnOption("Video", "Video", null)} className='cursor-pointer hover:text-[#24baef]'>Video</label> */}
                              {/* <label style={{ color: selected === "Events" ? "#24baef" : "" }} onClick={() => OnOption("Events", "Events", null)} className='cursor-pointer     hover:text-[#24baef]'>Events</label>
                              <label style={{ color: selected === "Contact" ? "#24baef" : "" }} onClick={() => OnOption("Contact", "Contact", null)} className='cursor-pointerhover:text-[#24baef]'>Contact</label> */}
                         </div>
                    </div>
                    : null}
               <div className='container m-auto flex flex-col items-end px-5'>
                    {
                         user === null ?
                              ShowLoginPopUp &&
                              <div onMouseLeave={() => setShowLoginPopUp(false)} className='w-[200px] absolute mt-[.5px] z-50 bg-[#2f3942] py-3 rounded-lg px-5'>
                                   <div id='login' className='flex flex-col  gap-y-3  '>
                                        <label id='login' onClick={() => setShowLoginPages(true) & setShowLoginPopUp(false)} className='z-50'>Login</label>
                                        {/* <label id='login' onClick={() => setShowRegisterPage(true) & setShowLoginPopUp(false)} className='z-50'>Register</label> */}
                                   </div>
                              </div> :
                              ShowLoginPopUp &&
                              <div onMouseLeave={() => setShowLoginPopUp(false)} className='w-[200px] absolute mt-[.5px] z-50 bg-[#2f3942] py-3 rounded-lg px-5'>
                                   <div id='login' className='flex flex-col  gap-y-3  '>
                                        <label onClick={() => navigate('/Profilepage', { state: { userInfo: userInfo } })} id='login' className='z-50 '>Profile</label>
                                        <label onClick={() => OnSignOutFunction()} id='login' className='z-50 '>Logout</label>
                                   </div>
                              </div>
                    }
               </div>
          </div>
     )
}

export default Header