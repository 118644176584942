import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'; // Import icons from react-icons library
import contact from '../Assets/contact.jpg'
import Header from './Header';
import { RiInstagramFill } from 'react-icons/ri';
import { FaSquareXTwitter } from 'react-icons/fa6';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
function ContactUsPage() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const [errors, setErrors] = useState([]);

    const validateName = (newName) => {
        const newErrors = [];
        if (!newName) {
            newErrors.push('Name is required.');
        } else if (newName.length < 2) {
            newErrors.push('Name must be at least 2 characters long.');
        } else if (!/^[a-zA-Z -']+$/.test(newName)) {
            newErrors.push('Name can only contain letters, spaces, hyphens, and apostrophes.');
        } else if (newName.length > 50) {
            newErrors.push('Name Should be lesthan 50 Characters');
        }
        setErrors(newErrors);
    };
    const handleChange = (event) => {
        setName(event.target.value);
        validateName(event.target.value);
    }
    const [emailerrors, setEmailErrors] = useState([]);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validateEmail = (newEmail) => {
        const newErrors = [];
        if (!emailRegex.test(newEmail)) {
            newErrors.push('Invalid email format.');
        }
        setEmailErrors(newErrors);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        validateEmail(event.target.value);
    };
    const [messageerrors, setMessageErrors] = useState([]);
    const handleMessageChange = (event) => {
        if (event.target.value.length < 1000) {
            setMessage(event.target.value)
        } else {
            setMessageErrors('Message Should be less than 1000')
        }
    };
    function PostTheMessage() {
        const date=new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()
        try {
            let data = {
                "suggestionBy": name,
                "email": email,
                "suggestion": message,
                "creationDate": date
            }
            if (errors.length == 0 && emailerrors.length == 0 && messageerrors == 0) {
                axios.post('https://cinimini-apis-3nugbllihq-el.a.run.app/suggestions', data)
                    .then((responce) => {
                        if (responce.status == 200) {
                            console.log(responce.data);
                            console.log('Successfully Added');
                            setName('')
                            setEmail('')
                            setMessage('')
                            toast('Thank You For Your Suggestion', { autoClose: true, pauseOnHover: false })
                        }
                    }).catch((error) => {
                        console.log(error.message);
                    })
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    return (
        <>
            <Header props={{ selected: "Contactus" }} />
            <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'white' }} />
            <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
                <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-16">
                        <div className="lg:col-span-1">
                            <h1 className="text-2xl font-semibold text-gray-900 sm:text-3xl dark:text-white">
                                Let's talk
                            </h1>
                            <p className="mt-4 text-gray-500 dark:text-gray-400">
                                To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly.
                            </p>
                            <div className="mt-6">
                                <form action="#" method="POST">
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block mb-1 text-gray-900 dark:text-white">Your Name</label>
                                        <input value={name} onChange={(e) => handleChange(e)} type="text" id="name" name="name" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary_color dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600" required />
                                        <h5 htmlFor="name" className="block  text-red-500 mt-2 mb-3 dark:text-white">{errors}</h5>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block mb-1 text-gray-900 dark:text-white">Email ID</label>
                                        <input value={email} onChange={(e) => handleEmailChange(e)} type="email" id="email" name="email" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary_color dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600" required />
                                        <h5 htmlFor="name" className="block  text-red-500 mt-2 mb-3 dark:text-white">{emailerrors}</h5>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="comment" className="block mb-1 text-gray-900 dark:text-white">Your Message</label>
                                        <textarea value={message} onChange={(e) => handleMessageChange(e)} id="comment" name="comment" rows="5" placeholder='Type something if you want....' className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary_color dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600" required></textarea>
                                        <h5 htmlFor="name" className="block  text-red-500 mt-2 mb-3 dark:text-white">{messageerrors}</h5>
                                    </div>
                                    <button type="submit" className="block w-full py-2 px-4 bg-primary-700 text-white font-semibold rounded-md hover:bg-primary-800 focus:outline-none focus:ring-1 focus:ring-primary_color dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Submit Comment</button>
                                </form>
                            </div>
                            <button onClick={() => PostTheMessage()} className="bg-primary_color/90 w-max hover:bg-primary_color text-white font-bold py-4 px-10 rounded-full focus:outline-none focus:shadow-outline" >
                                Send Message
                            </button>
                        </div>
                        {/* Modified the second div below */}
                        <div className="shrink-0 max-w-md lg:max-w-lg mx-auto xl:mt-0 mt-5 dark:bg-gray-800 p-4 rounded-md flex flex-col ">
                            <div className="flex items-center justify-between mb-6">
                                <img src={contact} alt="Location Image" className="w-full h-auto rounded-lg" />
                            </div>
                            <div className="flex items-center mb-4">
                                <FaMapMarkerAlt className="text-gray-700 dark:text-gray-300 mr-2" />
                                <p className="text-gray-700 dark:text-gray-300">22, Padi Pudu Nagar Main Road, Chennai</p>
                            </div>
                            <div className="flex items-center mb-4">
                                <FaPhone className="text-gray-700 dark:text-gray-300 mr-2" />
                                <p className="text-gray-700 dark:text-gray-300">(+91) 7304704039</p>
                            </div>
                            <div className="flex items-center mb-4">
                                <FaEnvelope className="text-gray-700 dark:text-gray-300 mr-2" />
                                <p className="text-gray-700 dark:text-gray-300">illustionspotline@gmailcom</p>
                            </div>
                            <div className="flex items-center mb-4 mt-5 gap-x-8">
                                <FaFacebook className="text-blue-500 w-8 h-8 hover:text-blue-700 mr-2 cursor-pointer" onClick={() => window.open('https://www.facebook.com', '_blank')} />
                                <RiInstagramFill className="text-pink-500 w-8 h-8 hover:text-pink-700 mr-2 cursor-pointer" onClick={() => window.open('https://www.instagram.com', '_blank')} />
                                <FaSquareXTwitter className="text-black/90 w-8 h-8 hover:text-black cursor-pointer" onClick={() => window.open('https://www.twitter.com', '_blank')} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactUsPage