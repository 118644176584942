import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Searchbar from "../Image/Seacrh-White.png";
import starnotfill from "../Assets/starnotfill.png";
import playcircle from "../Assets/playcircle.png";
import UpArrow from "../Image/Up-White.png";
import Star from "../Assets/Star.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Close from '../Assets/Close.png'
import { GetAdvertisements, GetTheData } from '../Application/Api';
import ReactPlayer from 'react-player';
import Header from './Header.js';
import Footer from './Footer.js';
import axios from 'axios';
import { FaAngleDown, FaSearch, FaTimes } from 'react-icons/fa';
import { FaArrowTurnUp } from "react-icons/fa6";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { scroll } from 'framer-motion';
import BannerComponent from './BannerComponent.js';
import { PiFadersBold } from 'react-icons/pi';
import { motion } from 'framer-motion'
function Movie() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])

    const [HeaderBanner, setHeaderBanner] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const Header = data.filter((e) => e.destination == 4 && e.placement == 1)
            setHeaderBanner(Header)
            const bottombann = data.filter((e) => e.destination == 4 && e.placement == 3)
            setBottomBanner(bottombann)
        }
    }
    const navigate = useNavigate();
    const [movieRating, setMovieRating] = useState([]);
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data)
            } else {
                setUser(data)
            }
        });
        FetchingMovieData()
        FetchingGenreData()
        FetchingCategoryData()
        window.addEventListener("click", (e) => {
            if (e.target.id != "Rating") {
                setShowStarRating(false);
            }
            if (e.target.id != "Video") {
                setShowMovieTrailer(false)
            }
        })
        window.addEventListener('wheel', () => {
            setFilterFocus(false)
        })

    }, [])

    function FetchingSeriesGenre(params) {
        let temp = [...movies]
        let temp1 = []
        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < JSON.parse(temp[i].genreIds).length; j++) {
                if (JSON.parse(temp[i].genreIds)[j] === params) {
                    temp1.push(temp[i])
                }
            }
        }
        console.log(temp1);
        ReArrangeTheArray(temp1)
    }

    const [selectedMovie, setSelectedMovie] = useState(0)
    const [userInfo, setUserInfo] = useState('')
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            setMovieRating(JSON.parse(response.data[0].movieRating));
            setUserInfo(response.data[0])
        } catch (error) {
            console.log(error.message);
        }
    }

    const [movies, setMovies] = useState([])
    async function FetchingMovieData() {
        try {
            const response = await GetTheData('movies')
            FilteringYears(response.data)
            setMovies(response.data)
            setFilterArray(response.data)
            ReArrangeTheArray(response.data)
        } catch (error) {

        }
    }
    const [offsetValue, setOffsetValue] = useState(0)
    async function AgainFetchingMovieData(offset) {
        let temp = [...movies]
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies?limit=10&offset=${offset}`)
            for (let i = 0; i < response.data.length; i++) {
                temp.push(response.data[i])
            }
            FilteringYears(temp)
            setMovies(temp)
            setFilterArray(temp)
            ReArrangeTheArray(temp)
            setOffsetValue(offset)
        } catch (error) {

        }
    }
    const [Years, setYears] = useState([])
    function FilteringYears(movie) {
        let temp = []
        movie.forEach(e => {
            if (temp.findIndex(e1 => e1 == new Date(e.releaseDate).getUTCFullYear()) == -1) {
                temp.push(new Date(e.releaseDate).getUTCFullYear())
            }
        })
        setYears(temp)
    }
    const [FilterArrayDiv, setFilterArrayDiv] = useState([])
    function ReArrangeTheArray(params) {
        let temp = []
        for (let i = 0; i < params.length; i += 10) {
            let temp1 = [...temp]
            let data = params.slice(i, i + 10)
            temp1 = data
            temp.push(temp1)
        }
        setFilterArrayDiv(temp)
    }

    const [movieCategories, setMovieCategories] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieCategories?limit=20&offset=0`)
            setMovieCategories(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieGenres?limit=20&offset=0`)
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingGenreDataByID(params) {
        const value = JSON.parse(params)
        try {
            if (value.length > 1) {
                let temp = ''
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    temp += data1.name.trim()
                    if (i != value.length - 1) {
                        temp += ', '
                    }
                }
                return temp;
            } else {
                const data = GenreCollection.find(e => e.id === value[0])
                return data.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }
    //Filter Movies
    const [FilterArray, setFilterArray] = useState([])
    const [FilteredId, setFilteredId] = useState(0)
    const [selectedMovieForAplha, setSelectedMovieForAplha] = useState([])
    function MovieFilterFunctionById(params) {
        console.log(params);
        let temp = [...movies]
        if (params != 0) {
            let temp1 = []
            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < JSON.parse(temp[i].categoryId).length; j++) {
                    if (JSON.parse(temp[i].categoryId)[j] == params) {
                        temp1.push(temp[i])
                    }
                }
            }
            setShowMobileViewFilter(false)
            ReArrangeTheArray(temp1)
            setFilteredId(params)
            setAlphabit([])
            setFilterFocus(false)
            setSelectedMovieForAplha(temp1)

        }
        else {
            ReArrangeTheArray(temp)
            setFilteredId(0)
            setAlphabit([])
            setFilterFocus(false)
            setSelectedMovieForAplha(temp)
            setShowMobileViewFilter(false)
        }
    }
    const [count, setCount] = useState(0)
    function MovieFilterFunctionByName(params) {
        if (params.length > count) {
            const data = params.trim().toLowerCase()
            const response = FilterArray.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            setAlphabit([])
            setCount(params.length)
            setShowMobileViewFilter(false)
        }
        else if (params.length < count) {
            const data = params.trim().toLowerCase()
            const response = movies.filter(e => e.name.trim().toLowerCase().startsWith(data))
            ReArrangeTheArray(response)
            setAlphabit([])
            setCount(params.length)
            setShowMobileViewFilter(false)
        }
        else {
            MovieFilterFunctionById(FilteredId)
            setShowMobileViewFilter(false)
        }
    }

    const alpha = [
        {
            id: 1,
            name: "A"
        },
        {
            id: 2,
            name: "B"
        },
        {
            id: 3,
            name: "C"
        },
        {
            id: 4,
            name: "D"
        },
        {
            id: 5,
            name: "E"
        },
        {
            id: 6,
            name: "F"
        },
        {
            id: 7,
            name: "G"
        },
        {
            id: 8,
            name: "H"
        },
        {
            id: 9,
            name: "I"
        },
        {
            id: 10,
            name: "J"
        },
        {
            id: 11,
            name: "K"
        },
        {
            id: 12,
            name: "L"
        },
        {
            id: 13,
            name: "M"
        },
        {
            id: 14,
            name: "N"
        },
        {
            id: 15,
            name: "O"
        },
        {
            id: 16,
            name: "P"
        },
        {
            id: 17,
            name: "Q"
        },
        {
            id: 18,
            name: "R"
        },
        {
            id: 19,
            name: "S"
        },
        {
            id: 20,
            name: "T"
        },
        {
            id: 21,
            name: "U"
        },
        {
            id: 22,
            name: "V"
        },
        {
            id: 23,
            name: "W"
        },
        {
            id: 24,
            name: "X"
        },
        {
            id: 25,
            name: "Y"
        },
        {
            id: 26,
            name: "Z"
        }
    ]
    const [alphabit, setAlphabit] = useState([])
    function AlphabiticalFilterFunction(params) {
        let temp = [...alphabit]
        const id = temp.findIndex(e => e === params)
        if (id === -1) {
            temp.push(params)
        } else {
            temp.splice(id, 1);
        }
        if (temp.length > 0) {
            FilterByAlphabitical(temp)
        }
        else {
            MovieFilterFunctionById(FilteredId)
        }
        setAlphabit(temp)
    }
    function FilterByAlphabitical(params) {
        let temp = [...selectedMovieForAplha.length > 0 ? selectedMovieForAplha : movies]
        let tem1 = []
        for (let i = 0; i < params.length; i++) {
            const res = alpha.find(e => e.id == params[i]).name
            const response = temp.filter(e => e.name.trim().toLowerCase().startsWith(res.trim().toLowerCase()))
            for (let j = 0; j < response.length; j++) {
                tem1.push(response[j]);
            }
        }
        setShowMobileViewFilter(false)
        ReArrangeTheArray(tem1);
    }
    const [onFocused, setOnFocused] = useState(false)
    const [showStarRating, setShowStarRating] = useState(false)
    const notify = async (e) => {
        setShowStarRating(false)
        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        ChangeTheRatingFunction(e)
        try {
            let temp = []
            if (userInfo.movieRating === null) {
                temp.push({
                    i: selectedMovie,
                    r: e
                })
            }
            else if (userInfo.movieRating !== null) {
                temp = [...JSON.parse(userInfo.movieRating)]
                const result = temp.findIndex(e => e.i === selectedMovie)
                if (result === -1) {
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber}`, {
                movieRating: JSON.stringify(temp)
            }).then((res) => {
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    };

    async function ChangeTheRatingFunction(rating) {
        let ParticularMovie = movies.find((e => e.id == selectedMovie))
        console.log(ParticularMovie);
        console.log(userInfo.id);
        try {
            let temp = []
            if (ParticularMovie.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (ParticularMovie.rating !== null) {
                temp = [...JSON.parse(ParticularMovie.rating)]
                const result = temp.findIndex(e => e.i === userInfo.id)
                if (result === -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp.splice(result, 1);
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies/${selectedMovie}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdateMovieLocally(selectedMovie, JSON.stringify(temp))
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    function UpdateMovieLocally(_id, _obj) {
        let tempMovies = [...movies]
        tempMovies.find(e => e.id == _id).rating = _obj
        setMovies(tempMovies)
    }
    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]


    const [starCount, setStarCount] = useState(-1)
    const [starCountSelected, setStarCountSelected] = useState(-1);
    const [showMovieTrailer, setShowMovieTrailer] = useState(false)
    const [movieTrailerLink, setMovieTrailerLink] = useState('')
    function MovieTrailerFunction(params) {
        setShowStarRating(false)
        setMovieTrailerLink(params)
        setShowMovieTrailer(true)
    }
    function MovieRatingFunction(e) {
        if (user === null) {
            alert('Login Do First')
        }
        else {
            setSelectedMovie(e.id)
            setStarCountSelected(movieRating !== null ? movieRating.findIndex(e1 => e1.i == e.id) != -1 ? movieRating.find(e1 => e1.i == e.id).r - 1 : -1 : -1)
            setShowStarRating(true)
        }
    }
    const [filterFocus, setFilterFocus] = useState(false)
    const [Selectedcate, setSelectedcate] = useState('Select')

    const scrollposition = useRef(null)
    function ScrollFunction() {
        scrollposition.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const [ShowMobileViewFilter, setShowMobileViewFilter] = useState(false)
    const [SearchContentMobile, setSearchContentMobile] = useState('')

    const [SelectedYear, setSelectedYear] = useState('')
    function FilterBasedOnYear(_Year) {
        let result = movies.filter(e => new Date(e.releaseDate).getUTCFullYear() == _Year)
        ReArrangeTheArray(result)
        setSelectedYear(_Year)
        setShowMobileViewFilter(false)
        if (_Year === 0) {
            ReArrangeTheArray(movies)
            setShowMobileViewFilter(false)
        }
    }
    function ReturnRating(params) {
        if (params != null) {
            let tempValue = 0
            JSON.parse(params)?.forEach(e => tempValue += Number(e.r));
            return (tempValue / JSON.parse(params).length) == 0.0 ? 0 : (tempValue / JSON.parse(params).length).toFixed(1)
        }
        return 0
    }

    return (
        <div className={`w-full bg-[#131722] h-[100vh] flex flex-col overflow-auto`}>
            {
                ShowMobileViewFilter ?
                    <motion.div inherit={{ x: -50 }} animate={{ x: 0 }} className='w-[280px] lg:hidden pb-24 block h-[100vh] z-[99999] pl-5 py-5 overflow-y-auto absolute bg-[#1c212e]'>
                        <FaTimes onClick={() => setShowMobileViewFilter(false)} className='absolute text-white right-0 w-5 m-2 top-1 h-5' />
                        <h3 className='mt-5 text-white text-[22px] font-normal'>Popular Categories </h3>
                        <div className='border-b-[1px] w-[200px] mt-2 border-b-gray-500'></div>
                        <div className='flex flex-col  gap-y-2 z-[40]  my-5 rounded-md hover:bg-transparent hover:cursor-pointer'>
                            <label className='text-[14px] text-[#949cb0]' onClick={() => MovieFilterFunctionById(0) & setSelectedcate('All')}>All</label>
                            {
                                movieCategories.map((e, index) =>
                                    <label className='text-[14px] text-[#949cb0]' key={index} onClick={() => MovieFilterFunctionById(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                )
                            }
                        </div>
                        <div style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                            className='flex bg-[#373c49] px-4 rounded-md flex-row outline-none items-center hover:border-white justify-end w-[245px] gap-5 border-[1px]'>
                            <input type='text' onChange={(e) => setSearchContentMobile(e.target.value)}
                                className="w-full text-white rounded-md outline-none  h-[45px] bg-[#373c49]" placeholder='Search' />
                            <FaSearch onClick={() => MovieFilterFunctionByName(SearchContentMobile)} className='text-[#949cb0]' />
                        </div>
                        <h3 className='mt-5 text-white  text-[22px] font-normal'> Filter by Alphabitical </h3>
                        <div className='border-b-[1px] w-[200px] mt-2 border-b-gray-500'></div>
                        <div className='grid grid-cols-4 w-[200px] gap-x-2 gap-y-2  mt-1 rounded-md '>
                            {
                                alpha.map((e, index) =>
                                    <div style={{ width: '100%', cursor: "pointer", backgroundColor: alphabit.findIndex(e1 => e1 == e.id) != -1 ? "#24baef" : 'transparent' }} key={index}
                                        onClick={() => AlphabiticalFilterFunction(e.id)} className='text-[14px] text-[#949cb0] rounded-md text-center'>{e.name}</div>
                                )
                            }
                        </div>
                        <h3 className='mt-5 text-white  text-[22px] font-normal'>Movies By Year</h3>
                        <div className='border-b-[1px] w-[200px] mt-2 border-b-gray-500'></div>
                        <div className='grid grid-cols-4 w-[200px] gap-x-2 gap-y-2 mt-1 rounded-md '>
                            <div onClick={() => FilterBasedOnYear(0)} style={{ width: '100%', backgroundColor: SelectedYear == '' ? '#24baef' : '' }}
                                className='text-[14px] text-[#949cb0] my-2 rounded-md text-center'>All</div>
                            {
                                Years.map((e, index) =>
                                    <div onClick={() => FilterBasedOnYear(e)} style={{ width: '100%', backgroundColor: SelectedYear == e ? '#24baef' : '' }} key={index}
                                        className='text-[14px] text-[#949cb0] my-2 rounded-md text-center'>{e}</div>
                                )
                            }
                        </div>
                    </motion.div> : null
            }
            <Header props={{ selected: "Movies" }} />
            <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'white' }} />
            {
                showStarRating &&
                <div id='Rating' className='w-full md:w-1/2 lg:w-1/3 absolute self-center z-50 top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                    <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                    <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                    <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                    <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
                    <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                        {
                            stars.map((e, index) =>
                                <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                    className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                            )
                        }
                    </div>
                    <div id='Rating' onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                        className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                        <h4 className=' tracking-wide text-base'>Rate</h4>
                    </div>
                </div>
            }
            {
                showMovieTrailer ?
                    <div id='Video' className='py-5  md:h-[50vh] md:w-[50%] lg:w-[35%] self-center top-[30vh] w-full rounded-md bg-[#1c212e] absolute z-10  flex flex-col items-center '>
                        <div id='Video' className=' flex flex-row justify-between w-full items-center px-5 pb-3'>
                            <h4 className='text-white font-normal'>Trailer</h4>
                            <img onClick={() => setShowMovieTrailer(false)} className='w-[20px]  hover:scale-125 hover:cursor-pointer duration-300 object-contain' src={Close} />
                        </div>
                        <div id='Video' className='md:w-[100%] h-full w-full flex flex-col gap-3 px-5'>
                            <ReactPlayer
                                width="100%"
                                height='100%'
                                url={`https://www.youtube.com/watch?v=${movieTrailerLink}`}
                                playing={true}
                                controls={true}
                            />
                        </div>
                    </div> : null
            }

            <div onMouseLeave={() => setFilterFocus(false)} className={`w-full bg-[#131722] flex flex-col mx-auto`}>
                <div className='w-full lg:block hidden bg-[#1c212e]'>
                    <div onMouseLeave={() => setFilterFocus(false)} className='container flex flex-col mt-2 mx-auto px-3'>
                        <div ref={scrollposition} className='flex flex-col relative'>
                            <div className='w-full h-[50px] flex flex-row gap-1'>
                                <div style={{ borderColor: filterFocus ? "#24baef" : 'gray', }} onClick={() => setFilterFocus(!filterFocus)}
                                    className=' flex flex-row justify-between  px-4 border-[1px] hover:cursor-pointer border-gray-500  text-[#949cb0] rounded-md items-center bg-[#1c212e] w-[20%]'>
                                    <h5 className='font-normal text-left text-sm lg:text-[16px] lg:pl-1'>{Selectedcate}</h5>
                                    <FaAngleDown className='lg:block hidden' />
                                </div>
                                <div onMouseEnter={() => setFilterFocus(false)} style={{ borderColor: onFocused ? "#24baef" : 'gray' }} onBlur={() => setOnFocused(false)} onFocus={() => setOnFocused(true)}
                                    className='flex px-4 bg-[#1c212e] rounded-md flex-row outline-none items-center  justify-end w-[80%] gap-5 border-[1px]'>
                                    <input type='text' onChange={(e) => MovieFilterFunctionByName(e.target.value)}
                                        className="w-full text-white text-sm lg:text-[16px] rounded-md outline-none px-1  h-full bg-[#1c212e]" placeholder='Search for a movie, TV Show or celebrity that you are looking for' />
                                    {/* <img className='w-[20px]  hover:cursor-pointer h-[20px]' src={Searchbar} alt='' /> */}
                                    <FaSearch className='text-[#949cb0]' />
                                </div>
                            </div>
                            {
                                filterFocus &&
                                <div onMouseLeave={() => setFilterFocus(false)} className='lg:w-[200px] xl:w-[250px]  2xl:w-[300px] flex flex-col px-5 gap-y-2 py-3 z-[40] absolute top-[53px] rounded-md bg-[#2f3942] hover:cursor-pointer'>
                                    <label onClick={() => MovieFilterFunctionById(0) & setSelectedcate('All')}>All</label>
                                    {
                                        movieCategories.map((e, index) =>
                                            <label key={index} onClick={() => MovieFilterFunctionById(e.id) & setSelectedcate(e.name)} >{e.name}</label>
                                        )
                                    }
                                </div>
                            }
                            <div onMouseEnter={() => setFilterFocus(false)} className='lg:flex lg:flex-row grid items-center justify-center grid-cols-12  gap-3 gap-y-2 py-2 mt-1 rounded-md bg-[#1c212e] '>
                                {
                                    alpha.map((e, index) =>
                                        <div style={{ width: '3.8%', cursor: "pointer", backgroundColor: alphabit.findIndex(e1 => e1 == e.id) != -1 ? "#24baef" : 'transparent' }} key={index}
                                            onClick={() => AlphabiticalFilterFunction(e.id)} className='text-[16px] rounded-md text-white  hover:text-[#24baef] text-center'>{e.name}</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => setShowMobileViewFilter(true)} className='px-3 lg:hidden w-max z-10 gap-x-1 my-5 flex flex-row items-center '>
                    <PiFadersBold className='rotate-90 w-5 h-5 text-gray-300' />
                    <h5 className='text-white text-base font-normal'>FILTER</h5>
                </div>
                <div onMouseLeave={() => setFilterFocus(false)} className='container bg-[#131722] flex flex-col mx-auto px-3'>
                    {FilterArrayDiv.length > 0 ?
                        <div className='w-full flex flex-col '>
                            {
                                FilterArrayDiv.map((e, index1) =>
                                    <div>
                                        <div key={index1} className='grid lg:grid-cols-4 2xl:grid-cols-5 md:grid-cols-3 grid-cols-2 py-2 gap-x-5 gap-y-3'>
                                            {e.map((item, index) =>
                                                <div className='w-full flex flex-col justify-center items-center'>
                                                    <div key={index} className='flex flex-col  '>
                                                        <div className='flex flex-col justify-end relative rounded-md hover:bg-[#24baef] duration-300'>
                                                            {item.thumbnailPictureUrl !== null ? <img onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), movierating: movieRating } })}
                                                                className='md:w-[290px] w-[200px] min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] lg:h-[400px] hover:opacity-60 hover:cursor-pointer rounded-md object-top object-fill ' src={item.thumbnailPictureUrl == null ? "" : `https://storage.googleapis.com/cinimini-console/movies/${item.id}/${JSON.parse(item.thumbnailPictureUrl)[0]}`} alt='' /> : null}
                                                            <img id="Video" onClick={() => MovieTrailerFunction(item.youtubeLink)} className=' h-[35px] w-[35px] z-[9] hover:cursor-pointer absolute opacity-50 hover:opacity-100 bottom-[45%] left-[45%]' src={playcircle} />
                                                            <div onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), movierating: movieRating } })} className=' absolute bg-gradient-to-b from-transparent from-[75%] to-black  hover:opacity-60 hover:bg-[#24baef] hover:cursor-pointer w-full min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] lg:h-[400px] rounded-md object-top object-fill '></div>
                                                            {
                                                                new Date() < new Date(item.releaseDate) ? null :
                                                                    <div className='flex flex-row absolute md:my-5 my-3 md:pl-2 mx-2 gap-x-2 items-center'>
                                                                        <img className='md:w-[18px] w-[15px] object-contain' src={Star} alt='' />
                                                                        <div className='flex flex-row items-center justify-center gap-x-4'>
                                                                            <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(item.rating)}/10</h6>
                                                                            <div className='flex flex-row items-center justify-center gap-x-1'>
                                                                                <img id='Rating' onClick={() => MovieRatingFunction(item)} className='md:w-[18px] w-[15px] cursor-pointer object-contain' src={movieRating != null ? movieRating.findIndex(e1 => e1.i == item.id) != -1 ? Star : starnotfill : starnotfill} alt='' />
                                                                                <h6 className='text-gray-200 font-normal cursor-default text-sm'>{movieRating != null ? movieRating.findIndex(e1 => e1.i == item.id) != -1 ? movieRating.find(e1 => e1.i == item.id).r + '/10' : null : null}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className='flex  flex-col pt-1'>
                                                            <div className=' flex flex-row items-center justify-between rounded-md'>
                                                                <div className='flex'>
                                                                    {
                                                                        JSON.parse(item.genreIds) != null && JSON.parse(item.genreIds).map((e, index1) => index1 < 1 && GenreCollection.findIndex(e1 => e1.id == e) !== -1 ?
                                                                            <div className='flex flex-row items-center'>
                                                                                <h6 onClick={() => FetchingSeriesGenre(e)} className='text-[14px] hover:cursor-pointer hover:text-[#24baef] text-[#949cb0] font-normal'>{GenreCollection.find(e2 => e2.id == e).name}</h6>
                                                                                {/* <h6 className='self-end pr-1 text-[#949cb0] text-sm'>{index1 < JSON.parse(item.genreIds).length - 1 ? ',' : ''}</h6> */}
                                                                            </div> : null
                                                                        )
                                                                    }
                                                                </div>
                                                                <label onClick={() => FilterBasedOnYear(new Date(item.releaseDate).getUTCFullYear())} className='text-[#949cb0] text-[12px] font-normal tracking-widest'>{new Date(item.releaseDate).getUTCFullYear()}</label>
                                                            </div>
                                                            <label onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), movierating: movieRating } })} className='text-white text-[12px] block md:hidden lg:text-base hover:cursor-pointer'>{item.name.length > 14 ? item.name.substr(0, 14) + '...' : item.name}</label>
                                                            <label onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), movierating: movieRating } })} className='text-white  hidden md:block lg:text-base hover:cursor-pointer'>{item.name}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className=' container h-[250px] my-5'>
                                            {HeaderBanner.length > 0 && <BannerComponent height={200} sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />}
                                        </div>
                                    </div>
                                )
                            }
                        </div> : null}
                    {
                        FilterArrayDiv.length === 0 && <h5 className='text-white mb-40'>Our Peoples Working On</h5>
                    }
                </div>
            </div>
            {/* <div className='flex flex-row items-center my-8 justify-center w-full '>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
                <div onClick={() => AgainFetchingMovieData(offsetValue + 10)} className='w-max whitespace-nowrap hover:bg-primary_color hover:border-primary_color duration-300 cursor-pointer md:px-12 px-5 rounded-md md:py-3 py-2 text-[12px] text-sm text-white border-[2px] border-gray-700'>+ View More</div>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
            </div> */}
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default Movie
