import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Leo1 from "../Assets/Leo1.avif";
import Leo2 from "../Assets/leo2.jpg";
import Leo3 from "../Assets/leo3.jpg";
import Leo4 from "../Assets/leo4.jpg";
import Calender from "../Assets/Calender.png";
import Down from "../Image/Down-White.png";
import Searchbar from "../Image/Seacrh-White.png";
import UpArrow from "../Image/Up-White.png";
import { GetAdvertisements, GetTheData } from '../Application/Api'
import eye from '../Assets/eye.png'
import heart from '../Assets/heart.png'
import heartfill from '../Assets/heartfill.png'
import Header from './Header';
import Footer from './Footer';
import { FaAngleDown, FaAngleRight, FaComment, FaEye, FaHeart, FaRegCommentAlt, FaRegEye, FaRegHeart } from 'react-icons/fa';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BannerComponent from './BannerComponent';
function News() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [HeaderBanner, setHeaderBanner] = useState('')
    const [NewsSquare, setNewsSquare] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const Header = data.filter((e) => e.destination == 2 && e.placement == 1)
            setHeaderBanner(Header)
            const square = data.filter((e) => e.destination == 2 && e.placement == 6)
            setNewsSquare(square)
            const bottombann = data.filter((e) => e.destination == 2 && e.placement == 3)
            setBottomBanner(bottombann)
        }
    }

    const [BottomBannerState, setBottomBannerState] = useState(true)
    const navigate = useNavigate();
    const [tvShow, setTvShow] = useState(false);
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (e.target.id !== "Show") {
                setTvShow(false);
                console.log(e)
            }
        })
    })
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data);
            } else {
                setUser(data)
            }
        });
        FetchingNews(offsetValue)
    }, [])


    const [newsFeed, setNewsFeed] = useState([])
    const [NewsFeedDiv, setNewsFeedDiv] = useState([])
    const [offsetValue, setOffsetValue] = useState(0)
    async function FetchingNews(offset) {
        let tempNews = [...newsFeed];
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed?limit=12&offset=${offset}`)
            for (let i = 0; i < response.data.length; i++) {
                tempNews.push(response.data[i])
            }
            setNewsFeed(tempNews)
            let temp = []
            if (tempNews.length > 0) {
                for (let i = 0; i < tempNews.length; i += 6) {
                    let temp1 = [...temp]
                    let data = tempNews.slice(i, i + 6)
                    temp1 = data
                    temp.push(temp1)
                }
                setNewsFeedDiv(temp)
                setOffsetValue(offset)
            }

        } catch (error) {
            console.log('news error');
        }
    }
    const [NewsFeedLikes, setNewsFeedLikes] = useState(null)
    const [userInfo, setUserInfo] = useState('')
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            setNewsFeedLikes(JSON.parse(response.data[0].newsFeedLikes))
            setUserInfo(response.data[0])
        } catch (error) {
            console.log(error.message);
        }
    }
    async function StoringTheLikes(_id) {
        if (user !== null) {
            try {
                let temp = []
                if (userInfo.newsFeedLikes === null) {
                    temp.push(_id)
                }
                else if (userInfo.newsFeedLikes !== null) {
                    temp = [...NewsFeedLikes]
                    const result = temp.findIndex(e => e === _id)
                    if (result === -1) {
                        temp.push(_id)
                    } else {
                        temp.splice(result, 1)
                    }
                }
                await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/user/' + PNumber, {
                    newsFeedLikes: JSON.stringify(temp)
                }).then((res) => {
                    console.log(res.data);
                    FetchingUserDetailsFromAPi(PNumber)
                    StoringTheLikeCount(temp, _id)
                }).catch((error) => {
                    console.log(error.message);
                })
            } catch (error) {
                console.log(error.message);
            }
        } else {
            alert('Do Login')
        }
    }

    async function StoringTheLikeCount(temp, _id) {
        try {
            const array = [...temp]
            let likes = newsFeed.find(e => e.id == _id).likeCount
            if (array.findIndex(e => e == _id) == -1) {
                likes -= 1
            }
            else {
                likes += 1
            }
            const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed/' + _id, {
                likeCount: likes
            })
            if (response.status == 200) {
                console.log(response.data);
                LocallyUpdatingNews(_id, likes)
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    function LocallyUpdatingNews(_id, Likes) {
        let temp = [...newsFeed]
        temp.find(e => e.id == _id).likeCount =Likes
            setNewsFeed(temp)

    }
    function CommentFunction(item) {
        if (user === null) {
            alert('Login Do')
        } else {
            navigate("/KnowMore", { state: { news: item, newsFeed: newsFeed, comment: 'true' } })
        }

    }

    const returnThePaddedValues = (val) => {
        if (val.toString().length < 2) {
            return val.toString().padStart(2, 0);
        }
        else {
            return val;
        }
    }
    const getThePostTime = (_date) => {
        let msecs = new Date(new Date().getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date().getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date().getUTCDate()) + " " + returnThePaddedValues(new Date().getUTCHours()) + ":" + returnThePaddedValues(new Date().getUTCMinutes()) + ":" + returnThePaddedValues(new Date().getUTCSeconds())).getTime() - new Date(new Date(_date).getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date(_date).getUTCDate()) + " " + returnThePaddedValues(new Date(_date).getUTCHours()) + ":" + returnThePaddedValues(new Date(_date).getUTCMinutes()) + ":" + returnThePaddedValues(new Date(_date).getUTCSeconds())).getTime();
        let secs = msecs / 1000;
        let mins = secs / 60;
        let hours = mins / 60;
        let days = hours / 24;
        let weeks = days / 7;
        let months = weeks / 4;
        let years = months / 12;
        if (secs > 59) {
            if (mins > 59) {
                if (hours > 23) {
                    if (days > 6) {
                        if (weeks > 3) {
                            if (months > 11) {
                                return years == 0 ? 1 + "y" : years.toFixed(0) + "y";
                            }
                            else {
                                return months == 0 ? 1 + "mo" : months.toFixed(0) + "mo";
                            }
                        }
                        else {
                            return weeks == 0 ? 1 + "w" : weeks.toFixed(0) + "w";
                        }
                    }
                    else {
                        return days == 0 ? 1 + "d" : days.toFixed(0) + "d";
                    }
                }
                else {
                    return hours == 0 ? 1 + "h" : hours.toFixed(0) + "h";
                }
            }
            else {
                return mins == 0 ? 1 + "m" : mins.toFixed(0) + "m";
            }
        }
        else {
            return secs == 0 ? 1 + "s" : secs.toFixed(0) + "s";
        }
    }
    const returnTheReleaseDate = (_date) => {
        let year = new Date(_date).getUTCFullYear();
        let month = (new Date(_date).getUTCMonth()+1).toString.length<2? 
        (new Date(_date).getUTCMonth()+1).toString().padStart(2, 0)
        : (new Date(_date).getUTCMonth()+1).toString();
        let date =
            new Date(_date).getUTCDate().toString().length < 2
                ? new Date(_date).getUTCDate().toString().padStart(2, 0)
                : new Date(_date).getUTCDate().toString();
        let final = date + "-" + month + "-" + year;
        return final;
    };
    return (
        <div className='w-full  bg-[#131722] '>
            <Header props={{ selected: "News" }} />
            <div className='container m-auto flex flex-col px-3  '>
                <div className='flex lg:flex-row w-full flex-col justify-between lg:gap-x-3 py-3'>
                    <div className='lg:w-[63%] xl:w-[75%]'>
                        {
                            NewsFeedDiv.map((e, index1) =>
                                <div key={index1}>
                                    <div className='grid md:grid-cols-2 xl:grid-cols-3 text-justify gap-3 grid-cols-1'>
                                        {e.map((item, index) =>
                                            <div key={index} className='flex flex-col gap-y-2 bg-[#1c212e] rounded-md p-2 justify-between '>
                                                <div className=' hover:bg-[#24baef] duration-300 rounded-md'>
                                                    <img onClick={() => navigate("/KnowMore", { state: { news: item, newsFeed: newsFeed } })} className='w-full hover:opacity-60 hover:cursor-pointer rounded object-cover object-top gap-6 h-[300px]' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + item.id + '/' + item.thumbnailUrl} alt='' />
                                                </div>
                                                <div className='flex flex-row justify-between items-center mt-1'>
                                                    <div className='flex flex-row gap-2 '>
                                                        <h5 className='text-[#24baef] hover:cursor-default text-[12px]'>NEWS & UPDATES </h5>
                                                        <h5 className='text-[#949cb0] hover:cursor-default  text-[12px]'>{returnTheReleaseDate(item.dateOfPublish)}</h5>
                                                        {/* <h5 className='text-[#949cb0] hover:cursor-default  text-[12px]'>{getThePostTime(item.dateOfPublish)} Ago</h5> */}
                                                    </div>
                                                </div>
                                                <div className='flex flex-row items-center gap-x-2'>
                                                    <label className='border-r-[4px] border-[#24baef]  h-[60px]'></label>
                                                    <label onClick={() => navigate("/KnowMore", { state: { news: item, newsFeed: newsFeed } })} className='text-left  hover:text-[#24baef] text-base font-normal  text-white'>{item.name}</label>
                                                </div>
                                                <p className='text-sm hover:cursor-default'>{item.description.length > 90 ? item.description.substr(0, 90) + '...' : item.description}</p>
                                                <div className='flex flex-row items-center justify-between '>
                                                    <div className='flex flex-row items-center gap-x-3'>
                                                        <div className='flex flex-row  p-1 rounded-md items-center gap-x-1'>
                                                            <FaRegEye className='text-white' />
                                                            <h5 className='text-[#24baef] hover:cursor-default font-normal'>{item.viewCount}</h5>
                                                        </div>
                                                        <div className='flex flex-row items-center p-1 rounded-md gap-x-1'>
                                                            {NewsFeedLikes !== null ?
                                                                (NewsFeedLikes.findIndex(e1 => e1 == item.id) == -1 ?
                                                                    (
                                                                        <FaRegHeart onClick={() => StoringTheLikes(item.id)} className=' hover:cursor-pointer text-white' />
                                                                    ) : (
                                                                        <FaHeart onClick={() => StoringTheLikes(item.id)}
                                                                            className=' text-red-600 hover:cursor-pointer' />))
                                                                : <FaRegHeart onClick={() => StoringTheLikes(item.id)} className='hover:cursor-pointer text-white' />}
                                                            <h5 className='text-[#24baef] hover:cursor-default font-normal'>{item.likeCount}</h5>
                                                        </div>
                                                        <div className='flex flex-row p-1  rounded-md items-center gap-x-1'>
                                                            <FaRegCommentAlt className='text-white hover:cursor-pointer' onClick={() => CommentFunction(item)} />
                                                            <h5 className='text-[#24baef] hover:cursor-default font-normal'>{item.commentsCount == null ? 0 : item.commentsCount}</h5>
                                                        </div>
                                                    </div>
                                                    <label className=' w-max hover:translate-x-3 text-sm text-[#949cb0] hover:text-[#24baef] font-medium flex flex-row items-center justify-center gap-x-1  cursor-pointer' onClick={() => navigate("/KnowMore", { state: { news: item, newsFeed: newsFeed } })}>More <FaAngleRight /></label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='container  my-5'>
                                        {HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='xl:w-[30%] lg:w-[40%] '>
                        <div className=' bg-[#1c212e] lg::mt-0 mt-2 h-max  pb-5 flex flex-col px-5 rounded-md'>
                            <div className='flex flex-row gap-2 w-full my-5'>
                                <div className=' border-l-4  border-l-[#24baef]'></div>
                                <h3 className='font-semibold hover:cursor-default text-white'>RECENT NEWS</h3>
                            </div>
                            <div className='flex flex-col rounded-md  h-auto custom-scrollbar overflow-auto gap-y-3'>
                                {
                                    newsFeed.map((e, index) => index < 5 ?
                                        <div className='flex flex-col gap-y-5 pb-2 border-b-[1px] border-b-white'>
                                            <div className='lg:flex-row flex-col flex items-center gap-5 '>
                                                <div className='lg:w-[30%] w-full hover:bg-[#24baef] duration-300 rounded-md'>
                                                    <img onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='w-[110px] h-[81px] hover:opacity-60 hover:cursor-pointer rounded-md object-top object-cover ' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + e.id + '/' + e.thumbnailUrl} />
                                                </div>
                                                <div className='lg:w-[70%] w-full'>
                                                    <label onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='text-white text-[14px] hover:text-[#24baef] font-normal'>{e.name}</label>
                                                    <div className='flex flex-row justify-between items-center'>
                                                        <h5 className=' text-[#949cb0] hover:cursor-default text-[12px]'>{returnTheReleaseDate(e.dateOfPublish)}</h5>
                                                        <div className='flex flex-row items-center gap-x-2'>
                                                            <div className='flex flex-row  p-1 rounded-md items-center gap-x-1'>
                                                                <FaEye className='text-white' />
                                                                <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.viewCount}</h5>
                                                            </div>
                                                            <div className='flex flex-row items-center  p-1 rounded-md gap-x-1'>
                                                                {NewsFeedLikes !== null ?
                                                                    (NewsFeedLikes.findIndex(e1 => e1 == e.id) == -1 ?
                                                                        (
                                                                            <FaRegHeart onClick={() => StoringTheLikes(e.id)} className=' hover:cursor-pointer text-white ' />
                                                                        ) : (
                                                                            <FaHeart onClick={() => StoringTheLikes(e.id)}
                                                                                className=' text-red-600 hover:cursor-pointer ' />))
                                                                    : <FaRegHeart onClick={() => StoringTheLikes(e.id)} className='hover:cursor-pointer text-white' />}
                                                                <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.likeCount}</h5>
                                                            </div>
                                                            <div className='flex flex-row p-1   rounded-md items-center gap-x-1'>
                                                                <FaRegCommentAlt onClick={() => CommentFunction(e)} className='text-white cursor-pointer' />
                                                                <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.commentsCount == null ? 0 : e.commentsCount}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                    )
                                }
                            </div>
                        </div>
                        <div className='w-[400px] relative lg:block hidden mt-5'>
                            <div className='sticky top-0 w-full '>
                                {NewsSquare.length > 0 && <BannerComponent sectionId={NewsSquare[0].sectionId} internal={NewsSquare[0].internal} redirectUrl={NewsSquare[0].redirectUrl} id={NewsSquare[0].id} ImageUrl={NewsSquare[0].bannerUrl} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-row items-center my-8 justify-center w-full '>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
                <div onClick={() => FetchingNews(offsetValue + 12)} className='w-max whitespace-nowrap hover:bg-primary_color hover:border-primary_color duration-300 cursor-pointer md:px-12 px-5 rounded-md md:py-3 py-2 text-[12px] text-sm text-white border-[2px] border-gray-700'>+ View More</div>
                <div className='w-full border-b-[1px] border-b-gray-700'></div>
            </div>
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default News
