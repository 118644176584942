import axios from "axios";

export async function GetTheData(params) {
    try {
        const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/${params}?limit=300&offset=0`)
        if (response.status == 200) {
            return response;
        }
    } catch (error) {
        console.log(error.message);
    }

}

export async function GetAdvertisements() {
    try {
        const response =await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/Advertisements/cinimini`)
        if (response.status == 200) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
    }
}