import React,{useEffect, useState} from 'react'
import Down from "../Image/Down-White.png" 
import { useNavigate } from 'react-router-dom';
import Searchbar from "../Image/Seacrh-White.png";
import Leo1 from "../Assets/Leo1.avif";
import Leo2 from "../Assets/leo2.jpg";
import Leo3 from "../Assets/leo3.jpg";
import Leo4 from "../Assets/leo4.jpg";
import Calender from "../Assets/Calender.png";
import UpArrow from "../Image/Up-White.png";
import Header from './Header';


function Contact() {
    const navigate=useNavigate();
    const[tvShow,setTvShow]=useState(false);
    
    useEffect(()=>{
        window.addEventListener("click",(e)=>{
            if(e.target.id!=="Show"){
                setTvShow(false);
                console.log(e)
            }
        })
    })

    let movies=[
        { 
            image:Leo1,
            h1:"LEO - Glimpse of Antony Das | Thalapathy Vijay | Lokesh Kanagaraj | Anirudh Ravichander",
            description:"Leo is an upcoming Indian Tamil-language action thriller film directed by Lokesh Kanagaraj, who co-wrote it with Rathna Kumar and Deeraj Vaidy. It is the third film in the Lokesh Cinematic Universe, and is produced by S. S. Lalit Kumar of Seven Screen Studio while Jagadish Palanisamy serves as co-producer",
            image2:Calender,
        },
        { 
            image:Leo2,
            h1:"LEO - Glimpse of Antony Das | Thalapathy Vijay | Lokesh Kanagaraj | Anirudh Ravichander",
            description:"Leo is an upcoming Indian Tamil-language action thriller film directed by Lokesh Kanagaraj, who co-wrote it with Rathna Kumar and Deeraj Vaidy. It is the third film in the Lokesh Cinematic Universe, and is produced by S. S. Lalit Kumar of Seven Screen Studio while Jagadish Palanisamy serves as co-producer",
            image2:Calender,
        },
        { 
            image:Leo3,
            h1:"LEO - Glimpse of Antony Das | Thalapathy Vijay | Lokesh Kanagaraj | Anirudh Ravichander",
            description:"Leo is an upcoming Indian Tamil-language action thriller film directed by Lokesh Kanagaraj, who co-wrote it with Rathna Kumar and Deeraj Vaidy. It is the third film in the Lokesh Cinematic Universe, and is produced by S. S. Lalit Kumar of Seven Screen Studio while Jagadish Palanisamy serves as co-producer",
            image2:Calender,
        },
        { 
            image:Leo4,
            h1:"LEO - Glimpse of Antony Das | Thalapathy Vijay | Lokesh Kanagaraj | Anirudh Ravichander",
            description:"Leo is an upcoming Indian Tamil-language action thriller film directed by Lokesh Kanagaraj, who co-wrote it with Rathna Kumar and Deeraj Vaidy. It is the third film in the Lokesh Cinematic Universe, and is produced by S. S. Lalit Kumar of Seven Screen Studio while Jagadish Palanisamy serves as co-producer",
            image2:Calender,
        },
    ]

    return (
        <div className='w-full flex flex-col '>
            <Header  props={{selected:"Contact"}}/>
            <div className='container m-auto flex flex-col mt-2 px-3'>
            <div className='w-full h-[50px] bg-[#233A50] flex flex-row rounded-[4px] gap-5'>
                    <div className='flex flex-row items-center  justify-between w-[20%]'>
                        <div className='flex flex-row justify-between '>
                            <label className='px-3'>TV SHOW</label>
                            {tvShow===true?
                                <div className='flex flex-col lg:w-[16%] w-[20%] rounded absolute bg-[#233A50] py-4 px-4 lg:mt-10 mt-12 gap-3 z-40'>
                                    <label>TV SHOW</label>
                                    <label>TV SHOW</label>
                                    <label>TV SHOW</label>
                                </div>
                            :null}
                        </div>
                        <img id='Show' className='Icon' onClick={()=>setTvShow(!tvShow)} src={tvShow===true?UpArrow:Down} alt=''/>
                    </div>
                    <div className='flex flex-row items-center px-3 justify-between w-[80%] gap-5'>
                        <input type='text' className="w-full outline-none border-[2px] rounded-[4px] p-2 bg-[#233A50]" placeholder='Search for a movie, TV Show or celebrity that you are looking for'/>
                        <img className='Icons' src={Searchbar} alt=''/>
                    </div>
                </div>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 text-justify gap-3 grid-cols-1 py-3'>
                {movies.map((item,index)=>
                <div key={index} className='flex flex-col gap-3'>
                    <img className='w-full rounded-md object-cover gap-6 h-[300px]' src={item.image} alt=''/>
                    <div className='flex flex-row items-center gap-3'>
                        <img className='w-12 h-12 object-contain' src={item.image2} alt=''/>
                        <label className='border-r-[3px] border-[#EE910A]  h-[60px]'></label>
                        <h5 className='text-justify'>{item.h1}</h5>
                    </div>
                    <p>{item.description}</p>
                    <label className='text-right text-[#EE910A] cursor-pointer' onClick={()=>navigate("/KnowMore")}>Know More ➔</label>
                </div>
                
                )} 
            </div>
        </div>
        </div>
    )
}

export default Contact
