import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Star from "../Assets/Star.png";
import netflix from '../Assets/netflix1.png'
import Header from './Header';
import Close from '../Assets/Close.png'
import Footer from './Footer';
import { GetAdvertisements, GetTheData } from '../Application/Api';
import Carousel from 'react-multi-carousel';
import starnotfill from "../Assets/starnotfill.png";
import playcircle from "../Assets/playcircle.png";
import 'react-multi-carousel/lib/styles.css';
import RadioButtonOn from '../Image/RadioButtonOn.png';
import RadioButtonOff from '../Image/RadioButtonOff.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import romeoBanner from '../Assets/romeoBanner.jpg'
import axios from 'axios';
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaComment, FaEye, FaHeart, FaRegCommentAlt, FaRegHeart } from 'react-icons/fa';
import { auth } from './FirebaseInit';
import n1 from '../Assets/n1.jpg'
import { motion } from 'framer-motion'
import n2 from '../Assets/n2.jpg'
import n3 from '../Assets/n3.jpg'
import n4 from '../Assets/n4.jpg'
import n5 from '../Assets/n5.jpg'
import n6 from '../Assets/n6.jpg'
import n7 from '../Assets/n7.jpg'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import FullWidthConatiner from './FullWidthConatiner';

import OpenYoutubeBox from './OpenYoutubeBox';
import OpenStarRatingBox from './OpenStarRatingBox';
import BannerSliderComponent from './BannerSliderComponent';

import FullWidthBanner from './FullWidthBanner';

import BannerComponent from './BannerComponent';
function Home() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])

    const SelectedMovieRef = useRef(0)
    const [FullBannerData, setFullBannerData] = useState('')
    const [HeaderBanner, setHeaderBanner] = useState('')
    const [SquareBanner, setSquareBanner] = useState('')
    const [leftBanner, setLeftBanner] = useState('')
    const [RightBanner, setRightBanner] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    const [FullWidthBannerArray, setFullWidthBannerArray] = useState([])
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const data1 = data.filter((e) => e.destination == 1 && e.placement == 5)
            setFullBannerData(data1)
            const Header = data.filter((e) => e.destination == 1 && e.placement == 1)
            setHeaderBanner(Header)
            const square = data.filter((e) => e.destination == 1 && e.placement == 6)
            setSquareBanner(square)
            const leftBann = data.filter((e) => e.destination == 1 && e.placement == 4)
            setLeftBanner(leftBann)
            const rightbann = data.filter((e) => e.destination == 1 && e.placement == 2)
            setRightBanner(rightbann)
            const bottombann = data.filter((e) => e.destination == 1 && e.placement == 3)
            setBottomBanner(bottombann)
            const wideBanner = data.filter((e) => e.destination == 1 && e.placement == 7)
            console.log(wideBanner);
            setFullWidthBannerArray(wideBanner);
        }
    }

    const CustomDot = ({
        index,
        active,
        onClick
    }) => {
        return (
            <div className='flex flex-col justify-center mx-[.15%] hover:cursor-pointer items-center' onClick={e => {
                onClick();
                e.preventDefault();
            }}>
                <img style={{ width: "8px", height: "8px", objectFit: "contain" }} src={active == 1 ? RadioButtonOn : RadioButtonOff} />
            </div>
        );
    };
    const navigate = useNavigate();
    const [movieRating, setMovieRating] = useState([]);
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data)
            } else {
                setUser(data)
            }
        });
        FetchingGenreData()
        FetchingWebseries()
        FetchingCategoryData()
        FetchingNews()
        FetchingCelebrities()
        FetchingBannerData()
        FetchingFullWidthBannerData()

        window.addEventListener("click", (e) => {
            if (e.target.id != "Video") {
                setShowMovieTrailer(false);
                // setbannerYTLink(false)
            }
        })

        window.addEventListener("click", (e) => {
            console.log(e);
            if (e.target.id != "Rating") {
                setShowStarRating(false);
                setSeriesStarRating(false)
            }
        })
    }, [])



    const [moviesData, setMoviesData] = useState([])
    async function FetchingFullWidthBannerData() {
        const response = await GetTheData('movies')
        setMoviesData(response.data)

    }

    const [Top5ArrayData, setTop5ArrayData] = useState([])
    const [Top5Selected, setTop5Selected] = useState('ThisMonth')
    function Top5Movies(params, _type) {
        switch (_type) {
            case 'ThisMonth':
                let res = params.filter(e => e.displayTag === 'Upcoming')
                setTop5Selected('ThisMonth')
                setTop5ArrayData(res)
                break;

            case '3Months':
                let res1 = params.filter(e => e.displayTag === 'Trending')
                setTop5Selected('3Months')
                setTop5ArrayData(res1)
                break;

            case '6Months':
                let res2 = params.filter(e => e.displayTag === 'In theater')
                setTop5Selected('6Months')
                setTop5ArrayData(res2)
                break;
            default:
                break;
        }
    }

    const [Webseries, setWebseries] = useState([])
    async function FetchingWebseries() {
        const response = await GetTheData('webSeries')
        setWebseries(response.data)
        Top5WebSeriesFunction(response.data, 'ThisWeek')
    }
    const [userInfo, setUserInfo] = useState('')
    const [NewsFeedLikes, setNewsFeedLikes] = useState([])
    const [webseriesRating, setwebseriesRating] = useState([]);
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            setMovieRating(JSON.parse(response.data[0].movieRating));
            setwebseriesRating(JSON.parse(response.data[0].webseriesRating));
            setUserInfo(response.data[0])
            console.log(response);
            setNewsFeedLikes(JSON.parse(response.data[0].newsFeedLikes))
        } catch (error) {
            console.log(error.message);
        }
    }

    const [celebritiesData, setCelebritiesData] = useState([])
    async function FetchingCelebrities() {
        try {
            const response = await GetTheData('celebrities')
            CelebritiesFilterByDate(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    const month = (Number(new Date().getUTCMonth()) + 1).toString().length < 2
        ? (Number(new Date().getUTCMonth()) + 1).toString().padStart(2, 0)
        : (Number(new Date().getUTCMonth()) + 1).toString();
    const date = (Number(new Date().getDate())).toString().length < 2
        ? (Number(new Date().getDate())).toString().padStart(2, 0)
        : (Number(new Date().getDate())).toString()

    function CelebritiesFilterByDate(params) {
        let temp = []
        for (let i = 0; i < params.length; i++) {
            if (date == ((Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCDate())).toString().length < 2 ?
                (Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCDate())).toString().padStart(2, 0)
                : (Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCDate())).toString())
            ) {
                if (month == ((Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCMonth()) + 1).toString().length < 2
                    ? (Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCMonth()) + 1).toString().padStart(2, 0)
                    : (Number(new Date(JSON.parse(params[i].personalInformation).born).getUTCMonth()) + 1).toString())) {
                    temp.push(params[i]);
                }
            }
        }
        setCelebritiesData(temp)
    }

    function FetchingGenreDataByID(params) {
        const value = params != undefined ? JSON.parse(params) : []
        // console.log(value);
        try {
            if (value.length > 1) {
                let temp = ''
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    temp += data1?.name.trim()
                    if (i != value.length - 1) {
                        temp += ', '
                    }
                }
                return temp;
            } else if (value.length > 0) {
                const data = GenreCollection.find(e => e.id === Number(value[0]))
                return data?.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }
    const [newsFeed, setNewsFeed] = useState([])
    async function FetchingNews() {
        try {
            const response = await GetTheData('newsFeed')
            setNewsFeed(response.data);
        } catch (error) {
            console.log('news error');
        }
    }
    const [bannersData, setBannersData] = useState([])
    async function FetchingBannerData() {
        const response = await GetTheData('movieBanners')
        setBannersData(response.data)
        Top5Movies(response.data, '3Months')
    }

    const [movieCategories, setMovieCategories] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('movieCategories')
            setMovieCategories(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await GetTheData('movieGenres')
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 3560, min: 1435 },
            items: 6
        },
        LargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 1440, min: 1020 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1024, min: 769 },
            items: 4
        },
        tab: {
            breakpoint: { max: 1024, min: 769 },
            items: 3
        },

        mini: {
            breakpoint: { max: 768, min: 596 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 595, min: 0 },
            items: 1
        }
    };
    const responsive1 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 2560, min: 1440 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1439, min: 901 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 900, min: 400 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 645, min: 0 },
            items: 1
        }
    };
    const responsiveCelebrity = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 2560, min: 1440 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1439, min: 901 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 900, min: 400 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 645, min: 0 },
            items: 1
        }
    };
    const [showStarRating, setShowStarRating] = useState(false)
    const displayTagColor = ['#fc7362', '#74fc68', '#fcbe68', '#69fafa']
    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]
    const [starCount, setStarCount] = useState(-1)

    const notify = async (e) => {
        setShowStarRating(false)
        if (Top5swap) {
            ChangeTheTop5RatingFunction(e)
        } else {
            ChangeTheRatingFunction(e)
        }

        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        try {
            let temp = []
            if (userInfo.movieRating === null) {
                temp.push({
                    i: selectedMovie,
                    r: e
                })
            }
            else if (userInfo.movieRating !== null) {
                temp = [...JSON.parse(userInfo.movieRating)]
                const result = temp.findIndex(e => e.i === selectedMovie)
                if (result === -1) {
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                }
            }
            console.log(temp);
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber}`, {
                movieRating: JSON.stringify(temp)
            }).then((res) => {
                console.log(res.data);
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    };
    const [selectedSeries, setselectedSeries] = useState(0)
    const Seriesnotify = async (e) => {
        setSeriesStarRating(false)
        ChangeTheWebRatingFunction(e)
        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        try {
            let temp = []
            if (userInfo.webseriesRating === null) {
                temp.push({
                    i: selectedSeries,
                    r: e
                })
            }
            else if (userInfo.webseriesRating !== null) {
                temp = [...JSON.parse(userInfo.webseriesRating)]
                const result = temp.findIndex(e => e.i === selectedSeries)
                if (result === -1) {
                    temp.push({
                        i: selectedSeries,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedSeries,
                        r: e
                    })
                }
            }
            console.log(temp);
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber}`, {
                webseriesRating: JSON.stringify(temp)
            }).then((res) => {
                console.log(res.data);
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    };
    const [showMovieTrailer, setShowMovieTrailer] = useState(false)
    const [movieTrailerLink, setMovieTrailerLink] = useState('')
    function MovieTrailerFunction(params) {
        setShowStarRating(false)
        setMovieTrailerLink(params)
        setShowMovieTrailer(true)
    }
    const [selectedMovie, setSelectedMovie] = useState(0)
    const [starCountSelected, setStarCountSelected] = useState(-1);
    const [Top5swap, setTop5swap] = useState(false)
    function MovieRatingFunction(e, _data) {
        console.log(_data);
        if (user === null) {
            alert('Login Do First')
        }
        else {
            if (_data !== undefined) {
                setTop5swap(true)
            }
            setSelectedMovie(e.id)
            SelectedMovieRef.current = e.id
            setStarCountSelected(movieRating != null ? movieRating.findIndex(e1 => e1.i == e.id) != -1 ? movieRating.find(e1 => e1.i == e.id).r - 1 : -1 : -1)
            setShowStarRating(true)
        }

    }
    async function StoringTheLikes(_id) {
        if (user === null) {
            alert('Login Do First')
        } else {
            try {
                let temp = []
                if (userInfo.newsFeedLikes === null) {
                    temp.push(_id)
                }
                else if (userInfo.newsFeedLikes !== null) {
                    temp = [...NewsFeedLikes]
                    const result = temp.findIndex(e => e === _id)
                    if (result === -1) {
                        temp.push(_id)
                    } else {
                        temp.splice(result, 1)
                    }
                }
                await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${PNumber !== '' && PNumber}`, {
                    newsFeedLikes: JSON.stringify(temp)
                }).then((res) => {
                    console.log(res.data);
                    FetchingUserDetailsFromAPi(PNumber)
                    StoringTheLikeCount(temp, _id)
                }).catch((error) => {
                    console.log(error.message);
                })
            } catch (error) {
                console.log(error.message);
            }
        }
    }
    async function StoringTheLikeCount(temp, _id) {
        const array = [...temp]
        let likes = newsFeed.find(e => e.id == _id).likeCount
        if (array.findIndex(e => e == _id) == -1) {
            likes -= 1
        }
        else {
            likes += 1
        }
        try {
            const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed/' + _id, {
                likeCount: likes
            })
            if (response.status == 200) {
                console.log(response.data);
                LocallyUpdatingNews(_id, likes)
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    function LocallyUpdatingNews(_id, Likes) {
        let temp = [...newsFeed]
        temp.find(e => e.id == _id).likeCount = Likes
        setNewsFeed(temp)

    }
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="absolute top-[35%] flex flex-row  items-center justify-between w-full bg-red-800">
                <FaAngleLeft className={` text-white bg-transparent/90 hover:cursor-pointer   rounded-full w-[30px] h-[30px]`} onClick={() => previous()} />
                <FaAngleRight className={`text-white bg-transparent/90  hover:cursor-pointer rounded-full w-[30px] h-[30px]`} onClick={() => next()} />
                {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
            </div>
        );
    };
    function CommentFunction(item) {
        if (user === null) {
            alert('Login Do')
        } else {
            navigate("/KnowMore", { state: { news: item, newsFeed: newsFeed, comment: 'true' } })
        }

    }
    const [SeriesStarRating, setSeriesStarRating] = useState(false)
    function SeriesRatingFunction(e) {
        if (user === null) {
            alert('Login Do First')
        }
        else {
            setselectedSeries(e.id)
            setStarCountSelected(webseriesRating !== null ? webseriesRating.findIndex(e1 => e1.i == e.id) != -1 ? webseriesRating.find(e1 => e1.i == e.id).r - 1 : -1 : -1)
            setSeriesStarRating(true)
        }
    }

    const [bannerYTlink, setbannerYTLink] = useState(false)
    const [YtLink, setYtLink] = useState('')
    function handleYtFunction(params) {
        setYtLink(params)
        setbannerYTLink(true)
    }
    function handleYtFalseFunction() {
        setbannerYTLink(false)
    }
    const [ShowBannerStarRating, setShowBannerStarRating] = useState(false)
    const [CurrentRating, setCurrentRating] = useState(-1)
    function HandleStarRatingFunction(params) {
        setCurrentRating(params)
        setShowBannerStarRating(true)
    }
    function HandleStarRatingFunctionForClose() {
        setShowBannerStarRating(false)
    }
    const array = [
        {
            id: 1,
            name: 'The Limit',
            img: n1,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix',
        },
        {
            id: 2,
            name: 'Winnie',
            img: n2,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 3,
            name: 'The Growth',
            img: n3,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 4,
            name: 'Swank',
            img: n4,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        },
        {
            id: 5,
            name: 'Breaking Bat',
            img: n5,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        }, {
            id: 6,
            name: 'MindSet',
            img: n6,
            genre: 'Adventure drama history',
            year: '2024',
            episodes: '10',
            ytLink: 'hihi',
            ottimage: netflix,
            ottName: 'Netflix'
        }
    ]

    const [ShowWebseriesBox, setShowWebseriesBox] = useState(false)
    function WebseriesTurnOn(params) {
        setYtLink(params)
        setShowWebseriesBox(true)
    }
    function WebseriesTurnOff() {
        setShowWebseriesBox(false)
    }

    function BannerNavigation(params) {
        navigate("/AboutMovie", { state: { data: params, test: FetchingGenreDataByID(params.genreIds) !== undefined && FetchingGenreDataByID(params.genreIds), movierating: movieRating } })
    }
    const [showFullwidthBanner, setShowFullwidthBanner] = useState(true)
    function Closebanner() {
        setShowFullwidthBanner(false)
        setBottomBannerState(true)
    }
    const [BottomBannerState, setBottomBannerState] = useState(false)
    const returnThePaddedValues = (val) => {
        if (val.toString().length < 2) {
            return val.toString().padStart(2, 0);
        }
        else {
            return val;
        }
    }
    const getThePostTime = (_date) => {
        let msecs = new Date(new Date().getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date().getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date().getUTCDate()) + " " + returnThePaddedValues(new Date().getUTCHours()) + ":" + returnThePaddedValues(new Date().getUTCMinutes()) + ":" + returnThePaddedValues(new Date().getUTCSeconds())).getTime() - new Date(new Date(_date).getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date(_date).getUTCDate()) + " " + returnThePaddedValues(new Date(_date).getUTCHours()) + ":" + returnThePaddedValues(new Date(_date).getUTCMinutes()) + ":" + returnThePaddedValues(new Date(_date).getUTCSeconds())).getTime();
        let secs = msecs / 1000;
        let mins = secs / 60;
        let hours = mins / 60;
        let days = hours / 24;
        let weeks = days / 7;
        let months = weeks / 4;
        let years = months / 12;
        if (secs > 59) {
            if (mins > 59) {
                if (hours > 23) {
                    if (days > 6) {
                        if (weeks > 3) {
                            if (months > 11) {
                                return years == 0 ? 1 + "y" : years.toFixed(0) + "y";
                            }
                            else {
                                return months == 0 ? 1 + "mo" : months.toFixed(0) + "mo";
                            }
                        }
                        else {
                            return weeks == 0 ? 1 + "w" : weeks.toFixed(0) + "w";
                        }
                    }
                    else {
                        return days == 0 ? 1 + "d" : days.toFixed(0) + "d";
                    }
                }
                else {
                    return hours == 0 ? 1 + "h" : hours.toFixed(0) + "h";
                }
            }
            else {
                return mins == 0 ? 1 + "m" : mins.toFixed(0) + "m";
            }
        }
        else {
            return secs == 0 ? 1 + "s" : secs.toFixed(0) + "s";
        }
    }
    const returnTheReleaseDate = (_date) => {
        let year = new Date(_date).getUTCFullYear();
        let month = new Date(_date).toLocaleDateString("en-US", { month: 'short' })
        let date =
            new Date(_date).getUTCDate().toString().length < 2
                ? new Date(_date).getUTCDate().toString().padStart(2, 0)
                : new Date(_date).getUTCDate().toString();
        let final = date + "-" + month + "-" + year;
        return final;
    };

    const [Top5Webseries, setTop5Webseries] = useState([])
    const [Top5SelectedWebseries, setTop5SelectedWebseries] = useState('ThisWeek')
    function Top5WebSeriesFunction(params, _type) {
        let temp = []
        switch (_type) {
            case 'ThisWeek':
                for (let i = 0; i < params.length; i++) {
                    if (new Date(params[i].releaseDate).getUTCMonth() === new Date().getUTCMonth()) {
                        if (new Date(params[i].releaseDate).getUTCFullYear() === new Date().getUTCFullYear()) {
                            temp.push(params[i])
                        }
                    }
                }
                setTop5SelectedWebseries('ThisWeek')
                setTop5Webseries(temp)
                break;

            case 'ThisMonth':
                let today = new Date()
                today.setMonth(today.getMonth() - 3)
                const result = today.toISOString().split('T')[0];
                params.filter(item => {
                    const itemDate = new Date(item.releaseDate);
                    if (itemDate >= new Date(result) && itemDate <= new Date()) {
                        temp.push(item)
                    }
                });
                setTop5SelectedWebseries('ThisMonth')
                setTop5Webseries(temp)
                break;

            case '3Month':
                let today1 = new Date()
                today1.setMonth(today1.getMonth() - 6)
                const result1 = today1.toISOString().split('T')[0];
                params.filter(item => {
                    const itemDate = new Date(item.releaseDate);
                    if (itemDate >= new Date(result1) && itemDate <= new Date()) {
                        temp.push(item)
                    }
                });
                setTop5SelectedWebseries('3Month')
                setTop5Webseries(temp)
                break;
            default:
                break;
        }
    }


    async function ChangeTheWebRatingFunction(rating) {
        let ParticularMovie = Top5Webseries.find((e => e.id == selectedSeries))
        try {
            let temp = []
            if (ParticularMovie.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (ParticularMovie.rating !== null) {
                temp = [...JSON.parse(ParticularMovie.rating)]
                const result = temp.findIndex(e => e.i == userInfo.id)
                if (result == -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp[result].r = rating
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/Webseries/${selectedSeries}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdatewebLocally(selectedSeries, JSON.stringify(temp))
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    async function ChangeTheTop5RatingFunction(rating) {
        let ParticularMovie = Top5ArrayData.find((e => e.id == SelectedMovieRef.current))
        try {
            let temp = []
            if (ParticularMovie.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (ParticularMovie.rating !== null) {
                temp = [...JSON.parse(ParticularMovie.rating)]
                const result = temp.findIndex(e => e.i == userInfo.id)
                if (result == -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp[result].r = rating
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies/${SelectedMovieRef.current}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                Updatetop5Locally(SelectedMovieRef.current, JSON.stringify(temp))
                SelectedMovieRef.current = 0
                setTop5swap(false)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    function Updatetop5Locally(_id, _obj) {
        let tempMovies = [...Top5ArrayData]
        tempMovies.find(e => e.id == _id).rating = _obj
        setTop5ArrayData(tempMovies)
    }
    async function ChangeTheRatingFunction(rating) {
        let ParticularMovie = bannersData.find((e => e.id == SelectedMovieRef.current))
        try {
            let temp = []
            if (ParticularMovie.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (ParticularMovie.rating !== null) {
                temp = [...JSON.parse(ParticularMovie.rating)]
                const result = temp.findIndex(e => e.i == userInfo.id)
                if (result == -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp[result].r = rating
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies/${SelectedMovieRef.current}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdateMovieLocally(SelectedMovieRef.current, JSON.stringify(temp))
                SelectedMovieRef.current = 0
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    function UpdatewebLocally(_id, _obj) {
        let tempMovies = [...Top5Webseries]
        tempMovies.find(e => e.id == _id).rating = _obj
        setTop5Webseries(tempMovies)
    }

    function UpdateMovieLocally(_id, _obj) {
        let tempMovies = [...bannersData]
        tempMovies.find(e => e.id == _id).rating = _obj
        setBannersData(tempMovies)
    }
    function ReturnRating(params) {
        if (params != null) {
            let tempValue = 0
            JSON.parse(params)?.forEach(e => tempValue += e.r);
            return (tempValue / JSON.parse(params).length) == 0 ? 0 : (tempValue / JSON.parse(params).length).toFixed(1)
        }
        else
            return 0

    }
    return (
        <>
            <div className={`w-full bg-[#0e0d12] h-[100vh] overflow-y-auto`}>
                <Header props={{ selected: "Home" }} />

                {
                    showFullwidthBanner && bannersData.length > 0 && FullBannerData.length > 0 ?
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='absolute top-0 flex flex-col justify-center z-[9999] overflow-hidden backdrop-blur-sm w-full h-[100vh] '>
                            <div className=''>
                                <FullWidthBanner sectionId={FullBannerData[0].sectionId} internal={FullBannerData[0].internal} Imageurl={FullBannerData[0].bannerUrl} id={FullBannerData[0].id} Closebanner={Closebanner} redirectUrl={FullBannerData[0].redirectUrl} YoutubeLink={FullBannerData[0].youtubeUrl} />
                            </div>
                        </motion.div> : null}
                <div className={`bg-[#f2f2f2] m-auto pb-5 px-2 lg:px-0 flex flex-col justify-center items-center`}>
                    {
                        showStarRating &&
                        <div id='Rating' className='w-full md:w-1/2 lg:w-1/3 absolute z-[99] top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                            <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                            <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                            <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                            <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
                            <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                                {
                                    stars.map((e, index) =>
                                        <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                            className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                                    )
                                }
                            </div>
                            <div onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                                className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                                <h4 className=' tracking-wide text-base'>Rate</h4>
                            </div>
                        </div>
                    }
                    {
                        SeriesStarRating &&
                        <div id='Rating' className='w-full md:w-1/2 lg:w-1/3 absolute z-50 top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                            <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                            <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                            <img onClick={() => setSeriesStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                            <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
                            <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                                {
                                    stars.map((e, index) =>
                                        <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                            className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                                    )
                                }
                            </div>
                            <div onClick={() => Seriesnotify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                                className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                                <h4 className=' tracking-wide text-base'>Rate</h4>
                            </div>
                        </div>
                    }
                    {
                        showMovieTrailer ?
                            <div id='Video' className='py-5 md:h-[50vh] md:w-[50%] lg:w-[40%] w-full bg-[#131722] absolute z-[999] rounded-lg flex flex-col items-center '>
                                <div id='Video' className=' flex flex-row justify-between w-full items-center px-5 pb-3'>
                                    <h4 className='text-white font-normal'>Trailer</h4>
                                    <img onClick={() => setShowMovieTrailer(false)} className='w-[20px] hover:scale-125 hover:cursor-pointer duration-300 object-contain ' src={Close} />
                                </div>
                                <div id='Video' className='md:w-[100%] h-full w-full flex flex-col gap-3 px-5'>
                                    <ReactPlayer
                                        width="100%"
                                        height='100%'
                                        objectFit='fill'
                                        url={`https://www.youtube.com/watch?v=${movieTrailerLink}`}
                                        playing={true}
                                        controls={true}
                                    />
                                </div>
                            </div> : null
                    }
                    {
                        bannerYTlink &&
                        <OpenYoutubeBox YtLink={YtLink} handleYtFalseFunction={handleYtFalseFunction} />
                    }
                    {
                        ShowWebseriesBox && <OpenYoutubeBox YtLink={YtLink} handleYtFalseFunction={WebseriesTurnOff} />
                    }
                    {
                        ShowBannerStarRating &&
                        <OpenStarRatingBox CurrentRating={CurrentRating} HandleStarRatingFunctionForClose={HandleStarRatingFunctionForClose} />
                    }

                    <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'white' }} />
                    <Carousel customDot={<CustomDot />} arrows={true} responsive={responsive} showDots={true} autoPlay={true} infinite={true} className='w-full z-[9] m-auto max-w-[1800px]'>
                        {
                            bannersData.length > 0 && bannersData.slice(0, 10).map((e, index) =>
                                <div id="Video" key={index} className='flex flex-col mx-3 '>
                                    <h5 className={`text-white bg-primary_color cursor-default font-normal w-[100px] rounded-sm self-center relative left-1 top-3 text-center`}>{e.displayTag}</h5>
                                    <div id="Video" key={index} className='flex flex-col justify-end items-center'>
                                        {/* <div className='w-[290px] rounded-md h-[400px] absolute bg-gradient-to-b from-transparent to-black'></div> */}
                                        {e.thumbnailPictureUrl != null ? <img id="Video" onClick={() => navigate("/AboutMovie", { state: { data: e, test: FetchingGenreDataByID(e.genreIds), movierating: movieRating } })}
                                            className=' w-[290px] self-center hover:cursor-pointer h-[400px] rounded-md object-fill ' src={`https://storage.googleapis.com/cinimini-console/movies/${e.id}/${JSON.parse(e.thumbnailPictureUrl)[0]}`} alt='' /> : null}
                                        {/* <div className='w-[275px] hf absolute bg-gradient-to-b from-transparent to-black hover:opacity-60  hover:cursor-pointer rounded-md object-top object-fill '></div> */}
                                        <img id="Video" onClick={() => MovieTrailerFunction(e.youtubeLink)} className='absolute hover:cursor-pointer opacity-50 hover:opacity-100 duration-300 h-[35px] w-[35px] top-[40%] left-[45%] lg:left-[42%]' src={playcircle} />
                                        {
                                            new Date() < new Date(e.releaseDate) ? null :
                                                <div className='-mt-[51px] z-[999] w-[290px] md:w-full  flex flex-col pl-5 py-4 rounded-md'>
                                                    <div className='flex flex-row gap-x-2 items-center'>
                                                        <img className='w-[20px] object-contain' src={Star} alt='' />
                                                        <div className='flex flex-row items-center justify-center gap-x-4'>
                                                            <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(e.rating)}/10</h6>
                                                            <div className='flex flex-row items-center justify-center gap-x-1'>
                                                                <img id='Rating' onClick={() => MovieRatingFunction(e)} className='w-[20px] cursor-pointer object-contain' src={movieRating !== null ? movieRating.findIndex(e1 => e1.i == e.id) != -1 ? Star : starnotfill : starnotfill} alt='' />
                                                                <h6 className='text-gray-200 font-normal cursor-default text-sm'>{movieRating !== null ? movieRating.findIndex(e1 => e1.i == e.id) != -1 ? movieRating.find(e1 => e1.i == e.id).r + '/10' : null : null}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                    </div>
                                    <div className='flex w-[280px] md:w-full  self-center flex-col mb-4 lg:px-3 2xl:px-0'>
                                        <div className=' flex flex-row items-center justify-between rounded-md'>
                                            {FetchingGenreDataByID(e.genreIds) != undefined ?
                                                <div className=' py-1  text-sm hover:cursor-default  rounded-md w-max'>
                                                    {FetchingGenreDataByID(e.genreIds)}
                                                </div> : <div className=' py-1 px-2 rounded-sm w-max text-transparent'>none</div>}
                                            <h5 className='text-gray-500 font-medium hover:cursor-default tracking-widest'>{new Date(e.releaseDate).getUTCFullYear()}</h5>
                                        </div>
                                        <label onClick={() => navigate("/AboutMovie", { state: { data: e, test: FetchingGenreDataByID(e.genreIds), movierating: movieRating } })} className='text-[#24baef] font-medium hover:cursor-pointer'>{e.name.length > 25 ? e.name.substr(0, 20) + '...' : e.name}</label>
                                    </div>
                                </div>
                            )
                        }
                    </Carousel>
                </div>
                <div className='bg-[#131722] w-full pt-5 pb-10 px-2 flex flex-row justify-between'>
                    {/* <div className='w-[170px] hidden relative lg:flex flex-col justify-start items-start '>
                        <BannerComponent ImageUrl={'https://tpc.googlesyndication.com/simgad/7642967379725963211?sqp=4sqPyQQrQikqJwhfEAEdAAC0QiABKAEwCTgDQPCTCUgAUAFYAWBfcAJ4AcUBLbKdPg&rs=AOga4qnrpkMo9p9NcS0leYKZGPRaJOPR7w'}/>
                    </div> */}
                    <div className='w-full mx-auto'>
                        <div className='container relative mb-2 m-auto px-3 flex flex-row'>{
                            HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />
                        }
                        </div>
                        <div className='container m-auto flex md:flex-row flex-col justify-center md:justify-between items-center px-3'>
                            <h2 className='text-white text-left w-full lg:w-auto text-base md:text-2xl font-medium'>Top {Top5ArrayData.length <= 4 ? Top5ArrayData.length : 4} Movies</h2>
                            <div className='border-t-[1px] hidden lg:block 2xl:w-[65%] xl:w-[55%] lg:w-[45%] border-t-[#3a3c45]'></div>
                            <div className='flex flex-row lg:gap-y-0 lg:mt-0 mt-2 mb-2 lg:mb-0 gap-x-10  overflow-auto w-full lg:w-auto items-center justify-between'>
                                <label onClick={() => Top5Movies(bannersData, '3Months')} className={`whitespace-nowrap text-sm ${Top5Selected === '3Months' ? 'text-primary_color' : 'text-[#949cb0]'}`}>Trending</label>
                                <label onClick={() => Top5Movies(bannersData, '6Months')} className={`whitespace-nowrap text-sm ${Top5Selected === '6Months' ? 'text-primary_color' : 'text-[#949cb0]'}`}>In Theater</label>
                                <label onClick={() => Top5Movies(bannersData, 'ThisMonth')} className={`whitespace-nowrap text-sm ${Top5Selected === 'ThisMonth' ? 'text-primary_color' : 'text-[#949cb0]'} `}>Upcoming</label>
                            </div>
                        </div>
                        {Top5ArrayData.length > 0 ?
                            <div className='w-full px-3 lg:container m-auto flex flex-col  mt-6 '>
                                <div className='grid 2xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-x-5 border-b-[1px] border-b-[#3a3c45]'>
                                    {Top5ArrayData.map((item, index) => index < 4 ?
                                        <div key={index} className='flex flex-col lg:mb-3 mb-5'>
                                            <div className='flex flex-col self-center justify-end min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] lg:h-[400px] relative rounded-md hover:bg-[#24baef] duration-300'>
                                                {item.thumbnailPictureUrl != null ? <img onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds) != undefined && FetchingGenreDataByID(item.genreIds), movierating: movieRating } })}
                                                    className='lg:w-[290px] relative hover:opacity-60 hover:cursor-pointer w-full min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] lg:h-[400px] rounded-md object-top object-fill ' src={`https://storage.googleapis.com/cinimini-console/movies/${item.id}/${JSON.parse(item.thumbnailPictureUrl)}`} alt='' /> : null}
                                                <div onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds) != undefined && FetchingGenreDataByID(item.genreIds), movierating: movieRating } })} className=' absolute bg-gradient-to-b from-transparent from-[75%] to-black hover:opacity-60 hover:bg-[#24baef] hover:cursor-pointer w-full min-[320px]:h-[201px] min-[375px]:h-[250px] min-[425px]:h-[280px] md:h-[350px] lg:h-[400px] rounded-md object-top object-fill '></div>
                                                <img id="Video" onClick={() => MovieTrailerFunction(item.youtubeLink)} className=' h-[35px] w-[35px] opacity-50   hover:cursor-pointer absolute hover:opacity-100 bottom-[45%] md:left-[45%] left-[38%]' src={playcircle} />
                                                {
                                                    new Date() < new Date(item.releaseDate) ? null :
                                                        <div className='flex flex-row  w-max md:pl-3 absolute md:my-5 my-2 mx-2 gap-x-2 items-center'>
                                                            <img className='md:w-[18px] w-[15px] object-contain' src={Star} alt='' />
                                                            <div className='flex flex-row items-center justify-center gap-x-4'>
                                                                <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(item.rating)}/10</h6>
                                                                <div className='flex flex-row items-center justify-center gap-x-1'>
                                                                    <img id='Rating' onClick={() => MovieRatingFunction(item, 'Top5')} className='md:w-[20px] w-[15px] cursor-pointer object-contain' src={movieRating != null ? movieRating.findIndex(e1 => e1.i == item.id) != -1 ? Star : starnotfill : starnotfill} alt='' />
                                                                    <h6 className='text-gray-200 font-normal cursor-default text-sm'>{movieRating != null ? movieRating.findIndex(e1 => e1.i == item.id) != -1 ? movieRating.find(e1 => e1.i == item.id).r + '/10' : null : null}</h6>
                                                                </div>
                                                            </div>
                                                        </div>}
                                            </div>
                                            <div className='flex flex-col lg:self-start self-center w-full pt-2'>
                                                <div className='flex flex-row items-center justify-between rounded-md'>
                                                    {FetchingGenreDataByID(item.genreIds) != undefined ?
                                                        <div className='text-[12px] lg:text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                            {FetchingGenreDataByID(item.genreIds)}
                                                        </div> : <div className='rounded-md text-transparent'>none</div>}
                                                    <h5 className='text-[#949cb0] text-[12px] lg:text-[14px] hover:cursor-default font-normal tracking-widest'>{new Date(item.releaseDate).getUTCFullYear()}</h5>
                                                </div>
                                                <label onClick={() => navigate("/AboutMovie", { state: { data: item, test: FetchingGenreDataByID(item.genreIds), movierating: movieRating } })} className='text-white text-[14px] lg:text-[16px] hover:cursor-pointer'>{item.name.length > 25 ? item.name.substr(0, 20) + '...' : item.name}</label>
                                            </div>
                                        </div> : null
                                    )}
                                    {
                                        SquareBanner.length > 0 &&
                                        <div className='lg:w-[290px] xl:mb-5 md:h-[350px] lg:h-[400px] flex flex-col justify-center items-center'>
                                            <div className='lg:w-[290px] md:h-[350px] lg:h-[400px] mb-5 md:mb-0 flex flex-col self-center overflow-y-hidden'>
                                                <BannerComponent sectionId={SquareBanner[0].sectionId} internal={SquareBanner[0].internal} redirectUrl={SquareBanner[0].redirectUrl} id={SquareBanner[0].id} ImageUrl={SquareBanner[0].bannerUrl} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <h5 onClick={() => navigate('/Movies')} className='text-[#949cb0] flex flex-row self-end items-center gap-x-1 mt-2 hover:translate-x-3 hover:text-[#24baef] duration-300 hover:cursor-pointer font-normal'>View All<FaAngleRight /></h5>
                            </div> : null}
                    </div>
                    {/* <div className='w-[170px] hidden lg:flex relative flex-col justify-start items-start'>
                        <BannerComponent ImageUrl={'https://tpc.googlesyndication.com/simgad/7642967379725963211?sqp=4sqPyQQrQikqJwhfEAEdAAC0QiABKAEwCTgDQPCTCUgAUAFYAWBfcAJ4AcUBLbKdPg&rs=AOga4qnrpkMo9p9NcS0leYKZGPRaJOPR7w'}/>
                    </div> */}
                </div>
                <div className='lg:container bg-[#0e0d12] w-full flex flex-col py-10 m-auto px-3 '>
                    <div className='flex flex-row gap-2 w-full'>
                        <div className=' border-l-4  cursor-default border-l-[#24baef]'></div>
                        <h1 className='font-semibold  cursor-default text-white'>RECENT NEWS</h1>
                    </div>
                    <h5 className='text-[#949cb0]  cursor-default font-normal'>Stay tuned for all the latest entertainment news and TV premiers</h5>
                    <div className='flex lg:flex-row md:gap-x-4 flex-col justify-between mt-5'>
                        <div className=' lg:w-[70%] w-full grid md:grid-cols-2 grid-cols-1 gap-x-2'>
                            {newsFeed.map((e, index) => index < 4 ?
                                <div key={index} className='flex bg-[#1c212e] px-2 mb-2 py-2 rounded-md flex-col gap-y-1'>
                                    <div className=' hover:bg-[#24baef] duration-300 rounded-md'>
                                        <img onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='w-full hover:opacity-60 hover:cursor-pointer rounded-md object-cover object-top gap-6 h-[300px]' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + e.id + '/' + e.thumbnailUrl} alt='' />
                                    </div>
                                    <div className='flex flex-row justify-between mt-1'>
                                        <div className='flex flex-row gap-2 '>
                                            <h5 className='text-[#24baef] cursor-default text-[12px]'>NEWS & UPDATES </h5>
                                            <h5 className='text-[#949cb0] cursor-default text-[12px]'>{returnTheReleaseDate(e.dateOfPublish)}</h5>
                                        </div>
                                    </div>
                                    <div className='flex flex-row gap-2 w-full'>
                                        <div className=' border-l-4  border-l-[#24baef]'></div>
                                        <h4 onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='font-normal text-base line-clamp-2 text-white hover:text-[#24baef] duration-300 hover:cursor-pointer mt-1'>{e.name}</h4>
                                    </div>
                                    <div className='flex flex-row items-center justify-between '>
                                        <div className='flex flex-row items-center gap-x-3'>
                                            <div className='flex flex-row  p-1 rounded-md items-center gap-x-1'>
                                                <FaEye className='text-white' />
                                                <h5 className='text-[#24baef] cursor-default font-normal'>{e.viewCount}</h5>
                                            </div>
                                            <div className='flex flex-row items-center p-1 rounded-md gap-x-1'>
                                                {NewsFeedLikes !== null ?
                                                    (NewsFeedLikes.findIndex(e1 => e1 == e.id) == -1 ?
                                                        (
                                                            <FaRegHeart onClick={() => StoringTheLikes(e.id)} className='text-white hover:cursor-pointer' />
                                                        ) : (
                                                            <FaHeart onClick={() => StoringTheLikes(e.id)}
                                                                className=' text-red-600 hover:cursor-pointer' />))
                                                    : <FaRegHeart onClick={() => StoringTheLikes(e.id)} className='text-white hover:cursor-pointer' />}
                                                <h5 className='text-[#24baef] cursor-default font-normal'>{e.likeCount}</h5>
                                            </div>
                                            <div className='flex flex-row p-1  rounded-md items-center gap-x-1'>
                                                <FaRegCommentAlt className='text-white hover:cursor-pointer' onClick={() => CommentFunction(e)} />
                                                <h5 className='text-[#24baef] cursor-default font-normal'>{e.commentsCount == null ? 0 : e.commentsCount}</h5>
                                            </div>
                                        </div>
                                        <label className=' w-16 hover:translate-x-4 text-sm flex flex-row items-center text-[#949cb0] font-medium cursor-pointer' onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })}>More <FaAngleRight className=' hover:text-[#24baef]' /></label>
                                    </div>
                                </div> : null
                            )
                            }
                        </div>
                        <div className='xl:w-[30%] lg:w-[35%] flex flex-col gap-x-5 md:flex-row lg:flex-col w-full mt-5 md:mt-0'>
                            <div className='bg-[#f2f2f2]  md:w-[49%] lg:w-auto pb-5 flex flex-col rounded-md'>
                                <div className='flex flex-row gap-2 w-full  my-5'>
                                    <div className=' border-l-4 ml-4 border-l-[#24baef]'></div>
                                    <h3 className='font-semibold hover:cursor-default'>RECENT NEWS</h3>
                                </div>
                                <div className='flex flex-col rounded-md h-auto custom-scrollbar overflow-auto gap-y-3'>
                                    {
                                        newsFeed.map((e, index) => index < 5 ?
                                            <div key={index} className='flex flex-col gap-y-5 mx-4 border-b-[1px] border-b-gray-500'>
                                                <div className='flex-row flex items-center gap-5 justify-between pb-2'>
                                                    <img onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='w-[30%] h-[100px] hover:cursor-pointer rounded-md object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + e.id + '/' + e.thumbnailUrl} />
                                                    <div className='w-[70%] flex flex-col'>
                                                        <h5 onClick={() => navigate("/KnowMore", { state: { news: e, newsFeed: newsFeed } })} className='text-black hover:text-[#24baef]  hover:cursor-pointer line-clamp-2 duration-300 leading-4 mb-2 text-xs md:text-[14px]  font-semibold '>{e.name}</h5>
                                                        <div className='flex 2xl:flex-row flex-col justify-between mt-1 md:mt-0 2xl:items-center'>
                                                            {/* <h5 className=' text-gray-500 hover:cursor-default text-[12px]'>{getThePostTime(e.dateOfPublish)} Ago</h5> */}
                                                            <h5 className=' text-gray-500 hover:cursor-default text-[12px]'>{returnTheReleaseDate(e.dateOfPublish)}</h5>
                                                            <div className='flex flex-row items-center gap-x-2'>
                                                                <div className='flex flex-row md:p-1 rounded-md items-center gap-x-1'>
                                                                    <FaEye className='' />
                                                                    <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.viewCount}</h5>
                                                                </div>
                                                                <div className='flex flex-row items-center   p-1 rounded-md gap-x-1'>
                                                                    {NewsFeedLikes !== null ?
                                                                        (NewsFeedLikes.findIndex(e1 => e1 == e.id) == -1 ?
                                                                            (
                                                                                <FaRegHeart onClick={() => StoringTheLikes(e.id)} className=' hover:cursor-pointer' />
                                                                            ) : (
                                                                                <FaHeart onClick={() => StoringTheLikes(e.id)}
                                                                                    className=' text-red-600 hover:cursor-pointer' />))
                                                                        : <FaRegHeart onClick={() => StoringTheLikes(e.id)} className='hover:cursor-pointer' />}
                                                                    <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.likeCount}</h5>
                                                                </div>
                                                                <div className='flex flex-row p-1   rounded-md items-center gap-x-1'>
                                                                    <FaRegCommentAlt onClick={() => CommentFunction(e)} className='hover:cursor-pointer' />
                                                                    <h5 className='text-[#24baef] hover:cursor-default text-[12px] font-normal'>{e.commentsCount == null ? 0 : e.commentsCount}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                        )
                                    }
                                </div>
                            </div>
                            <div className='relative mt-3'>
                                {SquareBanner.length > 0 && <BannerComponent sectionId={SquareBanner[0].sectionId} internal={SquareBanner[0].internal} height={200} redirectUrl={SquareBanner[0].redirectUrl} id={SquareBanner[0].id} ImageUrl={SquareBanner[0].bannerUrl} />}
                            </div>
                        </div>
                    </div>
                </div>
                {bannersData.length > 0 && FullWidthBannerArray.length > 0 ?
                    <FullWidthConatiner handleYtFunction={handleYtFunction} youtubeUrl={FullWidthBannerArray[0].youtubeUrl} sectionId={FullWidthBannerArray[0].sectionId} internal={FullWidthBannerArray[0].internal} redirectUrl={FullWidthBannerArray[0].redirectUrl} id={FullWidthBannerArray[0].id} ImageUrl={FullWidthBannerArray[0].bannerUrl} subTitle={FullWidthBannerArray[0].subTitle} title={FullWidthBannerArray[0].title} /> : null}
                <div className='w-full flex flex-col justify-center items-center bg-[#131722]'>
                    <div className='xl:max-w-[2160px] w-full flex flex-col self-center bg-[#131722] py-5 '>
                        <div className='w-full mx-auto gap-x-2 px-2 flex lg:flex-row flex-col items-center justify-center'>
                            <div className='w-[170px] h-[650px] hidden overflow-y-hidden lg:flex relative flex-col'>
                                {leftBanner.length > 0 && <BannerComponent sectionId={leftBanner[0].sectionId} internal={leftBanner[0].internal} height={'650'} redirectUrl={leftBanner[0].redirectUrl} id={leftBanner[0].id} ImageUrl={leftBanner[0].bannerUrl} />}
                            </div>
                            {Webseries.length > 0 && <BannerSliderComponent WebseriesTurnOn={WebseriesTurnOn} array={Webseries.slice(0, 5)} />}
                            <div className='w-[170px] hidden h-[650px] overflow-y-hidden lg:flex relative flex-col '>
                                {RightBanner.length > 0 && <BannerComponent height={'650'} sectionId={RightBanner[0].sectionId} internal={RightBanner[0].internal} redirectUrl={RightBanner[0].redirectUrl} id={RightBanner[0].id} ImageUrl={RightBanner[0].bannerUrl} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-[#0e0d12] flex flex-col py-10 '>
                    <div className='container m-auto flex flex-col'>
                        <div className='container m-auto border-b-[1px] border-b-[#3a3c45] pb-5 '>
                            <div className='container flex flex-col md:flex-row justify-between px-2 items-center '>
                                <h2 className='text-white md:w-auto w-full text-left lg:text-2xl text-base font-medium'>WebSeries</h2>
                                <div className='border-t-[1px] hidden lg:block 2xl:w-[65%] xl:w-[60%] lg:w-[50%]  border-t-[#3a3c45]'></div>
                                <div className='flex flex-row mt-3 md:mt-0 md:gap-x-10 w-full md:w-auto items-center justify-between'>
                                    <label onClick={() => Top5WebSeriesFunction(Webseries, 'ThisWeek')} className={`text-sm ${Top5SelectedWebseries == 'ThisWeek' ? 'text-primary_color' : 'text-[#949cb0]'} `}>This Month</label>
                                    <label onClick={() => Top5WebSeriesFunction(Webseries, 'ThisMonth')} className={`text-sm ${Top5SelectedWebseries == 'ThisMonth' ? 'text-primary_color' : 'text-[#949cb0]'} `}>3 Month Ago</label>
                                    <label onClick={() => Top5WebSeriesFunction(Webseries, '3Month')} className={`text-sm ${Top5SelectedWebseries == '3Month' ? 'text-primary_color' : 'text-[#949cb0]'} `}>6 Months Ago</label>
                                </div>
                            </div>

                            <Carousel slidesToSlide={1} arrows={true} responsive={responsive1} className='mt-5 m-auto'>
                                {
                                    Top5Webseries.length > 0 ? Top5Webseries.map((e, index) =>
                                        <div key={index} className=' flex  flex-col px-2 justify-between'>
                                            <img onClick={() => navigate("/AboutWebseries", { state: { data: e, test: FetchingGenreDataByID(e.genreIds), webseriesRating: webseriesRating } })} className='w-[390px] hover:cursor-pointer rounded-md h-[185px] ' src={`https://storage.googleapis.com/cinimini-console/webseries/${e.id}/${e.bannerUrl}`} />
                                            <img id="Video" onClick={() => MovieTrailerFunction(e.youtubeLink)} className='hover:cursor-pointer opacity-60 hover:opacity-100 object-contain absolute top-[30%] left-[45%] h-[35px] w-[35px]' src={playcircle} />
                                            <div className='flex flex-row items-center justify-between pt-1'>
                                                <div className=' text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                    {FetchingGenreDataByID(e.genreIds) != undefined ?
                                                        <div className=' text-[14px] text-[#949cb0] hover:cursor-default font-normal rounded-md '>
                                                            {FetchingGenreDataByID(e.genreIds)}
                                                        </div> : <div className='rounded-md text-transparent'>none</div>}
                                                </div>
                                                <h5 className='text-gray-400 text-sm font-normal cursor-default'>{(JSON.parse(e.seasonDetails))[0].y} {JSON.parse(e.seasonDetails).length > 1 ? Number((JSON.parse(e.seasonDetails))[JSON.parse(e.seasonDetails).length - 1].y) == Number((JSON.parse(e.seasonDetails))[0].y) ? '' : ' - ' + (JSON.parse(e.seasonDetails))[JSON.parse(e.seasonDetails).length - 1].y : ''}</h5>
                                            </div>
                                            <label onClick={() => navigate("/AboutWebseries", { state: { data: e, test: FetchingGenreDataByID(e.genreIds), webseriesRating: webseriesRating } })} className='font-medium text-white w-max tracking-wide '>{e.name}</label>
                                            {new Date() < new Date(e.releaseDate) ? null : <div className='flex flex-row  absolute bottom-[23%] gap-x-2 p-1'>
                                                <img className='w-[20px] h-[20px] ' src={Star} />
                                                <h6 className='text-gray-200 font-normal  cursor-default text-sm'>{ReturnRating(e.rating)}/10</h6>
                                                <div className='flex flex-row gap-x-2'>
                                                    <img id='Rating' onClick={() => SeriesRatingFunction(e)} className='w-[20px] cursor-pointer object-contain' src={webseriesRating != null && webseriesRating.findIndex(e1 => e1.i == e.id) != -1 ? Star : starnotfill} alt='' />
                                                    <h6 className='text-gray-200 font-normal cursor-default text-sm'>{webseriesRating != null ? webseriesRating.findIndex(e1 => e1.i == e.id) != -1 ? webseriesRating.find(e1 => e1.i == e.id).r + '/10' : null : null}</h6>
                                                </div>
                                            </div>}
                                        </div>
                                    ) : <h1 className='text-white font-normal text-sm'>No Data Found</h1>
                                }
                            </Carousel>
                        </div>
                        <h5 onClick={() => navigate('/Webseries')} className='text-[#949cb0] flex flex-row self-end mt-2 w-max items-center gap-x-1 hover:translate-x-3 hover:text-[#24baef] duration-300 hover:cursor-pointer font-normal'>View All<FaAngleRight /></h5>
                    </div>
                </div>
                <div className='w-full bg-[#131722] flex flex-col justify-center items-center'>
                    <div className='container bg-[#131722] m-auto px-2'>
                        {celebritiesData.length > 0 ?
                            <div className='flex flex-col '>
                                <div className='flex flex-row gap-2 mt-5'>
                                    <div className=' border-l-4  border-l-[#24baef]'></div>
                                    <h1 className='font-bold text-white'>BORN TODAY</h1>
                                </div>
                                <h5 className='text-gray-400 font-normal'>Stay tuned for all the latest entertainment news and TV premiers</h5>
                                <Carousel arrows responsive={responsiveCelebrity} className=''>
                                    {celebritiesData.map((e, index) =>
                                        <div onClick={() => navigate("/Actorinfo", { state: { data: e } })} key={index} className='flex flex-col gap-y-2 py-5 mr-5'>
                                            <img className='h-[400px] hover:cursor-pointer rounded-md object-cover object-top' src={`https://storage.googleapis.com/cinimini-console/celebrities/${e.id}/${(JSON.parse(e.profilePictureUrls)[0])}`} />
                                            <h4 className='text-white font-normal italic'>{e.name}</h4>
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                            : null}
                    </div>
                </div>
                <Footer />
                {BottomBannerState ? <div className='w-full py-2 sticky bottom-0 flex flex-col z-[99] justify-center items-center bg-gray-200'>
                    <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                        <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                    </div>
                    {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
                </div> : null}
            </div>
        </>
    )
}

export default Home;
