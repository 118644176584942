import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import RadioButtonOn from '../Image/RadioButtonOn.png';
import RadioButtonOff from '../Image/RadioButtonOff.png';
import { FaPlay } from 'react-icons/fa';
import { GetTheData } from '../Application/Api';
import { useNavigate } from 'react-router-dom';
function BannerSliderComponent({ array, WebseriesTurnOn }) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 2560, min: 1440 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1439, min: 901 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 900, min: 400 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 645, min: 0 },
            items: 1
        }
    };
    const CustomDot = ({
        index,
        active,
        onClick
    }) => {
        return (
            <div className='flex flex-col justify-center mr-[10px] hover:cursor-pointer items-center' onClick={e => {
                onClick();
                e.preventDefault();
            }}>
                {active ? <div className='w-10 h-2 duration-300 bg-primary_color rounded-full' /> : <div className='w-2 h-2 duration-300 bg-white rounded-full' />}
            </div>
        );
    };
    const [playHover, setPlayHover] = useState(false)
    useEffect(() => {
        FetchingGenreData()
        FetchingOttPlatforms()
        FetchingCategoryData()
    }, [])

    const [GenreCollection, setGenreCollection] = useState([])
    async function FetchingGenreData() {
        try {
            const response = await GetTheData('movieGenres')
            setGenreCollection(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchingGenreDataByID(params) {
        const value = JSON.parse(params)
        try {
            if (value.length > 1) {
                let temp = []
                let count = 0
                for (let i = 0; i < value.length; i++) {
                    const data1 = GenreCollection.find(e => e.id === Number(value[i]))
                    count++;
                    temp += data1.name.trim()
                    if (count > 3) {
                        temp += '...'
                        break;
                    }
                    if (i != value.length - 1) {
                        temp += ', '
                    }

                }
                return temp;
            } else {
                const data = GenreCollection.find(e => e.id === value[0])
                return data.name;
            }
        } catch (error) {
            console.log('error genre');
        }
    }
    const [OttPlatform, setOttPlatform] = useState([])
    async function FetchingOttPlatforms() {
        try {
            const response = await GetTheData('ottPlatforms')
            setOttPlatform(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }
    function FetchSeriesName(params) {
        if (params != undefined)
            return OttPlatform.find(e => e.id === Number(params))?.name
    }
    const navigate = useNavigate()
    function FilterLanguages(params) {
        console.log(params);
        let temp = ''
        for (let i = 0; i < JSON.parse(params.categoryIds).length; i++) {
            let value = movieCategories.find(e => e.id === JSON.parse(params.categoryIds)[i]).name
            if (i < JSON.parse(params.categoryIds).length - 1) {
                value=value+', '
            }
            temp += value;
        }
        return temp;
    }
    const [movieCategories, setMovieCategories] = useState([])
    async function FetchingCategoryData() {
        try {
            const response = await GetTheData('movieCategories')
            setMovieCategories(response.data)
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <div className=''>
            <Carousel autoPlay customDot={<CustomDot />} showDots={true} infinite arrows={true} responsive={responsive} className='xl:w-[1110px] min-[320px]:w-[300px] min-[375px]:w-[350px] min-[425px]:w-[380px] md:w-[700px] lg:w-[800px] rounded-md h-[500px] '>
                {array.map((e, index) =>
                    <div key={index} className='w-full brightness-90 h-[450px] rounded-md justify-center flex flex-col '>
                        <img className='w-full h-[450px] absolute object-cover object-top rounded-md' src={`https://storage.googleapis.com/cinimini-console/webseries/${e.id}/${e.bannerUrl}`} />
                        <div onClick={() => navigate("/AboutWebseries", { state: { data: e, test: FetchingGenreDataByID(e.genreIds), Languages: FilterLanguages(e) } })} className='w-full cursor-pointer h-full px-10 z-[99] py-20 flex flex-row justify-between items-center'>
                            <div className='opacity-100 '>
                                <h1 onClick={() => navigate("/AboutWebseries", { state: { data: e, test: FetchingGenreDataByID(e.genreIds) } })} className='text-primary_color cursor-pointer text-4xl xl:text-6xl font-extrabold mb-[10px]'>{e.name}</h1>
                                <div className='flex flex-col md:flex-row gap-x-5'>
                                    <h6 className='text-sm text-white font-normal'>{FetchingGenreDataByID(e.genreIds)}</h6>
                                    <h3 className='border-r-2 border-r-gray-400'></h3>
                                    <h6 className='text-sm text-white font-normal'>{(JSON.parse(e.seasonDetails))[0].y} {JSON.parse(e.seasonDetails).length > 1 ? Number((JSON.parse(e.seasonDetails))[JSON.parse(e.seasonDetails).length - 1].y) == Number((JSON.parse(e.seasonDetails))[0].y) ? '' : ' - ' + (JSON.parse(e.seasonDetails))[JSON.parse(e.seasonDetails).length - 1].y : ''} </h6>
                                    <h3 className='border-r-2 border-r-gray-400'></h3>
                                    <h6 className='text-sm text-white font-normal'>{JSON.parse(e.seasonDetails).length} Seasons</h6>
                                </div>
                                <div className='flex flex-row items-center mt-5 rounded-lg gap-4 '>
                                    <div className='space-y-1'>
                                        <a >
                                            <label className='text-primary_color text-sm lg:text-base lg:font-semibold tracking-widest'>{FetchSeriesName(JSON.parse(e.ottIds)[0])}</label>
                                        </a>
                                        <h6 className='text-gray-300 tracking-widest hover:cursor-default'>streaming on <label className='text-[12px] text-primary_color'>{FetchSeriesName(JSON.parse(e.ottIds)[0])}</label></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => WebseriesTurnOn(e.youtubeLink)} onMouseEnter={() => setPlayHover(true)} onMouseLeave={() => setPlayHover(false)}
                            className='absolute bottom-[8%] left-[100px] md:left-[70%] md:bottom-[40%] z-[99] border-2 w-[60px] h-[60px] hover:border-white hover:cursor-pointer border-gray-500 rounded-full md:w-[150px] md:h-[150px] flex flex-col justify-center items-center '>
                            <FaPlay className={`md:w-[53px] md:h-[43px] ${playHover ? 'text-primary_color' : 'text-primary_color/60'} duration-300 w-[20px] h-[20px]`} />
                        </div>
                    </div>
                )}
            </Carousel>
        </div>
    )
}

export default BannerSliderComponent