import React, { useEffect, useState } from 'react';
import Star from "../Assets/Star.png";
import Like from "../Assets/like.png";
import Dislike from "../Assets/Dislike.png";
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import Trailer from './Trailer';
import Actors from './Actors';
import Close from '../Assets/Close.png'
import Videos from './Videos';
import MovieNews from './MovieNews';
import Jailer from "../Image/jailer.jpg";
import starnotfill from "../Assets/starnotfill.png";
import amazon from '../Assets/amazon.png'
import Netflix from '../Assets/Netflix.png'
import Itunes from '../Assets/Itunes.png'
import playstore from '../Assets/playstore.png'
import awards from '../Assets/award.png'
import { GetAdvertisements, GetTheData } from '../Application/Api';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import { FaAngleDown, FaBackward } from 'react-icons/fa';
import { FaArrowLeftLong } from "react-icons/fa6";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BannerComponent from './BannerComponent';
function AboutMovie() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [BottomBanner, setBottomBanner] = useState('')
    const [LeftBanner, setLeftBanner] = useState('')
    const [RightBanner, setrightBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const bottombann = data.filter((e) => e.destination == 4 && e.placement == 3)
            setBottomBanner(bottombann)
            const leftbann = data.filter((e) => e.destination == 4 && e.placement == 4)
            setLeftBanner(leftbann)
            const rightbann = data.filter((e) => e.destination == 4 && e.placement == 2)
            setrightBanner(rightbann)
        }
    }
    const location = useLocation()
    const datas = location.state.data;
    const genre = location.state.test;
    const movierating = location.state.movierating;
    console.log(movierating);
    const [MovieRating, setMovieRating] = useState(movierating)
    console.log(genre);
    const award = JSON.parse(datas.awardsIds)

    const [MovieVideos, setMovieVideos] = useState("")
    const [MovieNews1, setMovieNews1] = useState([])
    const [movieCelebrities, setMovieCelebrities] = useState([])
    useEffect(() => {
        axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/movieVideos/${datas.id}`)
            .then((response) => {
                if (response.status == 200) {
                    setMovieVideos(response.data[0].videosCollection)
                }
            }).catch((error) => {
                console.log(error.message);
            })
        axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/topicNews/movies/${datas.id}`)
            .then((response) => {
                if (response.status == 200) {
                    setMovieNews1(response.data)
                }
            }).catch((error) => {
                console.log(error.message);
            })
        axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/celebrityMovies/${datas.id}`)
            .then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    setMovieCelebrities(response.data)
                }
            }).catch((error) => {
                console.log(error.message);
            })
    }, [])
    useEffect(() => {
        FetchingOttPlatforms()
    }, [])

    const [ottplatform, setOttplatform] = useState([])
    async function FetchingOttPlatforms() {
        try {
            const response = await GetTheData('ottPlatforms')
            FetchingOttplatformById(response.data)
        } catch (error) {
            console.log('ott error');
        }
    }
    function FetchingOttplatformById(params) {
        let temp = []
        for (let i = 0; i < JSON.parse(datas.ottIds).length; i++) {
            const data = params.find(e => e.id === Number(JSON.parse(datas.ottIds)[i]))
            if (data != undefined) {
                temp.push(data)
            }

        }
        setOttplatform(temp)
    }
    const [selected, setSelected] = useState("Actors");
    const navigate = useNavigate();
    const OnOption = (_name, _route) => {
        setSelected(_name)
        navigate("/" + _route);
    }
    const [trailer, setTrailer] = useState(false);
    const OnClickTrailer = () => {
        setSelected('trailer')
        setTrailer(true);
        setActors(false)
        setVideo(false);
        setFilmography(false);
        setNews(false);

    }
    const [actors, setActors] = useState(true);
    const OnClickActors = () => {
        setSelected('Actors')
        setActors(true);
        setTrailer(false);
        setVideo(false);
        setFilmography(false);
        setNews(false);
        // setSelected();
    }
    const [video, setVideo] = useState(false);
    const OnClickVideo = () => {
        setSelected('video')
        setVideo(true);
        setTrailer(false);
        setActors(false)
        setFilmography(false);
        setNews(false);
    }
    const [filmography, setFilmography] = useState(false);
    const OnClickFilmography = () => {
        setSelected('film')
        setFilmography(true);
        setTrailer(false);
        setActors(false)
        setVideo(false);
        setNews(false);
    }
    const [news, setNews] = useState(false);
    const OnClickNews = () => {
        setSelected('news')
        setNews(true);
        setTrailer(false);
        setActors(false)
        setVideo(false);
        setFilmography(false);
    }
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data)
            } else {
                setUser(data)
            }
        });
        FetchingCelebrities()
        FetchingAwards()
    }, [])
    const [awardsData, setAwardsData] = useState([])
    async function FetchingAwards() {
        try {
            const response = await GetTheData('awardTitles')
            // setAwardsData(response.data)
            FetchingTheAwardsBasedOnId(response.data)
        } catch (error) {
            console.log('awards error');
        }
    }
    function FetchingTheAwardsBasedOnId(params) {
        let temp = []
        for (let i = 0; i < award.length; i++) {
            const result = params.find(e => e.id == award[i])
            if (result != undefined) {
                temp.push(result)
            }
            // console.log(result);
        }
        setAwardsData(temp)
    }
    async function FetchingCelebrities() {
        try {
            const response = await GetTheData('celebrities')
            if (response.status == 200) {
                CelebritiesFetchFunction(response.data, "dir")
                CelebritiesFetchFunction(response.data, "scr")
                CelebritiesFetchFunction(response.data, "pro")
                CelebritiesFetchFunction(response.data, "wri")
                CelebritiesFetchFunction(response.data, "cin")
                CelebritiesFetchFunction(response.data, "edi")
                CelebritiesFetchFunction(response.data, "sto")
                CelebritiesFetchFunction(response.data, "mus")
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const [director, setDirector] = useState([])
    const [screenPlay, setScreenPlay] = useState([])
    const [producer, setProducer] = useState([])
    const [writter, setWritter] = useState([])
    const [cinimograpy, setCinimograpy] = useState([])
    const [editor, setEditor] = useState([])
    const [storyBy, setStoryBy] = useState([])
    const [Music, setMusic] = useState([])
    function CelebritiesFetchFunction(_celebrities, _role) {
        const data = _celebrities.find(e => e.name === (JSON.parse(datas.castAndCrew)[_role]))
        switch (_role) {
            case 'dir':
                if (data !== undefined) {
                    setDirector(data)
                } else {
                    setDirector([])
                }
                break;
            case 'scr':
                if (data !== undefined) {
                    setScreenPlay(data)
                } else {
                    setScreenPlay([])
                }
                break;
            case 'pro':
                if (data !== undefined) {
                    setProducer(data)
                } else {
                    setProducer([])
                }
                break;
            case 'wri':
                if (data !== undefined) {
                    setWritter(data)
                } else {
                    setWritter([])
                }
                break;
            case 'cin':
                if (data !== undefined) {
                    setCinimograpy(data)
                } else {
                    setCinimograpy([])
                }
                break;
            case 'edi':
                if (data !== undefined) {
                    setEditor(data)
                } else {
                    setEditor([])
                }
                break;
            case 'sto':
                if (data !== undefined) {
                    setStoryBy(data)
                } else {
                    setStoryBy([])
                }
                break;
            case 'mus':
                if (data !== undefined) {
                    setMusic(data)
                } else {
                    setMusic([])
                }
                break;
            default:
                break;
        }

    }
    const [selectedMovie, setSelectedMovie] = useState(0)
    const [starCountSelected, setStarCountSelected] = useState(-1);
    const [showStarRating, setShowStarRating] = useState(false)
    const [starCount, setStarCount] = useState(-1)
    function MovieRatingFunction(e) {
        if (user === null) {
            alert('Login Do First')
        }
        else {
            setSelectedMovie(e.id)
            setStarCountSelected(MovieRating !== null ?MovieRating.findIndex(e1 => e1.i == e.id) != -1 ? MovieRating.find(e1 => e1.i == e.id).r - 1 : -1:-1)
            setShowStarRating(true)
        }
    }
    const notify = async (e) => {
        setShowStarRating(false)
        ChangeTheRatingFunction(e)
        toast("Thank You For " + (e) + ' star Rating', { autoClose: true, pauseOnHover: false })
        try {
            let temp = []
            if (userInfo.movieRating === null) {
                temp.push({
                    i: selectedMovie,
                    r: e
                })
            }
            else if (userInfo.movieRating !== null) {
                temp = [...JSON.parse(userInfo.movieRating)]
                const result = temp.findIndex(e => e.i === selectedMovie)
                if (result === -1) {
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                } else {
                    temp.splice(result, 1)
                    temp.push({
                        i: selectedMovie,
                        r: e
                    })
                }
            }
            await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/user/' + PNumber, {
                movieRating: JSON.stringify(temp)
            }).then((res) => {
                console.log(res.data);
                FetchingUserDetailsFromAPi(PNumber)
                setStarCountSelected(-1)
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    };
    async function ChangeTheRatingFunction(rating) {
        try {
            let temp = []
            if (datas.rating === null) {
                temp.push({
                    i: userInfo.id,
                    r: rating
                })
            }
            else if (datas.rating !== null) {
                temp = [...JSON.parse(datas.rating)]
                const result = temp.findIndex(e => e.i === userInfo.id)
                if (result === -1) {
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                } else {
                    temp.splice(result, 1);
                    temp.push({
                        i: userInfo.id,
                        r: rating
                    })
                }
            }
            await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/movies/${selectedMovie}`, {
                rating: JSON.stringify(temp)
            }).then((res) => {
                UpdateMovieLocally(selectedMovie, JSON.stringify(temp))
            }).catch((error) => {
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    function UpdateMovieLocally(_id, _obj) {
        let temp=datas
        temp.rating = _obj;
        datas=temp;
    }
    const [userInfo, setUserInfo] = useState('')
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            // console.log(JSON.parse(response.data[0].movieRating))
            setMovieRating(JSON.parse(response.data[0].movieRating));
            setUserInfo(response.data[0])
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        window.addEventListener('click', (e) => {
            if (e.target.id !== 'Rating') {
                setShowStarRating(false)
            }
        })
    }, [])

    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]

    const UserEmpty = {
        "name": "Vijay",
        "categoryId": "[]",
        "description": "Chennai",
        "profilePictureUrl": "",
        "galleryPictureUrls": "",
        "movieIds": "[]",
        "awardsIds": "[]",
        "tagIds": "[]",
        "personalInformation": "{\"born\":\"\",\"alm\":\"\",\"occ\":\"\",\"yea\":\"\",\"wor\":\"\",\"spo\":\"\",\"chi\":\"\",\"fam\":\"\",\"hon\":\"\",\"fac\":\"\",\"ins\":\"\",\"you\":\"\"}",
        "newFeedIds": null,
        "webseriesIds": null
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const [ExpantBanner, setExpantBanner] = useState(false)
    function ReturnRating(params) {
        if(params!=null){
            let tempValue = 0
            JSON.parse(params)?.forEach(e => tempValue += e.r);
            return (tempValue / JSON.parse(params).length)==0.0?0:(tempValue / JSON.parse(params).length).toFixed(1)
        }
        return 0
    }
    return (
        <div className='w-full flex flex-col h-[100vh] overflow-auto'>
            <Header props={{ selected: "Movies" }} />
            <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'black' }} />
            {
                showStarRating &&
                <div id='Rating' className='w-full md:w-1/2 lg:w-1/3 absolute self-center z-[9999] top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
                    <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
                    <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
                    <img onClick={() => setShowStarRating(false)} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
                    <h4 className='text-white text-center md:mt-[15%] mt-[20%] font-normal'>Rate This</h4>
                    <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' onMouseLeave={() => setStarCount(-1)}>
                        {
                            stars.map((e, index) =>
                                <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                                    className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                            )
                        }
                    </div>
                    <div onClick={() => notify(starCountSelected + 1)} style={{ borderWidth: 1 }}
                        className='hover:text-black text-white hover:bg-[#24baef] rounded-md duration-300 p-1 hover:cursor-pointer w-[80%] bg-primary_colorlg:bg-transparent lg:w-[40%] mt-[5%] flex flex-col justify-center items-center'>
                        <h4 className=' tracking-wide'>Rate</h4>
                    </div>
                </div>
            }
            <div className='relative'>
                <div onClick={() => ExpantBanner ? setExpantBanner(false) : navigate('/Movies')} className='absolute  hover:-translate-x-3 hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] mx-10 my-5 z-[99999]'>
                    <FaArrowLeftLong />
                    <h5 className='font-normal text-[14px]'>Back</h5>
                </div>
            </div>
            <div className='w-full '>
                {/* <div className='relative w-full h-[60vh] sm:block hidden'>
                    <img onClick={() => setExpantBanner(!ExpantBanner)} className={`w-full ${ExpantBanner ? 'lg:z-[999]' : 'h-[60vh]'} absolute object-cover object-top`} src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + (JSON.parse(datas.galleryPicturesUrl)[0])} />
                    <div onClick={() => setExpantBanner(!ExpantBanner)} className='w-full bg-gradient-to-t from-[#131722] h-[50%] to-transparent bottom-0 absolute'></div>
                </div> */}
                <div className={`w-full bg-gradient-to-t  from-[#131722] ${ExpantBanner ? 'hidden' : ''} to-[#131722] `}>
                    <div className='flex flex-row mx-auto max-w-[2000px] px-2 justify-center items-start'>
                        <div className='w-[170px] hidden lg:flex relative flex-col mt-2'>
                            {LeftBanner.length > 0 && <BannerComponent sectionId={LeftBanner[0].sectionId} internal={LeftBanner[0].internal} redirectUrl={LeftBanner[0].redirectUrl} id={LeftBanner[0].id} ImageUrl={LeftBanner[0].bannerUrl} />}
                        </div>
                        <div className='container mx-auto sm:px-3' >
                            <div className='flex flex-col lg:flex-row w-full gap-x-5 justify-between mt-2 lg:mt-0 mb-2'>
                                <div className='w-full lg:w-[800px]  relative flex flex-col '>
                                    <img className='w-[290px] lg:flex rounded-md flex-col h-[400px] hidden mt-2 object-fill ' src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + (JSON.parse(datas.thumbnailPictureUrl)[0])} alt='' />
                                    <div className='lg:hidden block relative w-full h-[400px]'>
                                        <img className='w-full h-[400px] object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/movies/' + datas.id + '/' + (JSON.parse(datas.thumbnailPictureUrl)[0])} alt='' />
                                        <div className='absolute w-full h-[50%] bottom-0 bg-gradient-to-t from-[#131722] to-transparent'></div>
                                    </div>
                                    {
                                        new Date() < new Date(datas.releaseDate) ? null :
                                            <div className='absolute bottom-1 rounded-md lg:w-[290px] flex flex-col px-2 py-2 gap-3 '>
                                                <div className='flex flex-row gap-x-2 items-center '>
                                                    <img className='w-[18px] object-contain' src={Star} alt='' />
                                                    <h6 className='text-gray-200 font-normal cursor-default text-sm'>{ReturnRating(datas.rating)}/10</h6>
                                                    <img onClick={() => MovieRatingFunction(datas)} id='Rating' className='w-[20px] cursor-pointer object-contain' src={MovieRating != null ? MovieRating.findIndex(e1 => e1.i == datas.id) != -1 ? Star : starnotfill : starnotfill} alt='' />
                                                    <h6 className='text-gray-200 font-normal cursor-default text-sm'>{MovieRating != null ? MovieRating.findIndex(e1 => e1.i == datas.id) != -1 ? MovieRating.find(e1 => e1.i == datas.id).r + '/10' : null : null}</h6>
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className='w-full relative flex flex-col gap-y-4 py-2'>
                                    <h1 className='text-white lg:text-4xl  text-xl hover:cursor-default font-bold tracking-wider'>{datas.name}</h1>
                                    <div className='flex flex-row gap-x-3 '>
                                        <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{new Date(datas.releaseDate).getUTCFullYear()}</p>
                                        <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                        <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{datas.duration == 0 ? 'NA' : Math.floor(datas.duration / 60) + ' hr ' + datas.duration % 60 + ' mins'}</p>
                                        <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p>
                                        <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{datas.certification}</p>
                                        {genre != undefined ? <p className='border-r-2 xl:block hidden border-gray-700 hover:cursor-default '></p> : null}
                                        <p className='text-gray-200 text-[12px] xl:text-[15px] font-normal hover:cursor-default '>{genre}</p>
                                    </div>
                                    {/* <p className={`text-base mb-5 text-gray-100 ${ShowmoreOption ? 'h-auto' : 'lg:h-[100px] xl:h-[150px]'} custom-scrollbar overflow-y-auto`}>{ShowmoreOption ? datas.description.substr(0, 160) : datas.description}</p>
                                {ShowmoreOption ?datas.description.length>170? <label onClick={() => setShowmoreOption(false)} className='text-gray-200 text-sm w-max self-end text-end'>Show More</label>:null :
                                    <label onClick={() => setShowmoreOption(true)} className='text-gray-200 text-sm w-max self-end text-end'>Show Less</label>} */}
                                    <p className={`xl:text-base text-sm hover:cursor-default  text-gray-300 ${datas.description.length < 450 ? 'h-auto' : 'xl:h-[190px] h-[140px]'}  custom-scrollbar overflow-y-auto`}>{datas.description}</p>
                                    <div className='mb-2 flex flex-col lg:flex-row justify-start gap-x-10'>
                                        {JSON.parse(datas.castAndCrew).dir !== '-' ? <div className='space-y-1'>
                                            <label onClick={() => director.length !== 0 ? navigate("/Actorinfo", { state: { data: director } }) : null} className='text-white  hover:text-white line-clamp-1 tracking-wide xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).dir.length > 18 ? JSON.parse(datas.castAndCrew).dir.substr(0, 18) + '...' : JSON.parse(datas.castAndCrew).dir}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default tracking-wider'>Director</div>
                                        </div> : null}
                                        {JSON.parse(datas.castAndCrew).scr !== '-'&&JSON.parse(datas.castAndCrew).scr!=''? <div className='space-y-1'>
                                            <label onClick={() => screenPlay.length !== 0 ? navigate("/Actorinfo", { state: { data: screenPlay } }) : null} className='text-white hover:text-white  tracking-wide  xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).scr.length > 18 ? JSON.parse(datas.castAndCrew).scr.substr(0, 18) + '...' : JSON.parse(datas.castAndCrew).scr}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Screenplay</div>
                                        </div> : null}
                                        {JSON.parse(datas.castAndCrew).pro !== '-' ? <div className='space-y-1'>
                                            <label onClick={() => producer.length !== 0 ? navigate("/Actorinfo", { state: { data: producer } }) : null} className='text-white hover:text-white  tracking-wide line-clamp-1  xl:text-base text-sm'>{JSON.parse(datas.castAndCrew).pro}</label>
                                            <div style={{ fontSize: 13 }} className='text-gray-200 hover:cursor-default  tracking-wider'>Producer</div>
                                        </div> : null}
                                    </div>
                                </div>
                                <div className='w-full flex  py-3 flex-col '>
                                    <ReactPlayer
                                        width="100%"
                                        url={`https://www.youtube.com/watch?v=${datas.youtubeLink}`}
                                        loop={true}
                                        muted={false}
                                        // light={true}
                                        controls={true}
                                    />
                                </div>
                            </div>
                            <div className='lg:flex lg:flex-row '>
                                <div className=' xl:w-[35%] '>
                                    {
                                        ottplatform.length > 0 && ottplatform.map((e, index) =>
                                            <div key={index} className='flex flex-row items-center mb-2  bg-[#1c212e] pl-5  rounded-lg gap-4 '>
                                                <img className='w-[80px] h-[60px] my-2 cursor-pointer hover:scale-105 duration-300 rounded-md' src={'https://storage.googleapis.com/cinimini-console/ottPlatforms/' + e.iconUrl} />
                                                <div className='space-y-1'>
                                                    <label onClick={() => navigate("/AboutOttPlatforms", { state: { data: e } })} className='text-white font-bold tracking-widest'>{e.name}</label>
                                                    <h6 className='text-gray-300 tracking-widest hover:cursor-default'>streaming on {e.name}</h6>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className='bg-[#1c212e] space-y-6 p-5 mb-2 rounded-md'>
                                        {JSON.parse(datas.castAndCrew).wri !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Written by: <label className='text-white text-left break-all w-[50%]  text-sm lg:text-base' onClick={() => writter.length !== 0 ? navigate("/Actorinfo", { state: { data: writter } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).wri, desc: 'Writter' } })}>{JSON.parse(datas.castAndCrew).wri}</label> </p> : null}
                                        {JSON.parse(datas.castAndCrew).cin !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Cinematography: <label className='text-white text-left w-[50%] break-all text-sm lg:text-base' onClick={() => cinimograpy.length !== 0 ? navigate("/Actorinfo", { state: { data: cinimograpy } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).cin, desc: 'Cinematography' } })}>{JSON.parse(datas.castAndCrew).cin}</label></p> : null}
                                        {JSON.parse(datas.castAndCrew).edi !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Edited by: <label className='text-white text-sm text-left w-[50%] break-all lg:text-base' onClick={() => editor.length !== 0 ? navigate("/Actorinfo", { state: { data: editor } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).edi, desc: 'Editor' } })}>{JSON.parse(datas.castAndCrew).edi}</label></p> : null}
                                        {JSON.parse(datas.castAndCrew).mus !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Music by: <label className='text-white text-sm text-left w-[50%] break-all lg:text-base' onClick={() => Music.length !== 0 ? navigate("/Actorinfo", { state: { data: Music } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).mus, desc: 'Music Specialist' } })}>{JSON.parse(datas.castAndCrew).mus}</label></p> : null}
                                        {JSON.parse(datas.castAndCrew).sto !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Story by: <label className='text-white text-sm text-left w-[50%] break-all lg:text-base' onClick={() => storyBy.length !== 0 ? navigate("/Actorinfo", { state: { data: storyBy } }) : navigate("/Actorinfo", { state: { data: UserEmpty, name1: JSON.parse(datas.castAndCrew).sto, desc: 'Story Writter' } })}>{JSON.parse(datas.castAndCrew).sto}</label></p> : null}
                                        {JSON.parse(datas.castAndCrew).dia !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm  hover:cursor-default lg:text-base'>Dialogues by: <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{JSON.parse(datas.castAndCrew).dia}</p></p> : null}
                                        {JSON.parse(datas.castAndCrew).pro !== '-' ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Produced by: <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{JSON.parse(datas.castAndCrew).pro}</p></p> : null}
                                        <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Production company:<p className='text-white text-left w-[50%] break-all text-sm lg:text-base'>{JSON.parse(datas.castAndCrew).proc}</p></p>
                                        <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Release Year: <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                        <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Running Time: <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{datas.duration == 0 ? 'NA' : Math.floor(datas.duration / 60) + ' hr ' + datas.duration % 60 + ' mins'}</p></p>
                                        <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Language:  <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{JSON.parse(datas.castAndCrew).lang}</p></p>
                                        {datas.budget !== 0 ? <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Budget: <p className='text-white text-sm text-left w-[50%] break-all lg:text-base'>{datas.budget} cr</p></p> : null}
                                        <p className='flex flex-row w-[100%] justify-between gap-x-2 text-sm hover:cursor-default lg:text-base'>Release date: <p className='text-white text-left w-[50%] break-all text-sm lg:text-base'>{new Date(datas.releaseDate).getUTCDate() + "-" + (Number(new Date(datas.releaseDate).getUTCMonth()) + 1) + "-" + new Date(datas.releaseDate).getUTCFullYear()}</p></p>
                                    </div>

                                </div>
                                <div className='w-full lg:flex  flex-col mt-2 lg:mt-0'>
                                    <div className='lg:pl-4 px-3 w-[99%] lg:ml-3 rounded-lg lg:h-[60px] h-[80px]  flex flex-row items-center bg-[#1c212e] justify-between text-white'>
                                        <div className='flex flex-row md:gap-10 md:w-auto w-full justify-between' >
                                            <label style={{ color: selected === "Actors" ? "#24baef" : "" }} className='text-sm lg:text-base hover:text-[#24baef] ' onClick={() => OnClickActors()}>Cast</label>
                                            <label style={{ color: selected === "trailer" ? "#24baef" : "" }} className='text-sm lg:text-base hover:text-[#24baef]' onClick={() => OnClickTrailer()}>Trailer</label>
                                            <label style={{ color: selected === "video" ? "#24baef" : "" }} className='text-sm lg:text-base hover:text-[#24baef]' onClick={() => OnClickVideo()}>Video</label>
                                            <label style={{ color: selected === "film" ? "#24baef" : "" }} className='text-sm lg:text-base hover:text-[#24baef]' onClick={() => OnClickFilmography()}>Awards</label>
                                            <label style={{ color: selected === "news" ? "#24baef" : "" }} className='text-sm lg:text-base hover:text-[#24baef]' onClick={() => OnClickNews()}>News</label>
                                        </div>
                                    </div>
                                    <div className='mt-2 '>
                                        {
                                            actors === true ?
                                                <Actors props={{ movieCelebrities: movieCelebrities }} /> : null
                                        }
                                        {
                                            trailer === true ?
                                                <Trailer data={datas.youtubeLink} /> : null
                                        }
                                        {
                                            video === true ?
                                                <Videos props={{ videosCollection: MovieVideos }} /> : null
                                        }
                                        {
                                            filmography === true ?
                                                <div className=' rounded-lg lg:mx-3 h-[500px] overflow-y-auto custom-scrollbar'>
                                                    {
                                                        awardsData.length > 0 ? awardsData.map((e, index) =>
                                                            <div key={index} className='w-full py-2 rounded-sm justify-center flex flex-col border-b-[1px] border-b-gray-600'>
                                                                <div className=' flex flex-row '>
                                                                    <div className='lg:w-[10%] flex flex-col justify-center items-center'>
                                                                        <img className='w-[81px] hover:cursor-pointer h-[81px] rounded-md' src={awards} />
                                                                        <h5 className='text-gray-300 hover:cursor-default text-center font-normal tracking-wide mt-1'>2012</h5>
                                                                    </div>
                                                                    <div className='lg:w-[90%] gap-y-2 flex flex-col mt-5 '>
                                                                        <label className='text-white text-base tracking-wide'>{e.awardsName}</label>
                                                                        <h4 className='text-white hover:cursor-default font-normal text-sm'>{e.name}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : <h5 className='text-gray-300 py-2 text-center font-normal'>No Announcements yet</h5>
                                                    }
                                                </div> : null
                                        }
                                        {
                                            news === true ?
                                                <MovieNews props={{ movieNews: MovieNews1 }} /> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[170px]  hidden lg:flex relative flex-col mt-2'>
                            {RightBanner.length > 0 && <BannerComponent sectionId={RightBanner[0].sectionId} internal={RightBanner[0].internal} redirectUrl={RightBanner[0].redirectUrl} id={RightBanner[0].id} ImageUrl={RightBanner[0].bannerUrl} />}
                        </div>
                    </div>
                </div>
            </div>
            {ExpantBanner ? null : <Footer />}
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default AboutMovie;


