import React, { useState } from 'react'
import Star from "../Assets/Star.png";
import Close from '../Assets/Close.png'
import starnotfill from "../Assets/starnotfill.png";
function OpenStarRatingBox({HandleStarRatingFunctionForClose,CurrentRating}) {
    const [starCount, setStarCount] = useState(-1)
    const [starCountSelected, setStarCountSelected] = useState(CurrentRating-1);
    const stars = [starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill, starnotfill]
    return (
        <div className='w-full md:w-1/2 lg:w-1/3 absolute z-[99] top-[30vh] bg-[#1c212e] rounded-md h-1/3 flex flex-col  items-center'>
            <img className='w-[30%] h-[30%] hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute -top-14' src={Star} />
            <h2 className='text-white absolute -mt-4 z-50'>{starCountSelected > -1 ? starCountSelected + 1 : 0}</h2>
            <img onClick={() => HandleStarRatingFunctionForClose()} className='w-[6%] h-[6%]  mx-2 hover:scale-125 hover:cursor-pointer duration-300 object-contain mt-2 absolute right-0' src={Close} />
            <h4 className='text-white text-center md:mt-[15%] mt-[20%]'>Rate This</h4>
            <div id='Rating' className='flex flex-row justify-center items-center gap-x-2 mx-5 mt-[5%] ' >
                {
                    stars.map((e, index) =>
                        <img id='Rating' onPointerLeave={() => setStarCount(-1)} onMouseEnter={() => setStarCount(index)} onClick={() => setStarCountSelected(index)} key={index}
                            className={`cursor-pointer w-1/12 xl:w-[5%]`} src={starCount > -1 ? (index <= starCount ? Star : e) : (index <= starCountSelected ? Star : e)} />
                    )
                }
            </div>
            <div style={{ borderWidth: 1 }}
                className='hover:text-black text-white hover:bg-[#24baef] duration-300 p-1 hover:cursor-pointer w-[80%] hover:border-0 lg:bg-transparent lg:w-[40%] mt-[5%] rounded-md flex flex-col justify-center items-center'>
                <h4 className=' tracking-wide text-base'>Rate</h4>
            </div>
        </div>
    )
}

export default OpenStarRatingBox