import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyD8DtrGwYoFKJlTEDdujchbM8Cuq6S7ySY",
  authDomain: "cinimini-console.firebaseapp.com",
  projectId: "cinimini-console",
  storageBucket: "cinimini-console.appspot.com",
  messagingSenderId: "850545917297",
  appId: "1:850545917297:web:05394172552fac1bfc0ad0",
  measurementId: "G-96L9VP80XE"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app);