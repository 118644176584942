import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import playcircle from "../Assets/playcircle.png";
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function FullWidthBanner({ sectionId, internal, Imageurl, Closebanner, YoutubeLink, id, redirectUrl }) {
    const [ShowYoutube, setShowYoutube] = useState(false)
    let path = sectionId == 4 ? "webseries" : sectionId == 3 ? "movies" : sectionId == 2 ? "celebrities" : sectionId == 1 ? "news" : null
    useEffect(() => {
        if (internal !== 0) {
            FetchingWebseries()
        }
    }, [])
    const [data, setData] = useState([])
    async function FetchingWebseries() {
        const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specific/${path}/${redirectUrl}`)
        if (response.status == 200) {
            setData(response.data[0])
            console.log(response.data);
        }
    }

    const navigate = useNavigate()
    return (
        <div className=' m-auto lg:max-w-[1680px] flex flex-col h-[250px] md:h-[500px] lg:h-[600px] xl:h-[700px] overflow-hidden relative rounded-md border-[4px] border-white '>
            <div>
                {internal == 0 ? <a href={redirectUrl != '-' ? redirectUrl : null}>
                    <img className='lg:w-[1672px]  w-[100%] h-full lg:h-[600px] 2xl:h-[700px] object-fill object-top absolute top-0' src={`https://storage.googleapis.com/cinimini-console/advertisements/${id}/${Imageurl}`}></img>
                </a> :
                    <div onClick={() => navigate(`${sectionId == 4 ? "/AboutWebseries" : sectionId == 3 ? "/AboutMovie" : sectionId == 2 ? "/ActorInfo" : sectionId == 1 ? "/AnoutNews" : null}`, { state: { data: data } })}>
                        <img className='lg:w-[1672px]  w-[100%] h-full lg:h-[600px] 2xl:h-[700px] object-fill object-top absolute top-0' src={`https://storage.googleapis.com/cinimini-console/advertisements/${id}/${Imageurl}`}></img>
                    </div>}
            </div>
            <h5 className='text-white font-normal z-[999] absolute m-2'>Ad</h5>
            <div className='p-1 rounded-full bg-[#0c0e17] w-6 h-6 z-[99] flex flex-col self-end m-2'>
                <FaTimes onClick={() => Closebanner()} className='w-4 h-4 text-white cursor-pointer' />
            </div>

            <img onClick={() => setShowYoutube(true)} className='lg:h-[100px] lg:w-[100px] h-[35px] w-[35px] opacity-50 hover:opacity-100 duration-150 z-[99] absolute top-[45%] left-[50%] lg:top-[45%] lg:left-[50%] cursor-pointer' src={playcircle} />
            {
                ShowYoutube ?
                    <div className='w-full h-full rounded-md z-[999]'>
                        <ReactPlayer
                            width="100%"
                            height='100%'
                            url={`https://www.youtube.com/watch?v=${YoutubeLink}`}
                            playing={true}
                            controls={true}
                        />
                    </div> : null
            }
        </div>
    )
}

export default FullWidthBanner