import React, { useEffect, useState } from 'react';
import Logo from "../Image/HeaderLogo.jpg";
import UnCheck from "../Image/CheckBoxOff.png";
import closeblack from "../Assets/closeblack.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from './FirebaseInit';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Login() {
    const [selected, setSelected] = useState("");
    const [emailError, setEmailError] = useState("");

    const navigate = useNavigate();
    const location = useLocation()
    const [phoneNumber, setPhoneNumber] = useState("");

    const IsValidEmail = (_email) => {
        if (_email.length > 0) {
            if (_email.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                return true
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    const [loginSwap, setloginSwap] = useState(false)
    const [registerName, setRegisterName] = useState("")
    const [registerEmail, setRegisterEmail] = useState("")
    const [registerPass, setRegisterPass] = useState("")
    const [registerNumber, setRegisterNumber] = useState("")
    const [registerAddress, setRegisterAddress] = useState("")

    const [user, setUser] = useState(null)
    async function PhoneNumberAuthentication() {
        if (phoneNumber.length === 10) {
            try {
                const recapcha = new RecaptchaVerifier(auth, 'recaptcha-container', {})
                const num = "+91 " + phoneNumber
                console.log(num);
                const confirmation = await signInWithPhoneNumber(auth, num, recapcha)
                    .then((responce) => {
                        console.log(responce);
                        console.log(confirmation);
                        setUser(confirmation)
                    }).catch((error) => {
                        console.log(error.message);
                    })


            } catch (error) {
                console.log(error);
            }
        }
        else {
            toast('Invalid Mobile Number', { containerId: 'id2' })
        }
    }
    const [otp, setotp] = useState("")
    function VerifyOTP() {
        try {
            user.confirm(otp)
                .then((res) => {
                    console.log(res);
                    notify()
                    navigate(-1)
                }).catch((error) => {
                    console.log(error);
                    toast(error.message, { containerId: 'id2' })
                })
        } catch (error) {
            console.log(error.messgae);
            toast(error.message, { containerId: 'id2' })
        }
    }
    function notify() {
        toast("Login Successfully Done", { autoClose: true, pauseOnHover: false, containerId: 'id1' })
    }
    console.log(location.state.path);
    return (
        <div className='bg-[#131722] w-full h-[100vh] overflow-y-auto'>
            <Header props={{ selected: "Login" }} />
            <ToastContainer containerId='id1' position='top-center' toastStyle={{ backgroundColor: 'green', color: 'black' }} />
            <ToastContainer containerId='id2' position='top-center' toastStyle={{ backgroundColor: 'red', color: 'white' }} />
            <div className='container  px-2 m-auto flex  flex-col items-center mb-[10%]'>
                <div className='w-[30px] h-[30px] bg-white self-end mb-[5%] mt-[2%] rounded-full hover:cursor-pointer p-1'>
                    <img src={closeblack} onClick={() => navigate(-1)} className=' ' />
                </div>
                {
                    loginSwap == false ? (
                        <div className='gap-8 lg:border-[1px] rounded-md flex flex-col justify-center items-center bg-white w-[40vh] py-10 px-5'>
                            {
                                user === null ? (
                                    <div className='flex flex-col justify-center items-center gap-y-5'>
                                        <div>
                                            <img src={Logo} alt='' />
                                        </div>
                                        <div className='flex flex-col gap-2'>
                                            <h2 className='h2'>LOGIN</h2>
                                        </div>
                                        <div className='flex flex-col gap-y-5 w-full '>
                                            <div className='flex flex-col gap-2 '>
                                                <p>Phone Number</p>
                                                <input className='py-3 px-5 rounded-md ' maxLength={10} style={{ border: selected === "Phone" ? "2px solid #000000" : emailError.length > 0 ? "1px solid #FF0000" : "2px solid #E8E8E8", outline: 0, color: selected === "Phone" ? "#000000" : "" }}
                                                    onChange={(e) => setPhoneNumber(e.target.value)} onPointerEnter={() => setSelected("Phone")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Phone Number' />
                                                <p className='text-red-600'>{emailError}</p>
                                            </div>
                                            <div id='recaptcha-container' style={{ marginTop: '10px' }}></div>
                                            <div onClick={() => PhoneNumberAuthentication()} className='flex flex-col w-full gap-2 input py-3 rounded-full border-[#000000] bg-black hover:bg-[#EE961A] hover:border-[#EE961A] duration-300'>
                                                <button className='text-white font-semibold' >SEND OTP</button>
                                            </div>
                                        </div>
                                    </div>) : (
                                    <div className='flex flex-col gap-y-5 w-full '>
                                        <div className='flex flex-col gap-2'>
                                            <p>OTP</p>
                                            <input onChange={(text) => setotp(text.target.value)} className='py-3 px-5 rounded-md' style={{ border: selected === "Password" ? "2px solid #000000" : "2px solid #E8E8E8", outline: 0, color: selected === "Password" ? "#000000" : "" }}
                                                onPointerEnter={() => setSelected("Password")} onPointerLeave={() => setSelected(null)} placeholder='Enter the OTP' />
                                        </div>
                                        <div onClick={() => VerifyOTP()} className='flex flex-col w-full gap-2 input py-3 rounded-full border-[#000000] bg-black hover:bg-[#EE961A] hover:border-[#EE961A] duration-300'>
                                            <button className='text-white font-semibold' >Enter OTP</button>
                                        </div>
                                    </div>)
                            }
                            <div className='flex flex-row items-center'>
                                <h5>Not registered yet ? </h5>
                                <label onClick={() => setloginSwap(true)} className='text-red-500 font-semibold'> create an account</label>
                            </div>
                        </div>
                    ) : <div className='gap-8 lg:border-[1px] rounded-md lg:shadow-lg  flex flex-col justify-center items-center bg-white lg:px-28 px-10 lg:py-5'>
                        <div>
                            <img src={Logo} alt='' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <h2 className='h2'>Register</h2>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Name</p>
                            <input className='py-3 px-5' style={{ border: selected === "RegisterName" ? "2px solid #000000" : "2px solid #E8E8E8", outline: 0, color: selected === "RegisterName" ? "#000000" : "" }}
                                onChange={(e) => setRegisterName(e.target.value)} onPointerEnter={() => setSelected("RegisterName")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Name' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Email</p>
                            <input className='py-3 px-5' style={{ border: selected === "RegisterEmail" ? "2px solid #000000" : emailError.length > 0 ? "2px solid #FF0000" : "2px solid #E8E8E8", outline: 0, color: selected === "RegisterEmail" ? "#000000" : "" }}
                                onChange={(e) => setRegisterEmail(e.target.value)} onPointerEnter={() => setSelected("RegisterEmail")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Email' />
                        </div>
                        {/* <div className='flex flex-col gap-2'>
                        <p>Password</p>
                        <input className='py-3 px-5' style={{ border: selected === "RegisterPassword" ? "2px solid #000000" : "2px solid #E8E8E8", outline: 0, color: selected === "RegisterPassword" ? "#000000" : "" }}
                           onChange={(e) => setRegisterPass(e.target.value)} onPointerEnter={() => setSelected("RegisterPassword")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Password' />
                    </div> */}
                        <div className='flex flex-col gap-2'>
                            <p>Phone Number</p>
                            <input className='py-3 px-5' style={{ border: selected === "RegisterNumber" ? "2px solid #000000" : "2px solid #E8E8E8", outline: 0, color: selected === "RegisterNumber" ? "#000000" : "" }}
                                onChange={(e) => setRegisterNumber(e.target.value)} onPointerEnter={() => setSelected("RegisterNumber")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Phone Number' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Address</p>
                            <input className='py-3 px-5' style={{ border: selected === "RegisterAddress" ? "2px solid #000000" : "2px solid #E8E8E8", outline: 0, color: selected === "RegisterAddress" ? "#000000" : "" }}
                                onChange={(e) => setRegisterAddress(e.target.value)} onPointerEnter={() => setSelected("RegisterAddress")} onPointerLeave={() => setSelected(null)} placeholder='Enter the Address' />
                        </div>
                        {/* <div className='flex flex-row  gap-14'>
                        <div className='flex flex-row gap-2 items-center'>
                            <img className='Icons' src={UnCheck} alt='' />
                            <h5>Remember Me</h5>
                        </div>
                        <div className='flex flex-row gap-2 items-center'>
                            <h5 className='underline cursor-pointer'>Forgot Password?</h5>
                        </div>
                    </div> */}
                        <div className='flex flex-col w-full gap-2 input py-3 rounded-full border-[#000000] bg-black hover:bg-[#EE961A] hover:border-[#EE961A] duration-300'>
                            <button className='text-white font-semibold'>Register</button>
                        </div>
                        <div className='flex flex-row items-center'>
                            <h5>Do you have an account ? </h5>
                            <label onClick={() => setloginSwap(false)} className='text-red-500 font-semibold'> Sign In</label>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Login
