import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import usercircle from '../Assets/usercircle.png'
import age from '../Assets/age.png'
import phoneicon from '../Assets/phoneicon.png'
import email from '../Assets/email.png'
import point from '../Assets/point.png'
import joindate from '../Assets/joindate.png'
import address from '../Assets/address.png'
import editicon from '../Assets/editicon.png'
import update from '../Assets/update.png'
import birthday from '../Assets/birthday.png'
import cameraphoto from '../Assets/cameraphoto.png'
import axios from 'axios'
import Profile from '../Assets/Profile.jpg'
import Header from './Header'
import { MdDeleteForever } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { FaArrowLeftLong } from 'react-icons/fa6'
import BannerComponent from './BannerComponent'
import { FaAngleDown } from 'react-icons/fa'
import { GetAdvertisements } from '../Application/Api'
import Footer from './Footer'
export default function Profilepage() {
    useEffect(() => {
        FetchingAdvertisements()
    }, [])
    const [Square, setSquare] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const square = data.filter((e) => e.destination == 2 && e.placement == 6)
            setSquare(square)
            const bottombann = data.filter((e) => e.destination == 2 && e.placement == 3)
            setBottomBanner(bottombann)
        }
    }
    const location = useLocation()
    const data1 = location.state.userInfo;
    console.log(data1);
    const [data, setdata] = useState(data1)
    const [userName, setUserName] = useState('')
    const [Name, setName] = useState('')
    const [MobileNumber, setMobileNumber] = useState('')
    const [Email, setEmail] = useState('')
    const [createdDate, setCreatedDate] = useState('')

    useEffect(() => {
        StroingData()
    }, [])
    function StroingData() {
        setName(data.name)
        setMobileNumber(data.mobileNumber)
        setImagePick(data.profilePictureUrl)
        setEmail(data.email)
        setCreatedDate(data.dateOfCreation)
        setUserName(data.userName)
    }

    async function UpdatethePhotoInDb(params) {
        try {
            const res =
            {
                profilePictureUrl: params
            }
            const response = await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${MobileNumber}`, res)
            if (response.status == 200) {
                console.log(response.data);
                setImagePick(params)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [ImagePick, setImagePick] = useState(null)
    async function AddToStorageBucket(params) {
        try {
            let formdata = new FormData()
            formdata.append("file", params)
            axios.post(`https://cinimini-apis-3nugbllihq-el.a.run.app/upload/users/nothing`, formdata,
                {
                    headers:
                    {
                        "Content-Type": 'multipart/form-data'
                    }
                }).then((responce) => {
                    if (responce.status == 200) {
                        console.log(responce.data);
                        FetchingUserDetailsFromAPi(data.mobileNumber)
                        UpdatethePhotoInDb(params.name)
                        setImagePick(params.name)
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }
    async function FetchingUserDetailsFromAPi(Number) {
        try {
            await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${Number}`)
                .then((response) => {
                    console.log(response.data[0]);
                    setName(response.data[0].name)
                    setEmail(response.data[0].email)
                }).catch((error) => {
                    console.log(error);
                })
        } catch (error) {
            console.log(error.message);
        }
    }
    async function UpdateTheProfile() {
        try {
            const res =
            {
                name: Name,
                email: Email,
                userName: userName
            }
            const response = await axios.patch(`https://cinimini-apis-3nugbllihq-el.a.run.app/user/${data.mobileNumber}`, res)
            if (response.status == 200) {
                FetchingUserDetailsFromAPi(MobileNumber)
                setNowYouCanEdit(false)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [NowYouCanEdit, setNowYouCanEdit] = useState(false)
    const returnTheReleaseDate = (_date) => {
        let year = new Date(_date).getUTCFullYear();
        let month = _date.toLocaleDateString("en-US", { month: 'long' })
        let date =
            new Date(_date).getUTCDate().toString().length < 2
                ? new Date(_date).getUTCDate().toString().padStart(2, 0)
                : new Date(_date).getUTCDate().toString();
        let final = date + " " + month + " " + year;
        console.log(final);
        return final;
    };
    function DeletePhotoFromDb(_image) {
        if (_image !== '')
            try {
                axios.delete(`https://cinimini-apis-3nugbllihq-el.a.run.app/files/users/nothing/${_image}`)
                    .then((response) => {
                        if (response.status == 200) {
                            UpdatethePhotoInDb('')
                        }
                    })

            } catch (error) {
                console.log(error);
            }
    }
    const navigate = useNavigate();
    const [BottomBannerState, setBottomBannerState] = useState(true)
    return (
        <div className=' flex flex-col h-[100vh] overflow-y-auto lg:py-0 w-full bg-[#131722]'>
            <Header props={'Profilepage'} />
            <div className='relative'>
                <div onClick={() => navigate(-1)} className='absolute  hover:-translate-x-3 hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] mx-10 my-5 z-[99999]'>
                    <FaArrowLeftLong />
                    <h5 className='font-normal text-[14px]'>Back</h5>
                </div>
            </div>
            <div className='w-full mt-32 gap-x-3 flex flex-row justify-center'>
                {/* <div className='w-[300px] h-[450px] '>

                </div> */}
                <div className='flex md:flex-row flex-col gap-y-5 mb-40'>
                    <div className='xl:w-[300px] w-[300px] border-r-[1px] flex flex-col justify-between py-5 items-center lg:h-[450px] bg-white md:rounded-l-md md:rounded-r-none rounded-md'>
                        <div className='flex flex-col items-center'>
                            <h3 className=' font-extrabold'>{Name}</h3>
                            <h5 className='mb-8 text-gray-700'>{userName.length > 0 ? '@' + userName : userName}</h5>
                            <div className='w-[150px] relative h-[150px] rounded-full bg-gray-100'>
                                <div onClick={() => DeletePhotoFromDb(ImagePick)} className='absolute w-6 h-6 right-4 bg-white rounded-full flex flex-col items-center justify-center my-3'>
                                    <RiDeleteBin6Line className='w-4 h-4 cursor-pointer' />
                                </div>
                                <img className='w-full h-full rounded-full object-cover object-top' src={ImagePick !== '' ? "https://storage.googleapis.com/cinimini-console/users/" + ImagePick : Profile} />
                            </div>
                            {/* <div className='flex flex-row items-center gap-x-4 mt-8'> */}
                            <div className=' w-[110px] flex  flex-col items-center justify-center rounded-sm h-[34px] cursor-pointer mt-8 bg-primary_color text-white' >
                                <h5 className='text-[13px] cursor-pointer font-normal'>Upload Photo</h5>
                                <input onChange={(e) => AddToStorageBucket(e.target.files[0])} className='absolute w-[105px] bg-red-500 cursor-pointer opacity-0' type='file' />
                            </div>

                            {/* </div> */}
                        </div>
                        <div className='flex flex-row items-center gap-x-2 sm:mt-0 mt-10'>
                            <h5 className='text-[12px] font-normal text-gray-500'>Member Since:</h5>
                            <label className='text-[12px] text-black hover:text-black'>{returnTheReleaseDate(new Date(createdDate))}</label>
                        </div>
                    </div>
                    <div className='lg:w-[600px] w-[300px] lg:h-[450px] h-auto bg-white rounded-md md:rounded-r-md md:rounded-l-none'>
                        <div className='flex flex-row items-center justify-between py-5 px-5 w-full '>
                            <div className=' rounded-r-md '>
                                <h2 className=''>Edit Profile</h2>
                            </div>
                            {NowYouCanEdit ? null : <div onClick={() => setNowYouCanEdit(true)} className='px-4 rounded-sm py-2 cursor-pointer bg-primary_color w-max text-white' >
                                <h5 className='text-[13px] font-normal'>Edit Profile</h5>
                            </div>}
                        </div>
                        <h5 className='border-b-2 ml-5 font-extrabold text-gray-700 border-b-primary_color w-max'>User Info</h5>
                        <div className='mt-10 px-5 pb-5 flex lg:flex-row flex-col w-full gap-x-10 items-center justify-between'>
                            <div className='lg:w-[50%] w-full'>
                                <h5 className='text-gray-400 font-normal'>Full Name *</h5>
                                <input onChange={(e) => setName(e.target.value)} disabled={NowYouCanEdit ? false : true} value={Name} className={`border-[1px] ${NowYouCanEdit ? 'text-black' : 'text-gray-400'} w-full text-[14px] border-gray-300 px-2 py-3 outline-none rounded-md mt-4`} type='text'></input>
                            </div>
                            <div className='lg:w-[50%] lg:mt-0 mt-5 w-full'>
                                <h5 className='text-gray-400 font-normal'>User Name *</h5>
                                <input value={userName} onChange={(e) => setUserName(e.target.value)} disabled={NowYouCanEdit ? false : true} className={`border-[1px] ${NowYouCanEdit ? 'text-black' : 'text-gray-400'} w-full text-[14px] border-gray-300 px-2 py-3 outline-none rounded-md mt-4`} type='text' />
                            </div>
                        </div>
                        <div className=' px-5 pb-5 flex lg:flex-row flex-col w-full gap-x-10 items-center justify-between'>
                            <div className='lg:w-[50%] w-full'>
                                <h5 className='text-gray-400 font-normal'>Mobile Number *</h5>
                                <div className='flex flex-row items-center border-[1px] px-2 rounded-md mt-4 w-full border-gray-300'>
                                    <h4 className='text-[14px] text-gray-400 font-normal'>+91</h4>
                                    <input value={MobileNumber} disabled={true} className=' text-gray-400 px-2 py-3 outline-none text-[14px] ' type='text' />
                                </div>
                            </div>
                            <div className='lg:w-[50%] lg:mt-0 mb-3 mt-5 w-full'>
                                <h5 className='text-gray-400 font-normal'>Email Id *</h5>
                                <input onChange={(e) => setEmail(e.target.value)} value={Email} disabled={NowYouCanEdit ? false : true} className={`border-[1px] ${NowYouCanEdit ? 'text-black' : 'text-gray-400'} w-full text-[14px] border-gray-300 px-2 py-3 outline-none rounded-md mt-4`} type='text' />
                            </div>
                        </div>
                        {
                            NowYouCanEdit ?
                                <div className='px-5 py-5'>
                                    <div onClick={() => UpdateTheProfile()} className='px-4 rounded-sm py-2 cursor-pointer bg-primary_color w-max text-white' >
                                        <h5 className='text-[13px] font-normal'>Update Info</h5>
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
                <div className='w-[300px] hidden lg:block h-[450px] '>
                    {Square.length > 0 && <BannerComponent sectionId={Square[0].sectionId} internal={Square[0].internal} redirectUrl={Square[0].redirectUrl} id={Square[0].id} ImageUrl={Square[0].bannerUrl} />}
                </div>
            </div>
            <Footer/>
            {BottomBannerState ? 
            <div className='w-full py-2 absolute bottom-0  flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}
