import React, { useState } from 'react'
import Header from './Header';
import career from '../Image/career.png'
import { ToastContainer, toast } from 'react-toastify';
export default function Careerpage() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phonenumber, setPhonenumber] = useState('')
    const [exp, setExp] = useState('')
    const [age, setAge] = useState('')
    const [file, setFile] = useState('')
    function SubmitFunction() 
    {
        if(name.length>0&&email.length>0&&phonenumber.length>0&&exp.length>0&&age.length>0&&file.length>0)
        {
            toast('Successfully Applied, HR will Call You Soon', { autoClose: true, pauseOnHover: false })
            setName('')
            setEmail('')
            setAge('')
            setExp('')
            setPhonenumber('')
            setFile('')
        }else{
            alert('Field are missing')
        }
    }
    return (
        <div className='w-full bg-white h-[100vh] overflow-auto'>
            <Header props={{ selected: "Home" }} />
            <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'white' }} />
            <div className='flex flex-col items-center'>
                <img className='w-full h-[700px] absolute object-cover object-center brightness-200' src={career} />
                <div className='absolute w-full 2xl:top-[50%] top-[40%] p-5 2xl:p-0'>
                    <div className='max-w-5xl mx-auto flex flex-col gap-3 mb-[100px]'>
                        <h1 className='text-black font-normal text-3xl'>Apply for a job</h1>
                        <h5 className='text-black text-sm font-normal'>We have a lot of jobs opportunities and are hiring, we're always looking for high-<br /> talented candidates of all the business types, use the form below and let us<br /> know about you and contact you back.</h5>
                    </div>
                    <div className='max-w-5xl bg-white flex flex-col gap-3 mx-auto p-5 mb-40 shadow-lg'>
                        <div className='flex flex-row items-center w-full gap-5 mb-3'>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Your Name</h3>
                                <input onChange={(e)=>setName(e.target.value)} value={name} type='text' className='w-full text-sm p-[6px] border mt-2 rounded-md' />
                            </div>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Your Email</h3>
                                <input value={email} onChange={(e)=>setEmail(e.target.value)} type='text' className='w-full text-sm p-[6px] border mt-2 rounded-md' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full gap-5 mb-3'>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Phone Number</h3>
                                <input value={phonenumber} onChange={(e)=>setPhonenumber(e.target.value)} type='text' className='w-full text-sm p-[6px] border mt-2 rounded-md' />
                            </div>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Apply For</h3>
                                <select className='w-full p-[3px] border mt-2 rounded-md text-sm'>
                                    <option>Android Developer</option>
                                    <option>IOS Developer</option>
                                    <option>Web Developer</option>
                                    <option>Game Developer</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full gap-5 mb-3'>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Year Of Experience</h3>
                                <input onChange={(e)=>setExp(e.target.value)} type='text' className='w-full text-sm p-[6px] border mt-2 rounded-md' />
                            </div>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Interested In</h3>
                                <select className='w-full p-[3px] border mt-2 rounded-md text-sm'>
                                    <option>Full Time Job</option>
                                    <option>Work From Home</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex flex-row items-center w-full gap-5 mb-3'>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Your Age</h3>
                                <input value={age} onChange={(e)=>setAge(e.target.value)} type='text' className='w-full text-sm p-[6px] border mt-2 rounded-md' />
                            </div>
                            <div className=' flex flex-col w-full'>
                                <h3 className='text-black text-xs font-extrabold'>Year Of Experience</h3>
                                <input  onChange={(e)=>setFile('hi')} type='file' className='w-full text-sm p-[3px] border mt-2 rounded-md' />
                            </div>
                        </div>
                        <div className=' flex flex-col '>
                            <h3 className='text-black mb-5 text-xs font-extrabold'>Check all your information and send us</h3>
                            <button onClick={()=>SubmitFunction()} className='bg-primary_color w-40 font-bold text-white rounded-md py-2'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='w-[50%] m-auto flex flex-col py-10 '>
                <img className='w-[20%] h-[20%] object-contain' src={Cinimini} />
                <h4 className='text-white tracking-wide py-5 font-normal'>Please enter the details below for our HR Manager to get in touch with you.</h4>
                <div className='flex flex-col w-[60%]  p-2 space-y-5 rounded-lg'>
                    <div className='flex flex-row items-center gap-3 mt-1' >
                        <label className='border-r-[5px] border-orange-500 h-[30px]' ></label >
                        <h2 className='text-gray-50 tracking-wide'>Careers</h2>
                    </div >
                    <div className='flex flex-row space-x-5 items-center justify-between'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Enquiry Type</h4>
                        <select className='outline-none w-[50%]'>
                            <option className='text-sm'>Choose Your Enquiry</option>
                            <option className='text-sm'>General Enquiry</option>
                        </select>
                    </div>
                    <div className='flex flex-row space-x-5 items-center justify-between'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Name</h4>
                        <input maxLength={15} className='border-[1px] px-2 w-[50%] outline-none rounded-sm' />
                    </div>
                    <div className='flex flex-row space-x-5 items-center justify-between'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Email</h4>
                        <input  maxLength={20} className='border-[1px] px-2 w-[50%] outline-none rounded-sm' />
                    </div>
                    <div className='flex flex-row space-x-5 items-center justify-between'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Phone Number</h4>
                        <input maxLength={10}  className='border-[1px] px-2 w-[50%] outline-none rounded-sm' />
                    </div>
                    <div className='flex flex-row space-x-5  justify-between'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Message</h4>
                        <textarea maxLength={10}  className='border-[1px] px-2 w-[50%] outline-none rounded-sm' />
                    </div>
                    <div className='flex hover:cursor-pointer hover:scale-110 duration-300 flex-col items-center p-1 w-[20%]  rounded-lg self-center justify-between bg-red-600'>
                        <h4 className='text-white text-base font-normal tracking-wide'>Submit</h4>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
