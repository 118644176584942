import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
function MovieNews({ props }) {
    const navigate = useNavigate();
    return (
        <div>
            {props.movieNews.length>0?
            <div className='container m-auto pl-3 flex flex-col h-[600px] overflow-y-auto custom-scrollbar'>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 text-justify gap-3 grid-cols-1 py-3'>
                    {props.movieNews.map((item, index) =>
                        <div key={index} className='flex flex-col gap-3 border-b-[1px] px-2 border-b-gray-400 pb-3'>
                            <img onClick={() => navigate("/KnowMore", { state: { news: item } })} className='w-full hover:cursor-pointer rounded object-cover gap-6 h-[300px]' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + item.id + '/' + JSON.parse(item.bannersUrl)[0]} alt='' />
                            <div className='flex flex-row items-center gap-3'>
                                <img className='w-12 h-12 object-cover object-top' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + item.id + '/' + item.thumbnailUrl} alt='' />
                                <label className='border-r-[3px] border-[#24baef] h-[60px]'></label>
                                <h5 onClick={() => navigate("/KnowMore", { state: { news: item } })} className='text-justify hover:text-[#24baef] duration-300 hover:cursor-pointer text-white'>{item.name}</h5>
                            </div>
                            <p className='hover:cursor-default h-[150px] overflow-hidden '>{item.description}</p>
                            <label className=' w-max hover:translate-x-3 text-sm text-gray-300 hover:text-[#24baef] font-medium flex flex-row items-center justify-center gap-x-1 self-end  cursor-pointer' onClick={() => navigate("/KnowMore", { state: { news: item } })}>More <FaAngleRight /></label>
                        </div>
                    )}
                </div>
            </div>:<h5 className='text-gray-300 py-2 text-center font-normal'>No News yet</h5>}
        </div>
    )
}

export default MovieNews
