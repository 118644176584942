import React, { useEffect, useId, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'
import comments from "../Assets/comments.png";
import Profile from '../Assets/Profile.jpg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaArrowLeftLong } from "react-icons/fa6";
import { GetAdvertisements, GetTheData } from '../Application/Api'
import Header from './Header'
import Footer from './Footer'
import { FaAngleDown, FaBackward, FaEdit, FaEye, FaHeart, FaRegCommentAlt, FaRegHeart } from 'react-icons/fa'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import BannerComponent from './BannerComponent'
import { AiOutlineEllipsis } from "react-icons/ai";
import { RiDeleteBin6Line } from 'react-icons/ri'
import { FiEdit2 } from 'react-icons/fi'
import { motion } from 'framer-motion';
function KnowMore() {
    useEffect(() => {
        FetchingAdvertisements()
        NewsFeedViewsDatails()
        NewsFeedLikesDetail()
    }, [])
    const [HeaderBanner, setHeaderBanner] = useState('')
    const [NewsSquare, setNewsSquare] = useState('')
    const [BottomBanner, setBottomBanner] = useState('')
    async function FetchingAdvertisements() {
        const data = await GetAdvertisements()
        if (data.length > 0) {
            const Header = data.filter((e) => e.destination == 2 && e.placement == 1)
            setHeaderBanner(Header)
            const square = data.filter((e) => e.destination == 2 && e.placement == 6)
            setNewsSquare(square)
            const bottombann = data.filter((e) => e.destination == 2 && e.placement == 3)
            setBottomBanner(bottombann)
        }
    }
    const location = useLocation()
    const datas = location.state.news

    const [NewsLikedGuys, setNewsLikedGuys] = useState([])
    async function NewsFeedLikesDetail() {
        try {
            const response = await axios.get('https://cinimini-apis-3nugbllihq-el.a.run.app/newsUserData/newsFeedLikes/' + datas.id)
            setNewsLikedGuys(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const [NewsVisitedGuys, setNewsVisitedGuys] = useState([])
    async function NewsFeedViewsDatails() {
        try {
            const response = await axios.get('https://cinimini-apis-3nugbllihq-el.a.run.app/newsUserData/newsFeedViews/' + datas.id)
            setNewsVisitedGuys(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }
    const [user, setUser] = useState(null)
    const [PNumber, setPNumber] = useState('')
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (data) => {
            if (data) {
                setUser(data)
                setPNumber(data.phoneNumber.substring(3, data.phoneNumber))
                FetchingUserDetailsFromAPi(data.phoneNumber.substring(3, data.phoneNumber))
                console.log(data);
            } else {
                setUser(data)
            }
            FetchingComments()
        });
    }, [])
    const [showComments, setShowComments] = useState(location.state.comment === 'true' ? true : false)
    const [Comments1, setComments1] = useState('')

    async function IncrementingCommentCount() {

        let count = datas.commentsCount;
        if (count !== null) {
            count++
        } else {
            count = 1
        }
        try {
            const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed/' + datas.id, {
                commentsCount: count
            })
            if (response.status == 200) {
                console.log(response.data);
                FetchingComments()

            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const [userInfo, setUserInfo] = useState('')
    const [UserId, setUserId] = useState(null)
    async function FetchingUserDetailsFromAPi(num) {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/specificuser/${num}`)
            setUserInfo(response.data[0])
            PatchingViewCountFunction(response.data[0], num)
            setUserId(response.data[0].id)
        } catch (error) {
            console.log(error.message);
        }
    }
    async function ViewsCountFunction(params, num) {
        try {
            let temp = []
            if (params.newsFeedViews === null) {
                temp.push(datas.id)
                await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/user/' + num, {
                    newsFeedViews: JSON.stringify(temp)
                }).then((res) => {
                    console.log(res.data);
                    // PatchingViewCountFunction(temp)
                    NewsFeedViewsDatails()
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else if (params.newsFeedViews !== null) {
                temp = [...JSON.parse(params.newsFeedViews)]
                const result = temp.findIndex(e => e === datas.id)
                if (result === -1) {
                    temp.push(datas.id)
                    await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/user/' + num, {
                        newsFeedViews: JSON.stringify(temp)
                    }).then((res) => {
                        console.log(res.data);
                        // PatchingViewCountFunction(temp)
                        NewsFeedViewsDatails()
                    }).catch((error) => {
                        console.log(error.message);
                    })
                }
            }

        } catch (error) {
            console.log(error.message);
        }
    }
    const [Count, setCount] = useState(null)
    async function PatchingViewCountFunction(params, num) {
        // const array = [...temp]
        // console.log(datas.viewCount);
        // if (array.findIndex(e => e == datas.id) !== -1) {
        //     Views += 1;
       
        try {
            const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed/' + datas.id, {
                viewCount: datas.viewCount+1
            })
            if (response.status == 200) {
                ViewsCountFunction(params, num)
                NewsFeedViewsDatails()
                FetchingNews()
                setCount(datas.viewCount+1)
            }
        } catch (error) {
            console.log(error.message);
        }
        // }

    }
    const [commentsData, setCommentsData] = useState([])
    async function FetchingComments() {
        try {
            const response = await axios.get(`https://cinimini-apis-3nugbllihq-el.a.run.app/comments/${datas.id}`)
            if (response.status == 200) {
                console.log(response.data);
                setCommentsData(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [Views, setViews] = useState(true)
    const [ViewsHover, setViewsHover] = useState(false)
    const [Comments, setComments] = useState(false)
    const [CommentsHover, setCommentsHover] = useState(false)
    const [Likes, setLikes] = useState(false)
    const [LikesHover, setLikesHover] = useState(false)
    function SwapFunction(params) {
        if (params === 'views') {
            setViews(true)
            setComments(false)
            setLikes(false)
            setShowEditCommentPage(false)
            setShowComments(false)
        }
        if (params === 'comments') {
            setViews(false)
            setComments(true)
            setLikes(false)
            setShowEditCommentPage(false)
            setShowComments(false)
        }
        if (params === 'likes') {
            setViews(false)
            setComments(false)
            setLikes(true)
            setShowEditCommentPage(false)
            setShowComments(false)
        }
    }
    async function StoringTheLikes() {
        if (user === null) {
            alert('Login Do First')
        } else {
            try {
                let temp = []
                if (userInfo.newsFeedLikes === null) {
                    temp.push(datas.id)
                }
                else if (userInfo.newsFeedLikes !== null) {
                    temp = [...JSON.parse(userInfo.newsFeedLikes)]
                    const result = temp.findIndex(e => e === datas.id)
                    if (result === -1) {
                        temp.push(datas.id)
                    } else {
                        temp.splice(result, 1)
                    }
                }
                await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/user/' + PNumber, {
                    newsFeedLikes: JSON.stringify(temp)
                }).then((res) => {
                    console.log(res.data);
                    FetchingUserDetailsFromAPi(PNumber)
                    StoringTheLikeCount(temp)
                    NewsFeedLikesDetail()
                }).catch((error) => {
                    console.log(error.message);
                })
            } catch (error) {
                console.log(error.message);
            }
        }

    }
    async function StoringTheLikeCount(temp) {
        const array = [...temp]
        let likes = datas.likeCount
        if (array.findIndex(e => e == datas.id) == -1) {
            likes -= 1
            datas.likeCount--
        }
        else {
            likes += 1
            datas.likeCount++
        }
        try {
            const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/newsFeed/' + datas.id, {
                likeCount: likes
            })
            if (response.status == 200) {
                console.log(response.data);
                FetchingNews()
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const [newsFeed, setNewsFeed] = useState('')
    async function FetchingNews() {
        try {
            const response = await GetTheData('newsFeed')
            setNewsFeed(response.data.find(e => e.id == datas.id))
        } catch (error) {
            console.log('news error');
        }
    }
    const navigate = useNavigate();

    function CommentFunction() {
        if (user === null) {
            alert('Login Do First')
        } else {
            setShowComments(!showComments)
        }
    }
    const [BottomBannerState, setBottomBannerState] = useState(true)
    const returnThePaddedValues = (val) => {
        if (val.toString().length < 2) {
            return val.toString().padStart(2, 0);
        }
        else {
            return val;
        }
    }
    const getThePostTime = (_date) => {
        let msecs = new Date(new Date().getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date().getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date().getUTCDate()) + " " + returnThePaddedValues(new Date().getUTCHours()) + ":" + returnThePaddedValues(new Date().getUTCMinutes()) + ":" + returnThePaddedValues(new Date().getUTCSeconds())).getTime() - new Date(new Date(_date).getUTCFullYear() + "-" + returnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) + "-" + returnThePaddedValues(new Date(_date).getUTCDate()) + " " + returnThePaddedValues(new Date(_date).getUTCHours()) + ":" + returnThePaddedValues(new Date(_date).getUTCMinutes()) + ":" + returnThePaddedValues(new Date(_date).getUTCSeconds())).getTime();
        let secs = msecs / 1000;
        let mins = secs / 60;
        let hours = mins / 60;
        let days = hours / 24;
        let weeks = days / 7;
        let months = weeks / 4;
        let years = months / 12;
        if (secs > 59) {
            if (mins > 59) {
                if (hours > 23) {
                    if (days > 6) {
                        if (weeks > 3) {
                            if (months > 11) {
                                return years == 0 ? 1 + "y" : years.toFixed(0) + "y";
                            }
                            else {
                                return months == 0 ? 1 + "mo" : months.toFixed(0) + "mo";
                            }
                        }
                        else {
                            return weeks == 0 ? 1 + "w" : weeks.toFixed(0) + "w";
                        }
                    }
                    else {
                        return days == 0 ? 1 + "d" : days.toFixed(0) + "d";
                    }
                }
                else {
                    return hours == 0 ? 1 + "h" : hours.toFixed(0) + "h";
                }
            }
            else {
                return mins == 0 ? 1 + "m" : mins.toFixed(0) + "m";
            }
        }
        else {
            return secs == 0 ? 1 + "s" : secs.toFixed(0) + "s";
        }
    }
    const returnTheReleaseDate = (_date) => {
        let year = new Date(_date).getUTCFullYear();
        let month = new Date(_date).toLocaleDateString("en-US", { month: 'short' })
        let date =
            new Date(_date).getUTCDate().toString().length < 2
                ? new Date(_date).getUTCDate().toString().padStart(2, 0)
                : new Date(_date).getUTCDate().toString();
        let final = date + "-" + month + "-" + year;
        return final;
    };
    const [ShowEditCommentPage, setShowEditCommentPage] = useState(false)
    function UpdateTheUserCommentFunction(e) {
        setShowComments(!showComments)
        setComments1(e)
    }
    const [CommentId, setCommentId] = useState(-1)
    function ShowEditCommentPageFunction(e) {
        if (ShowEditCommentPage) {
            setShowEditCommentPage(false)
            setCommentId(e.id)
            setShowComments(false)
        } else {
            setShowEditCommentPage(true)
            setCommentId(e.id)
        }

    }
    async function PostCommentFunction() {
        if (user === null) {
            alert('Login Do First')
        } else {
            try {
                if (CommentId == -1) {
                    if (Comments1.length > 0) {
                        const collection = {
                            content: Comments1,
                            newsFeedId: datas.id,
                            userId: userInfo.id,
                            creationDate: new Date().getFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate(),
                            likes: 0
                        }
                        const response = await axios.post('https://cinimini-apis-3nugbllihq-el.a.run.app/comments', collection)
                        if (response.status == 200) {
                            console.log(response);
                            setShowComments(false)
                            toast('Successfully Posted')
                            FetchingComments()
                            IncrementingCommentCount()
                            setComments1('')
                            setCommentId(-1)
                        }
                    }
                    else {
                        alert('Field is empty')
                    }
                } else {
                    if (Comments1.length > 0) {
                        const collection = {
                            content: Comments1,
                            creationDate: new Date().getFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate(),
                        }
                        const response = await axios.patch('https://cinimini-apis-3nugbllihq-el.a.run.app/comments/' + CommentId, collection)
                        if (response.status == 200) {
                            console.log(response);
                            setShowComments(false)
                            toast('Successfully Updated')
                            FetchingComments()
                            IncrementingCommentCount()
                            setComments1('')
                            setCommentId(-1)
                            setShowEditCommentPage(false)
                        }
                    }
                    else {
                        alert('Field is empty')
                    }
                }

            } catch (error) {
                console.log(error.message);
            }
        }
    }

    async function DeleteCommentFunction(_id) {
        if (user === null) {
            alert('Login Do First')
        } else {
            await axios.delete('https://cinimini-apis-3nugbllihq-el.a.run.app/comments/' + _id)
                .then((responce) => {
                    if (responce.status == 200) {
                        toast('Successfully Deleted')
                        FetchingComments()
                    }
                })
        }
    }

    return (
        <div className='w-full h-[100vh] relative overflow-auto bg-[#131722]'>
            <Header props={{ selected: "News" }} />
            <div onClick={() => navigate('/news')} className='absolute hover:-translate-x-3 hover:text-white hover:cursor-pointer duration-300 p-2 flex flex-row items-center gap-x-4 rounded-md bg-[#24baef] mx-2 lg:mx-10 my-4 z-[99999]'>
                <FaArrowLeftLong />
                <h5 className='font-normal text-[14px]'>Back</h5>
            </div>
            <div className='container mx-auto lg:px-2'>
                <ToastContainer position='top-center' toastStyle={{ backgroundColor: '#24baef', color: 'black' }} />
                <div className='w-full lg:flex-nowrap flex-wrap flex flex-row gap-4 mt-3'>
                    <div className='flex flex-col gap-3 2xl:w-[73%] lg:w-[67%]'>
                        <img className=' lg:h-[570px] rounded-md object-fill object-top' src={'https://storage.googleapis.com/cinimini-console/newsFeed/' + datas.id + '/' + JSON.parse(datas.bannersUrl)[0]} alt='' />
                    </div>
                    <div className='2xl:w-[25%] lg:w-[30%] lg:block hidden w-full bg-[#1c212e] p-2 rounded-lg'>
                        <div className='grid grid-cols-3 gap-5 mb-5'>
                            <div onClick={() => SwapFunction('views')} onPointerEnter={() => setViewsHover(true)} onPointerLeave={() => setViewsHover(false)} style={{ color: Views || ViewsHover ? 'white' : 'gray', backgroundColor: Views || ViewsHover ? '#24baef' : 'transparent' }}
                                className='font-bold   h-8 rounded-md  hover:cursor-pointer flex flex-col text-sm justify-center items-center'>VIEWS</div>
                            <div onClick={() => SwapFunction('comments')} style={{ color: Comments || CommentsHover ? 'white' : 'gray', backgroundColor: Comments || CommentsHover ? '#24baef' : 'transparent' }}
                                onPointerEnter={() => setCommentsHover(true)} onPointerLeave={() => setCommentsHover(false)} className='font-bold h-8 rounded-md   hover:cursor-pointer flex flex-col text-sm justify-center items-center'>COMMENTS</div>
                            <div onClick={() => SwapFunction('likes')} onPointerEnter={() => setLikesHover(true)} onPointerLeave={() => setLikesHover(false)} style={{ color: Likes || LikesHover ? 'white' : 'gray', backgroundColor: Likes || LikesHover ? '#24baef' : 'transparent' }}
                                className='font-bold  h-8 rounded-md   hover:cursor-pointer flex flex-col text-sm justify-center items-center'>LIKES</div>
                        </div>
                        <div className='flx flex-col '>
                            {
                                Views &&
                                <div className='flex flex-col overflow-auto lg:h-[500px]'>
                                    {
                                        NewsVisitedGuys.length > 0 ? NewsVisitedGuys.map((e, index) =>
                                            <div key={index} style={{ borderWidth: 1 }} className='flex-row flex items-center p-2 rounded-md gap-5 mb-2'>
                                                <img className='w-[70px] h-[70px] rounded-full object-cover' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                                <h5 className='font-normal hover:cursor-default text-base text-gray-400'>{e.name}</h5>
                                            </div>
                                        ) : <h5 className='text-white font-normal text-center'>No Views Yet</h5>
                                    }
                                </div>
                            }
                            {
                                Comments &&
                                <div className='custom-scrollbar overflow-auto lg:h-[500px]'>
                                    {
                                        commentsData.length > 0 ? commentsData.map((e, index) =>
                                            <div key={index} style={{ borderWidth: 1 }} className='flex-row rounded-md p-2 mb-2 flex gap-3 w-[100%] '>
                                                <div className=' lg:w-[25%] flex flex-col items-center'>
                                                    <img className='w-[70px] h-[70px] rounded-full' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                                    <h5 className='font-normal hover:cursor-default text-gray-400'>{e.name}</h5>
                                                </div>
                                                <div className='w-[70%] flex flex-col justify-between  py-1'>
                                                    <div className='flex w-full flex-row justify-between items-start'>
                                                        <p className=' text-white w-[80%] hover:cursor-default break-all '>
                                                            {e.content}
                                                        </p>
                                                        {UserId != null ? e.userId == UserId ?
                                                            <div className=' h-full z-[99] flex flex-col gap-y-2'>
                                                                <AiOutlineEllipsis onClick={() => ShowEditCommentPageFunction(e)} className='text-white cursor-pointer w-5 h-5' />
                                                                {ShowEditCommentPage && e.id == CommentId ? <FiEdit2 onClick={() => UpdateTheUserCommentFunction(e.content)} className='text-gray-400 cursor-pointer w-4 h-4' /> : null}
                                                                {ShowEditCommentPage && e.id == CommentId ? <RiDeleteBin6Line onClick={() => DeleteCommentFunction(e.id)} className='text-gray-400 cursor-pointer w-4 h-4 mb-2' /> : null}
                                                            </div> : null : null}
                                                    </div>
                                                    <div className='flex flex-row justify-between'>
                                                        <div className='flex flex-row gap-x-1'>
                                                            {/* <img className='w-[20px] h-[20px]' src={heart} />
                                                            <h5 className='font-normal hover:cursor-default text-gray-400'>6</h5> */}
                                                        </div>
                                                        <h5 className='font-normal hover:cursor-default text-gray-400'>{new Date(e.creationDate).toLocaleString("en-US", { month: "short" })} {new Date(e.creationDate).getUTCDate()} {new Date(e.creationDate).getFullYear()}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : <h5 className='text-white font-normal text-center'>No Comments Yet</h5>
                                    }
                                </div>
                            }
                            {
                                Likes &&
                                <div className='flex flex-col overflow-auto lg:h-[500px]'>
                                    {
                                        NewsLikedGuys.length > 0 ? NewsLikedGuys.map((e, index) =>
                                            <div key={index} style={{ borderWidth: 1 }} className='flex-row flex items-center p-2 rounded-md gap-5 mb-2'>
                                                <img className='w-[70px] h-[70px] rounded-full object-cover' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                                <h5 className='font-normal hover:cursor-default text-base text-gray-400'>{e.name}</h5>
                                            </div>
                                        ) : <h5 className='text-white font-normal text-center'>No Likes Yet</h5>
                                    }

                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex sm:flex-row flex-col justify-between lg:w-[67%] 2xl:w-[73%] w-full px-2  mt-2'>
                    <div className='flex flex-row gap-x-3 lg:gap-6 items-center'>
                        <h5 className='text-[#24baef] text-[12px] sm:text-sm hover:cursor-default'>NEWS & UPDATES </h5>
                        <h5 className='text-gray-500 text-[12px] sm:text-sm fonn hover:cursor-default'>{returnTheReleaseDate(datas.dateOfPublish)}</h5>
                    </div>
                    <div className='flex flex-row items-center mt-2 sm:mt-0 gap-x-3'>
                        <div className='flex flex-row p-1   rounded-md items-center gap-x-1'>
                            <FaEye className='text-white' />
                            <h5 className='text-[#24baef]  hover:cursor-default font-normal'>{Count||datas.viewCount}</h5>
                        </div>
                        <div className='flex flex-row items-center p-1   rounded-md gap-x-1'>
                            <div className='w-[20px]  h-[20px] object-contain  flex flex-col justify-center items-center'>
                                {
                                    userInfo.newsFeedLikes != null ? JSON.parse(userInfo.newsFeedLikes).findIndex(e => e === datas.id) !== -1 ? <FaHeart onClick={() => StoringTheLikes()} className='text-red-600 hover:cursor-pointer' /> : <FaRegHeart onClick={() => StoringTheLikes()} className='text-white hover:cursor-pointer' /> : <FaRegHeart onClick={() => StoringTheLikes()} className='text-white hover:cursor-pointer' />
                                }
                            </div>
                            <h5 className='text-[#24baef] hover:cursor-default font-normal'>{datas.likeCount}</h5>
                        </div>
                        <div className='flex flex-row items-center gap-x-1 p-1   rounded-md'>
                            <FaRegCommentAlt onClick={() => CommentFunction()} className=' text-white hover:cursor-pointer' />
                            <h5 className='text-[#24baef] hover:cursor-default font-normal'>{commentsData.length}</h5>
                        </div>
                    </div>
                </div>
                {
                    showComments ?
                        <div style={{ borderWidth: 1 }} className=' flex 2xl:w-[73%] lg:w-[67%]  sm:w-[97%] w-[95%] mx-2 rounded-md  flex-col mt-2 justify-center items-center py-2'>
                            <textarea value={Comments1} onChange={(text) => setComments1(text.target.value)} className='w-full bg-transparent text-white outline-none p-2' />
                            <div onClick={() => PostCommentFunction()} className='lg:font-bold bg-[#24baef] text-white h-8 font-normal rounded-lg sm:w-[30%] px-2  hover:cursor-pointer flex flex-col text-sm justify-center items-center'>Post Comment</div>
                        </div>
                        : null
                }
                <div className='mt-5 mb-5 px-2 2xl:w-[73%] lg:w-[67%]'>
                    <div className='flex flex-row items-center gap-3 ' >
                        <label className='border-r-[5px] border-[#24baef] h-[50px]' ></label >
                        <h4 className='text-white text-[13px] sm:text-lg hover:cursor-default tracking-wide'>{datas.name}</h4>
                    </div >
                    <p className='px-3 hover:cursor-default flex-wrap mt-2 text-[12px] lg:text-base'>{datas.description}</p>
                </div>
                <div className=' 2xl:w-[73%] lg:w-[67%] h-[250px] my-5'>
                    {HeaderBanner.length > 0 && <BannerComponent sectionId={HeaderBanner[0].sectionId} internal={HeaderBanner[0].internal} height={200} redirectUrl={HeaderBanner[0].redirectUrl} id={HeaderBanner[0].id} ImageUrl={HeaderBanner[0].bannerUrl} />}
                </div>
                {datas.youtubeLink !== '-' ? <div className='mb-10 lg:h-[500px] h-[200px] sm:h-[400px] lg:w-[67%] 2xl:w-[73%] w-full'>
                    <ReactPlayer
                        width=" 100%"
                        height='100%'
                        url={datas.youtubeLink}
                        loop={true}
                        muted={false}
                        // light={true}
                        controls={true}
                    />
                </div> : null}
                <div className='2xl:w-[25%] lg:w-[30%] lg:hidden block w-full bg-[#1c212e] p-2 rounded-lg'>
                    <div className='grid grid-cols-3 gap-5 mb-5'>
                        <div onClick={() => SwapFunction('views')} onPointerEnter={() => setViewsHover(true)} onPointerLeave={() => setViewsHover(false)} style={{ color: Views || ViewsHover ? 'white' : 'gray', backgroundColor: Views || ViewsHover ? '#24baef' : 'transparent' }}
                            className='font-bold   h-8 rounded-md  hover:cursor-pointer flex flex-col text-sm justify-center items-center'>VIEWS</div>
                        <div onClick={() => SwapFunction('comments')} style={{ color: Comments || CommentsHover ? 'white' : 'gray', backgroundColor: Comments || CommentsHover ? '#24baef' : 'transparent' }}
                            onPointerEnter={() => setCommentsHover(true)} onPointerLeave={() => setCommentsHover(false)} className='font-bold h-8 rounded-md   hover:cursor-pointer flex flex-col text-sm justify-center items-center'>COMMENTS</div>
                        <div onClick={() => SwapFunction('likes')} onPointerEnter={() => setLikesHover(true)} onPointerLeave={() => setLikesHover(false)} style={{ color: Likes || LikesHover ? 'white' : 'gray', backgroundColor: Likes || LikesHover ? '#24baef' : 'transparent' }}
                            className='font-bold  h-8 rounded-md   hover:cursor-pointer flex flex-col text-sm justify-center items-center'>LIKES</div>
                    </div>
                    <div className='flx flex-col '>
                        {
                            Views &&
                            <div className='flex flex-col overflow-auto lg:h-[500px]'>
                                {
                                    NewsVisitedGuys.length > 0 ? NewsVisitedGuys.map((e, index) =>
                                        <div key={index} style={{ borderWidth: 1 }} className='flex-row flex items-center p-2 rounded-md gap-5 mb-2'>
                                            <img className='w-[70px] h-[70px] rounded-full object-cover' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                            <h5 className='font-normal hover:cursor-default text-base text-gray-400'>{e.name}</h5>
                                        </div>
                                    ) : <h5 className='text-white font-normal text-center'>No Views Yet</h5>
                                }
                            </div>
                        }
                        {
                            Comments &&
                            <div className='custom-scrollbar overflow-auto lg:h-[500px]'>
                                {
                                    commentsData.length > 0 ? commentsData.map((e, index) =>
                                        <div key={index} style={{ borderWidth: 1 }} className='flex-row rounded-md p-2 mb-2 flex gap-3 w-[100%] '>
                                            <div className='flex flex-col w-[30%] items-center'>
                                                <img className='w-[70px] h-[70px] rounded-full object-cover' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                                <h5 className='font-normal hover:cursor-default text-gray-400'>{e.name}</h5>
                                            </div>
                                            <div className='w-[70%] flex flex-col justify-between  py-1'>
                                                <div className='flex w-full flex-row justify-between items-start'>
                                                    <p className=' text-white w-[80%] hover:cursor-default break-all '>
                                                        {e.content}
                                                    </p>
                                                    {UserId != null ? e.userId == UserId ?
                                                        <div className=' h-full z-[99] flex flex-col gap-y-2'>
                                                            <AiOutlineEllipsis onClick={() => ShowEditCommentPageFunction(e)} className='text-white cursor-pointer w-5 h-5' />
                                                            {ShowEditCommentPage && e.id == CommentId ? <FiEdit2 onClick={() => UpdateTheUserCommentFunction(e.content)} className='text-gray-400 cursor-pointer w-4 h-4' /> : null}
                                                            {ShowEditCommentPage && e.id == CommentId ? <RiDeleteBin6Line onClick={() => DeleteCommentFunction(e.id)} className='text-gray-400 cursor-pointer w-4 h-4 mb-2' /> : null}
                                                        </div> : null : null}
                                                </div>
                                                <div className='flex flex-row justify-between'>
                                                    <div className='flex flex-row gap-x-1'>
                                                        {/* <img className='w-[20px] h-[20px]' src={heart} />
                                                        <h5 className='font-normal hover:cursor-default text-gray-400'>6</h5> */}
                                                    </div>
                                                    <h5 className='font-normal hover:cursor-default text-gray-400'>{new Date(e.creationDate).toLocaleString("en-US", { month: "short" })} {new Date(e.creationDate).getUTCDate()} {new Date(e.creationDate).getFullYear()}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    ) : <h5 className='text-white font-normal text-center'>No Comments Yet</h5>
                                }
                            </div>
                        }
                        {
                            Likes &&
                            <div className='flex flex-col overflow-auto lg:h-[500px]'>
                                {
                                    NewsLikedGuys.length > 0 ? NewsLikedGuys.map((e, index) =>
                                        <div key={index} style={{ borderWidth: 1 }} className='flex-row flex items-center p-2 rounded-md gap-5 mb-2'>
                                            <img className='w-[70px] h-[70px] rounded-full object-cover' src={e.profilePictureUrl != '' ? 'https://storage.googleapis.com/cinimini-console/users/' + e.profilePictureUrl : Profile} />
                                            <h5 className='font-normal hover:cursor-default text-base text-gray-400'>{e.name}</h5>
                                        </div>
                                    ) : <h5 className='text-white font-normal text-center'>No Likes Yet</h5>
                                }

                            </div>
                        }
                    </div>
                </div>
                {/* <iframe allowtransparency="true" scrolling="no" allowfullscreen="true" className='w-[440px] h-[663px]' src={'https://platform.twitter.com/embed/Tweet.html?dnt=false&embedId=twitter-widget-0&features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&frame=false&hideCard=false&hideThread=false&id=1776095853616451646&lang=ta&origin=https%3A%2F%2Ftamil.hindustantimes.com%2Fentertainment%2Fnayanthara-and-vignesh-shivan-ice-cream-outing-with-friends-131712372911640.html&sessionId=88f9437cbed0ec54c52e9501dd571c7aac19e0b4&theme=light&widgetsVersion=2615f7e52b7e0%3A1702314776716&width=550px'} /> */}
            </div>
            <Footer />
            {BottomBannerState ? <div className='w-full py-2 sticky bottom-0 flex flex-col z-[99] justify-center items-center bg-gray-200'>
                <div onClick={() => setBottomBannerState(false)} className='bg-gradient-to-b from-white flex flex-col justify-center items-center to-gray-200 w-[70px] h-[40px] left-0 -top-[4vh] rounded-t-lg absolute'>
                    <FaAngleDown className='w-[30px] h-[30px] font-normal cursor-pointer' />
                </div>
                {BottomBanner.length > 0 && <BannerComponent sectionId={BottomBanner[0].sectionId} internal={BottomBanner[0].internal} redirectUrl={BottomBanner[0].redirectUrl} id={BottomBanner[0].id} ImageUrl={BottomBanner[0].bannerUrl} />}
            </div> : null}
        </div>
    )
}

export default KnowMore

