import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Down from "../Image/Down-White.png"
import Searchbar from "../Image/Seacrh-White.png";
import { useNavigate } from 'react-router-dom';
import UpArrow from "../Image/Up-White.png";

function Videos({ props }) {
    const navigate = useNavigate();
    const [tvShow, setTvShow] = useState(false);
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (e.target.id !== "Show") {
                setTvShow(false);
                console.log(e)
            }
        })
    })

    return (
        <div className='h-[500px] overflow-y-auto custom-scrollbar'>
            <div className='grid flex-row lg:grid-cols-2 gap-5 lg:pl-3'>
                {
                    props.videosCollection != null ?
                        props.videosCollection.split(',').map((e, index) =>
                            <div>
                                <div key={index} className='w-full xl:h-[400px] h-[350px] '>
                                    <ReactPlayer
                                        width="100%"
                                        height={'100%'}
                                        url={e.split('|')[1]}
                                        loop={true}
                                        muted={false}
                                        // light={true}
                                        controls={true}
                                    />
                                </div>
                                <div className='flex flex-row justify-between px-2'>
                                    <h4 className='text-white text-[15px] font-normal hover:cursor-default  text-center'>{e.split('|')[0]}</h4>
                                    {/* <h5 className='text-white/90  hover:cursor-default text-center font-normal'>{new Date(e.split('|')[2]).getDate()+'-'+(new Date(e.split('|')[2]).getUTCMonth()+1)+'-'+new Date(e.split('|')[2]).getFullYear()}</h5> */}
                                </div>
                            </div>
                            
                        ) : null
                }
            </div>
        </div>
    )
}

export default Videos;
